<template>
  <div class="main-view-sec">

    <Loader v-if="$root.isLoading"></Loader>

    <div class="page-headings">
      <h1 class="clearfix">Reports <span><a href="javascript:void(0)" @click="redirect('dashboard')">Home</a> <a href="javascript:void(0)">Reports</a></span></h1>
    </div>

    <div class="dash-chart-outer">
      

      <div class="row">
        <div class="col-sm-12">
          <div class="card-style m-b-30" data-scroll-index="0">
            <div class="box-head clearfix">Offline Hotspots Report:  
              <button type="button" class="btn exc-cle-exp-btn btn-success" @click="getOffline()"><i class="fas fa-eye"></i> Execute</button>
              <button type="button" class="btn exc-cle-exp-btn btn-danger"  @click="requestLog = []"><i class="fas fa-trash-alt"></i> Clean</button>
              <vue-json-to-csv
                csvTitle="offlineHotspots"
                :json-data="this.requestLog"
              >
                <v-btn style="font-size:14px;" dark color="green">
                  <v-icon dark style="font-size:18px;"> mdi-microsoft-excel </v-icon>  Export
                </v-btn>
              </vue-json-to-csv>


              <div class="pull-right">
                <div class="form-group serch-hotspot forgot-dev-pass">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                   
                  ></v-text-field> 
                </div>
              </div> 
            </div>
            <div class="all-table-theme">
              <div class="table-responsive">

                <v-data-table
                  :headers="headers"
                  :items="requestLog"
                  item-key="id"
                  class="table table-hover"
                  :search="search"
                >
                  <template v-slot:[`item.append`]></template>
                </v-data-table>


              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-sm-12">
          <div class="card-style m-b-30" data-scroll-index="0">
            <div class="box-head clearfix">Per User Earnings Report:  

              <button type="button" class="btn exc-cle-exp-btn btn-success"  @click="getUserearn()"><i class="fas fa-eye"></i> Execute</button>
              <button type="button" class="btn exc-cle-exp-btn btn-danger" @click="requestLog3 = []"><i class="fas fa-trash-alt"></i> Clean</button>
              <vue-json-to-csv :json-data="this.requestLog3" csvTitle="perUsersEarningsReport"
                >
                <v-btn dark color="green"style="font-size:14px;">

                  <v-icon dark style="font-size:18px;"> mdi-microsoft-excel </v-icon>  Export

                </v-btn>
              </vue-json-to-csv>

              <div class="pull-right">
                <div class="form-group serch-hotspot forgot-dev-pass">
                  <v-text-field
                    v-model="searchPerUser"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </div>
              </div>
            </div>
            <div class="filter-list report-filter">
              <div class="row">
                <div class="col-sm-5">
                  <div class="input-group">

                    <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date3"
                          label="Start date"
                          hint="Start date"
                          persistent-hint
                           @click:prepend="menu3 = true"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          :format="format_date"

                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date5"
                        :min="mindat"
                        :max="nowDate"
                        @input="menu3 = false"
                      ></v-date-picker>
                    </v-menu>

                    <span class="input-group-addon" id="basic-addon2"  @click="menu3= true"><i class="fas fa-calendar-alt"></i></span>


                  </div>
                </div>
                <div class="col-sm-5">
                  <div class="input-group">

                    <v-menu
                        ref="menu4"
                        v-model="menu4"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date4"
                            label="End date"
                            hint="End date"
                            persistent-hint
                            @click:prepend="menu4 = true"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            :format="format_date"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date6"
                          :min ="date3"
                          :max="nowDate"
                          @input="menu4 = false"
                        ></v-date-picker> 
                        </v-menu>


                       <span class="input-group-addon" id="basic-addon2" @click="menu4= true"><i class="fas fa-calendar-alt"></i></span>



                  </div>
                </div>
                
              </div>
            </div>
            <div class="all-table-theme">
              <div class="table-responsive">
                
                  <v-data-table
                  :headers="headers3"
                  :items="requestLog3"
                  item-key="id"
                  class="table table-hover"
                  :search="searchPerUser"
                > 
                  <template v-slot:item.status="{ item }">
                  <span :class="[item.status]"  class="status-cr st"></span>
                  {{item.status}} 
                  </template>
                  <template v-slot:item.append> </template>


                 
                </v-data-table>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import moment from "moment";
import { API_BASE_URL, API_HELIUM_URL, API_JAG_HELIUM_URL } from "../../constants";
import Loader from '../../components/loader';

export default {
  components: { Loader },
  data() {
    return {
      loader: true,
      message: "",
      amount: 0,
      nowDate: new Date().toISOString().slice(0, 10),
      mindat: '',
      dialog: false,
      hotspotDetails: [],
      hotspotTotal: [],
      progressBar: false,
      SelectedUser:"",
      url: "",
      mapurl: "",
      start: "",
      today: "",
      search: "",
      searchPerUser: "",
      allUser:[],
      uid: this.$cookies.get("uid"),
      menu2: false,
      menu1:false,
      date2:"",
      date1:"",
      menu3: false,
      menu4:false,
      date3: moment().subtract(1, 'month').format('MM-DD-YYYY'),
      date5: moment().subtract(1, 'month').format('YYYY-MM-DD'),
      date4: moment().format('MM-DD-YYYY'),
      date6: moment().format('YYYY-MM-DD'),
      accountAdded: [],
      oracleprice: null,
      installation: "",
      requestLog: [],
      requestLog2: [],
      requestLog3: [],
            token: {
        accessToken: ''
      },
      maxearn: 0,
      indexcount:[],
      allHotSpots: [],
      hotspotAll: [],
      commission: 20,
      first30days: 0,
      signneed: false,
      installdatetime: "",
    };
  },
  watch: {
    date5 (val) {
      console.log("val = ",val)
      this.date3 = this.format_date(this.date5)
    },
    date6 (val) {
       console.log("val = ",val)
      this.date4 = this.format_date(this.date6)
    }
  },
  computed: {
    headers() {
      return [
        { text: "Hotspot Name", value: "name" },
        { text: "Address", value: "address" },
        { text: "Location", value: "location" },
        { text: "Wallet Address", value: "wallet" },
        { text: "Status", value: "status" },
        //{ text: "Total earning", value: "total" },
      ];
    },
    headers2() {
      return [
        { text: "Status", value: "status" },
        { text: "Hotspot Name", value: "name" },
        { text: "Hotspot Address", value: "address" },
        { text: "Hotspot Location", value: "location" },
        { text: "Wallet Address", value: "wallet" },
        { text: "Total Rewards", value: "total",          filter: value => {
            if (!this.amount) return true;
            return value < this.amount;
          }},
      ];
    },
    headers3() {
      return [
        { text: "Status", value: "status" },
        { text: "Hotspot Name", value: "name" },
        { text: "Hotspot Address", value: "address" },
        { text: "Hotspot Location", value: "location" },
        { text: "Wallet Address", value: "wallet" },
        { text: "Total Rewards for the Hotspot", value: "total" },
        { text: "Host Name’s Relationship to Hotspot", value: "relationship" },
      ];
    },
  },

  beforeMount() {
    //this.getRequest();
    this.getOracleValue();
   // this.getAllUser()
    this.getAddress();
    this.getuid();
    //hide page loader
    this.$root.isLoading = true;
    setTimeout(() => {
      this.$root.isLoading = false;
    }, 2000)
  },

  methods: {
    redirect(link) { //custom redirect method
      if(this.investor == 1){
        this.$router.push('investor');
     }else{
        this.$router.push('dashboard');
     }
    },
    getItemText(item) {
    return `${item.name} - ${item.email}`;
    },
    getAllUser(){
      axios
        .get(`${API_BASE_URL}/users`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.allUser = resp.data
          });
    },
    getOracleValue() {
      axios
        .get(`${API_HELIUM_URL}/v1/oracle/prices/current`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.oracleprice = resp.data;
        });
    },
    sendRequest(amount, message) {
      console.log(this.message);
      axios.post(`${API_BASE_URL}/user/request/${this.uid}`, {
        payment: this.message,
        amount: this.amount,
      });
      this.dialog = false;
      this.getRequest();
    },
    getAddress() {
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.accountAdded = resp.data;
        });
    },
    getTotal(address, install, ind) {
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${install.slice(
            0,
            10
          )}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          if (resp.data.data) {
            this.requestLog[ind].total = resp.data.data.total;
          } else {
            this.requestLog[ind].total = 0;
          }
        })
        .catch((resp) => {
          this.requestLog[ind].total = 0;
        });
    },
    getuid() {
      this.getglobal();
      //this.$cookies.set('uid', this.user.data.uid)
      //this.uid = this.user.data.uid
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          console.log('inside UUId');
          this.hotspotDet(resp.data[0].Haddress, this.start, this.today);
        });
    },
    getOffline() {
      //this.uid = 'ZcWQHI5HddV3sW05ZVvtYjLuZDX2';
      this.$root.isLoading = true;
      axios.get(`${API_JAG_HELIUM_URL}/v1/user/offline/hotspots/report/${this.uid}`, {
        headers: {
          accept: "application/json",
        },
      })
      .then((resp) => {
        this.$root.isLoading = false;
        let offlineHotspots = resp.data;
        if(offlineHotspots.length > 0) {
          offlineHotspots.forEach((element, index) => {
            let obj = {};
            obj.name = this.formatHotspotName(element.hotspotName);
            obj.address = element.hotspotAddress;
            obj.wallet = element.walletAddress;
            obj.status = this.capitalizeFirstLetter(element.hotspotStatus);
            obj.location = ((element.short_street) ? element.short_street : '')+'-'+
                          ((element.short_state) ? element.short_state : '')+'-'+
                          ((element.short_country) ? element.short_country : '')+'-'+
                          ((element.short_city) ? element.short_city : '');
            this.requestLog.push(obj);
          });
        }
      })
      .catch((error) => {
        this.$root.isLoading = false;
        console.log(error);
      });
    },
    getOfflineBackup() {
      this.$root.isLoading = true;
      setTimeout(() => {
      this.$root.isLoading = false;
    }, 2000)
      var accountAdded = [];
            axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          accountAdded = resp.data;

          this.requestLog = [];
          var x = 0;

          for (var i = 0; i < accountAdded.length; i++) {
           
            var today = new Date();
            var end = accountAdded[i].removeDate? new Date(accountAdded[i].removeDate.slice(0,10)):today;

            //compare only date
            today.setHours(0,0,0,0)

            console.log("end = ",end)
           
            //get hotspot whose are currently assigned(Empty Remove Date means they are currently assigned)
            if(end >= today){
              
              axios
              .get(
                `${API_HELIUM_URL}/v1/hotspots/${accountAdded[i].Haddress}`,
                {
                  headers: {
                    accept: "application/json",
                  },
                }
              )
              .then((element) => {
                  element = element.data.data;

                  if (element.status.online == "offline") {
                    var data = {};
                    data.status = element.status.online;
                    data.name = element.name;
                    data.address = element.address;
                    data.location =
                      element.geocode.short_street +
                      " - " +
                      element.geocode.short_state +
                      " - " +
                      element.geocode.short_country +
                      " - " +
                      element.geocode.short_city;
                    data.wallet = element.owner
                    data.user = "no owner";
                    data.mobile = "";
                    data.hostname = "";
                    data.total = 0;
                    this.getTotal(element.address, element.timestamp_added, x);
                    this.getOwnerHotspot(element.address, x);
                    this.requestLog.push(data);
                    x += 1;
                  }
              });
            }

          }
            
        });
    },
    getOfflineDate(address){
              axios
          .get(
            `${API_HELIUM_URL}/v1/hotspots/${address}/activity`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            axios
          .get(
            `${API_HELIUM_URL}/v1/hotspots/${address}/activitycursor=${resp.data.data.cursor}`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            var unix_timestamp = resp.data.data[0].time
      var date = new Date(unix_timestamp * 1000);
      console.log(date)
      })
      })
      .catch((resp) => {

      })

    },
    getUserearn() {
      let UserID = this.$cookies.get('uid');
      if(!this.date3 || !this.date4) {
        return false;
      }
      this.$root.isLoading = true;
      axios.post(`${API_JAG_HELIUM_URL}/v1/user/earning/report`, {
        'userId': UserID, 
        'fromDate': this.date3, 
        'toDate': this.date4,
        headers: {
          accept: "application/json",
        },
      })
      .then((resp) => {
        this.$root.isLoading = false;
        let userHotspots = resp.data;
        if(userHotspots.length > 0) {
          userHotspots.forEach((element, index) => {
            let obj = {};
            obj.status = this.capitalizeFirstLetter(element.hotspotStatus);
            obj.name = this.formatHotspotName(element.hotspotName);
            obj.address = element.hotspotAddress;
            obj.wallet = element.hotspotWallet;
            obj.location = ((element.short_street) ? element.short_street : '')+'-'+
                          ((element.short_state) ? element.short_state : '')+'-'+
                          ((element.short_country) ? element.short_country : '')+'-'+
                          ((element.short_city) ? element.short_city : '');
            obj.total = (element.hotspotEarning).toFixed(3);
            obj.relationship = (element.hotspotOwner == '0') ? 'Primary Owner' : 'Referral Owner';            
            this.requestLog3.push(obj);
          });
        }
      })
      .catch((error) => {
        this.$root.isLoading = false;
        console.log(error);
      });
    },
    getUserearnBackup() {
      let UserID = this.$cookies.get('uid');

    if(!this.date3 || !this.date4){
      return false;
    }
     this.$root.isLoading = true;
      setTimeout(() => {
      this.$root.isLoading = false;
    }, 2000)

      var hotspotAll=[]
      axios
        .get(`${API_BASE_URL}/user/hotspot/${UserID}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          hotspotAll = resp.data;
        
      this.requestLog3 = [];
      var x = 0;
      for (var i = 0; i < hotspotAll.length; i++) {
                axios
          .get(`${API_HELIUM_URL}/v1/hotspots/${hotspotAll[i].Haddress}`, {
            headers: { accept: "application/json" },
          })
          .then((element) => {
            console.log('element', element);
                var data = {};
                data.status = this.capitalizeFirstLetter( element.data.data.status.online);
                data.name = this.formatHotspotName(element.data.data.name);
                data.address = element.data.data.address;
                data.location =
                  element.data.data.geocode.short_street +
                  " - " +
                  element.data.data.geocode.short_state +
                  " - " +
                  element.data.data.geocode.short_country +
                  " - " +
                  element.data.data.geocode.short_city;
                data.user = "no owner";
                data.mobile = "";
                data.wallet = element.data.data.owner
                data.hostname = "";
                data.total = 0;
                console.log(resp.data[x])
                if(resp.data[x].role == 0){data.relationship="Primary Owner"}else if(resp.data[x].role ==1){data.relationship="Referral Owner"}else{data.relationship="Watcher"}
                this.getTotalUser(element.data.data.address, this.date3,this.date4, x);
                this.getUserHotspot(element.data.data.address, x);
                this.requestLog3.push(data);
                x += 1;
          });
      }
      });
    },

        getTotalUser(address, dateinst, endinst,ind, commission = 0) {
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${dateinst}&max_time=${endinst}`,
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((resp) => {
         this.requestLog3[ind].total =   (resp.data.data.total - ((resp.data.data.total / 100) * commission)).toFixed(3) ;
      //   this.requestLog3[ind].total = resp.data.data.total;
        })
        .catch((resp) => {
          this.requestLog3[ind].total = 0;
        });
    },
        async getOwnerHotspotLower(address, ind) {
      var head = {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      };
      axios
        .get(`${API_BASE_URL}/allowner/${address}`, head)
        .then((result) => {
          if (result.data[0]) {
            this.requestLog2[ind].hostname = result.data[0].name;
            this.requestLog2[ind].user = result.data[0].email;
            this.requestLog2[ind].mobile = result.data[0].Mobile;
          } else {
            this.requestLog2[ind].user = "no owner";
          }
        });
    },
    async getOwnerHotspot(address, ind) {
      var head = {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      };
      axios
        .get(`${API_BASE_URL}/allowner/${address}`, head)
        .then((result) => {
          if (result.data[0]) {
            this.requestLog[ind].hostname = result.data[0].name;
            this.requestLog[ind].user = result.data[0].email;
            this.requestLog[ind].mobile = result.data[0].Mobile;
          } else {
            this.requestLog[ind].user = "no owner";
          }
        });
    },
        async getUserHotspot(address, ind) {
      var head = {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      };
      axios
        .get(`${API_BASE_URL}/allowner/${address}`, head)
        .then((result) => {
          if (result.data[0]) {
            this.requestLog3[ind].hostname = result.data[0].name;
            this.requestLog3[ind].user = result.data[0].email;
            this.requestLog3[ind].mobile = result.data[0].Mobile;
          } else {
            this.requestLog3[ind].user = "no owner";
          }
        });
    },
        getLowerthan(HNT,start,end) {
      this.requestLog2 = [];
      var x = 0;
      for (var i = 0; i < this.accountAdded.length; i++) {
        axios
          .get(
            `${API_HELIUM_URL}/v1/accounts/${this.accountAdded[i].address}/hotspots`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            resp.data.data.forEach((element, index) => {
                var data = {};
                data.status = element.status.online;
                data.name = element.name;
                data.address = element.address;
                data.location =
                  element.geocode.short_street +
                  " - " +
                  element.geocode.short_state +
                  " - " +
                  element.geocode.short_country +
                  " - " +
                  element.geocode.short_city;
                data.user = "no owner";
                data.wallet = element.owner
                data.mobile = "";
                data.hostname = "";
                data.total = 0;
                this.getbalance(element.address,start,end,100,x,HNT);
                this.getOwnerHotspotLower(element.address, x);
                this.requestLog2.push(data);
                x += 1;
            });
          });
      }
    },
    hotspotDet(address, start, today) {
      (this.dialog = false),
        axios
          .get(`${API_HELIUM_URL}/v1/hotspots/${address}`, {
            headers: { accept: "application/json" },
          })
          .then((resp) => {
            this.hotspotDetails = resp.data;
            var [year, month, day] = this.hotspotDetails.data.timestamp_added
              .substring(0, 10)
              .split("-");
            this.installation = new Date(year, month, day);
            axios
              .get(
                `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${this.installation
                  .toISOString()              
                  .slice(0, 10)}`,
                {
                  headers: { accept: "application/json" },
                }
              )
              .then((resp) => {
                this.hotspotTotal = resp.data;
                console.log('this.hotspotTotal', resp.data);
                this.mapurl = [
                  "https://www.openstreetmap.org/export/embed.html?bbox=" +
                    this.hotspotDetails.data.lng +
                    "%2C" +
                    this.hotspotDetails.data.lat +
                    "%2C" +
                    this.hotspotDetails.data.lng +
                    "%2C" +
                    this.hotspotDetails.data.lat +
                    "&layer=mapnik&marker=" +
                    this.hotspotDetails.data.lat +
                    "%2C" +
                    this.hotspotDetails.data.lng,
                ].join("");
                this.maxearn = parseInt(
                  ((this.hotspotTotal.data.total / 100) * 20).toFixed(2)
                );
              });
          });
    },
    openUser(userid) {
      this.$router.push({ name: "user", params: { id: userid } });
    },
    getbalance(address, dateinst, endinst, commission,ind,value) {
      console.log(address, dateinst, endinst)
      if (endinst == "null" || endinst == null) {
        endinst = "";
      }
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${dateinst}&max_time=${endinst}`,
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((resp) => {
          this.requestLog2[ind].total = (resp.data.data.total / 100) * commission;
          if (this.requestLog2[ind].total <= value){
              this.indexcount.push(ind)
          }
        })
        .catch((resp) => {
          this.requestLog2[ind].total = 0;
        });
    },
    getRequest() {
      axios
        .get(`${API_BASE_URL}/users`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          var requestLog = resp.data;
          requestLog.forEach(
            (obj) => (obj["createDate"] = obj["createDate"].substring(0, 10))
          );
          axios
            .get(`${API_BASE_URL}/usersHotspot`, {
              headers: {
                accept: "application/json",
                "x-access-token": this.token.accessToken,
              },
            })
            .then((resp) => {
              var hotspotOwn = resp.data;
              var i;

              for (i = 0; i < requestLog.length; i++) {
                if (
                  requestLog[i]["createdsign"] == "2021-07-06T18:47:22.000Z"
                ) {
                  requestLog[i]["createdsign"] = "";
                }
                if (requestLog[i]["signed"] == "") {
                  requestLog[i]["signed"] = "No";
                }
                if (requestLog[i]["signed"] == 0) {
                  requestLog[i]["signed"] = "No";
                }
                if (requestLog[i]["signed"] == 1) {
                  requestLog[i]["signed"] = "Yes";
                }
                if (
                  hotspotOwn.find(
                    ({ firebaseID }) => firebaseID === requestLog[i].firebaseID
                  )
                ) {
                  const result = hotspotOwn.find(
                    ({ firebaseID }) => firebaseID === requestLog[i].firebaseID
                  );
                  requestLog[i].assignHotspot = result.assignHotspot;
                } else {
                  requestLog[i].assignHotspot = 0;
                }
              }
              this.requestLog = requestLog;
            });
        });
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
      );
    },


    saveHotspot(address) {
      let payload = { haddress: address };
      let res = axios.put(
        `${API_BASE_URL}/hotspot/${this.uid}`,
        payload
      );
      let data = res.data;
    },
    checkfirstdays(role, startdate, firstdays) {
      var [year, month, day] = startdate.substring(0, 10).split("-");
      var installation = new Date();
      if (role == 1) {
        if (firstdays == 0) {
          //30 days NO
          installation = new Date(year, month - 1, day);
          installation.setDate(installation.getDate() + 1);
        } else {
          //30 days YES
          installation = new Date(year, month, day);
          installation.setDate(installation.getDate() + 1);
        }
      } else {
        //primary owner
        if (this.first30days == 0) {
          //30 days YES
          installation = new Date(year, month, day);
          installation.setDate(installation.getDate() + 1);
        } else {
          installation = new Date(year, month - 1, day);
          installation.setDate(installation.getDate() + 1);
        }
      }
      this.installation = installation.toISOString().substring(0, 10);
      return installation.toISOString().substring(0, 10);
    },
    getbalancee(address, dateinst, endinst, commission,firstday, type) {
      var final_comm = 0;
      if (type == "refer") {
        axios
          .get(`${API_BASE_URL}/user/hotspot/own/${address}`, {
            headers: {
              accept: "application/json",
            },
          })
          .then((resp) => {
            if (resp.data.length != 0) {
              final_comm = resp.data[0].commission;
            } else {
              final_comm = 20;
            }
            this.getbalanceStep2(
              address,
              dateinst,
              endinst,
              commission,
              final_comm
            );
          });
      } else {
        this.getbalanceStep2(
          address,
          dateinst,
          endinst,
          commission,
          final_comm
        );
      }
    },
    getbalanceStep2(address, dateinst, endinst, commission, final_comm) {
      var totalearn = 0;
      if (endinst == "null" || endinst == null) {
        endinst = "";
      }
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${dateinst.slice(
            0,
            10
          )}${endinst}`,
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((resp) => {
          totalearn = ((resp.data.data.total-(resp.data.data.total / 100) * final_comm))/ 100 * commission;
          for (var i = 0; i < this.requestLog3.length; i++) {
            if (this.requestLog3[i].address == address) {
              this.requestLog3[i].total = totalearn.toFixed(2);
            }
          }
        })
        .catch((resp) => {
          for (var i = 0; i < this.requestLog3.length; i++) {
            if (this.requestLog3[i].address == address) {
              this.requestLog3[i].total = (0).toFixed(2);
            }
          }
        });
    },
    getglobal() {
      axios
        .get(`${API_BASE_URL}/user/info/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            this.investor = resp.data[0].investor;
            if(resp.data[0].investor == 0){
              if(resp.data[0].signed==1 || resp.data[0].signed==null){this.signneed=false}
              else{this.signneed=true}
                (this.commission = resp.data[0].commission),
                (this.first30days = resp.data[0].FirstDays);
            }
          } catch (err) {
            this.error = true;
          }
        });
    },
     formatHotspotName(hotspotName) {
      let hotspot_name = hotspotName.replaceAll('-',' ');
      let hotspot_name_caps = hotspot_name.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
      return hotspot_name_caps;
    },
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    format_date(value){

      console.log("vin for alue = ",value)
        if (value) {
          return moment(String(value)).format('MM-DD-YYYY')
        }
    }
  },
  
};
</script>