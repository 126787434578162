<template> 
  <div>
    <div class="main-view-sec">

      <Loader v-if="loader"></Loader>

      <div class="page-headings">
        <h1 class="clearfix">
          Profile
          <span
            ><a href="javascript:void(0)" @click="redirect('dashboard')">Home</a>
            <a href="javascript:void(0)">Profile</a></span
          >
        </h1>
      </div> 
      <div class="row">
        <div class="col-sm-4">
          <div class="card-style m-b-30 theme-line">
            <div class="text-center">
              <div class="pro-media-main">
                  <a
                    href="javascript:void(0);"
                    class="fa fa-trash"
                    @click="deleteProfile = true"
                  ></a>
                  <div id="imageUpload" class="fileupload-image image-upload" v-bind:style="{ backgroundImage: 'url(' + image + ')' }">
                    <input
                      type="file"
                      title="Change Profile"
                      name="image"
                      v-on:change="changeImage"
                    />
                     <i class="fa fa-camera"></i>

                     <input v-if="selectedImage" v-model="selectedImage.name" type="hidden" name="photo" />

                  </div>
              </div>


              <h2 class="user-name">{{FirstName}} {{LastName}}</h2>
              <v-alert
                  dense
                  text
                  type="success"
                  v-if="profileImageSuccess"
                >
                  {{profileImageMessage}}
              </v-alert>



            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card-style m-b-30">
            <div class="box-head clearfix">PROFILE COMPLETION</div>
            <div class="progress-wrap progress" style="background-color:#eff3f6" data-progress-percent="20">
              <div class="progress-bar progress" :style="{'width':profileCompletionPercentage, 'background-color':'#184cf8'}"></div>
            </div>
            <h1 class="prog-counts-h1">{{profileCompletionPercentValue}} / <span>100%</span></h1>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card-style m-b-30">
            <div class="box-head clearfix">My Notifications</div>
            <div class="card-226 mCustomScrollbar">
              <ul class="alert-list">
                <li v-if="heliumstatus != 'operational'">
                  <a class="alert alert-danger" href="javascript:void(0)">{{ dynamicMessage.experiencing_diffculties }}
                  </a>
                </li>

                <li v-if="status == 'offline'">
                  <a class="alert alert-danger" href="javascript:void(0)">
                    {{ dynamicMessage.hotspot_offline }}
                  </a>
                </li>

                <li v-if="notamonth === false">
                  <a class="alert alert-danger" href="javascript:void(0)">
                    {{ dynamicMessage.will_start_earning }}
                  </a>
                </li>
               
              </ul>
            </div>
          </div> 
        </div>
      </div>
      <div class="card-style m-b-30">
        <div class="box-head clearfix">
          Personal Details
          <button
            type="button"
            class="btn edit-btn-new pull-right"
            data-toggle="modal"
            data-target="#myModal-edit"
            data-backdrop="static" data-keyboard="false"
          >
            <i class="fa fa-pencil"></i> Edit Profile
          </button>
        </div>
        <div class="form-horizontal profile-form-cust">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">First Name</label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{FirstName}}</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">Last Name</label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{LastName}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">Email</label>
                <div class="col-sm-8">
                  <p class="form-control-static">
                    {{contact.email}}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">Phone</label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{phone}}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">Mobile Walllet Address</label>
                <div class="col-sm-8">
                  <p class="form-control-static">
                    {{hmtAddress}}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">IOT Wallet Address</label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{iotAddress}}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label class="col-sm-2 control-label">HNT Wallet Address</label>
                <div class="col-sm-10">
                  <p class="form-control-static">
                    {{hntAddress}}
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          </br>
          <div class="box-head clearfix">
           Contact Address
          </div>
          
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">Address 1</label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{MailingStreet}}</p>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">Address 2</label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{MailingStreet2}}</p>
                </div>
              </div>
            </div>
            
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">City</label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{MailingCity}}</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">Region </label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{MailingState}}</p>
                </div>
              </div>
            </div>
          </div>
           
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">Zip code / Postal Code</label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{MailingZipCode}}</p>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">Country</label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{MailingCountry}}</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="card-style m-b-30">
        <div class="box-head clearfix">
          Change 2FA Type
        </div>
        <div class="profile-form-cust">
          
          <div>
            <div>
              <div class="form-group d-flex space-between">
                <div class="tf-check-radio">
                   <v-radio-group
                    v-model="twofactoroption"
                    row
                  >
                    <v-radio
                      label="Email"
                      value="Email"
                    ></v-radio>

                    <v-radio
                      label="Google Authenticator"
                      value="Google Authenticator"
                    ></v-radio>
                  </v-radio-group>
                </div>

                <div class="tf-check-save">
                   <button
                    type="button"
                    class="btn edit-btn-new"
                    @click ="handle2faoption"
                  >
                    <i class="fa fa-save"></i> Save
                  </button>
                </div>

              </div>
            </div>
          </div>

          <div>
            <v-alert
              dense
              text
              type="success"
              v-if="twofactorSuccessMsg"
            >
              {{twofactorSuccessMsg}}
            </v-alert>
          </div>

        </div>
      </div>

    </div>

    <div class="all-pop-main" style="">
      <div
        class="modal fade"
        id="myModal-edit"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        
      >
        <div class="modal-dialog " role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 class="modal-title" id="myModalLabel">Edit Profile</h4>
            </div>
           
            <div class="modal-body mCustomScrollbar">

              <v-form ref="form">
                <div class="form-group">
                  <v-text-field
                  v-model="contact.FirstName"
                  label="First Name*"
                  :rules="rules.firstnameRules"
                  type="text"
                  rows="0.5"
                >
                </v-text-field>
                </div>
                <div class="form-group">
                  <v-text-field
                    v-model="contact.LastName"
                    label="Last Name*"
                    :rules="rules.lastnameRules"
                    type="text"
                    rows="0.5"
                  >
                  </v-text-field>
                </div>
                <div class="form-group">
                 <v-text-field
                    v-model="contact.email"
                    label="Email / Username*"
                    type="email"
                    disabled
                    hide-details
                  >
                  </v-text-field>
                </div>
                <div class="form-group">
                  <v-text-field
                    v-model="contact.phone"
                    label="Phone*"
                    autocomplete="off"
                    :rules="rules.phoneRules"
                    type="text"
                  >
                  </v-text-field>
                </div>
                <div class="form-group">
                   <v-text-field
                    v-model="contact.hntAddress"
                    label="HNT Wallet Address*"
                    auto-grow
                    rows="1"
                    type="text"
                    row-height="15"
                    autocomplete="off"
                    :rules="rules.hntAddressRules"
                  >
                  </v-text-field>
                </div>

                <div class="form-group">
                   <v-text-field
                    v-model="contact.hmtAddress"
                    label="Mobile Wallet Address*"
                    auto-grow
                    rows="1"
                    type="text"
                    row-height="15"
                    autocomplete="off"
                  >
                  </v-text-field>
                </div>

                <div class="form-group">
                   <v-text-field
                    v-model="contact.iotAddress"
                    label="IOT Wallet Address*"
                    auto-grow
                    rows="1"
                    type="text"
                    row-height="15"
                    autocomplete="off"
                  >
                  </v-text-field>
                </div>

       
 
                <div class="form-group">
                   <v-textarea
                    v-model="contact.MailingStreet"
                    label="Address 1*"
                    auto-grow
                    rows="1"
                    
                    row-height="15"
                    :rules="rules.addressLine1Rules"
                    
                  >
                  </v-textarea>
                </div>
                <div class="form-group">
                   <v-textarea
                    v-model="contact.MailingStreet2"
                    label="Address 2"
                    auto-grow
                    rows="1"
                     row-height="15"
                    :rules="rules.addressLine2Rules"
                    
                  >
                  </v-textarea>
                </div>

                <div class="form-group">
                   <v-text-field
                    v-model="contact.MailingCity"
                    label="City*"
                    type="text"
                    :rules="rules.cityRules"
                  >
                  </v-text-field>
                </div>

                <div class="form-group">
                   <v-text-field
                    v-model="contact.MailingState"
                    label="Region*"
                    type="text"
                    :rules="rules.stateRules"
                  >
                  </v-text-field>
                </div>
 
                <div class="form-group">
                   <v-text-field
                    v-model="contact.MailingZipCode"
                    label="Zip Code*"
                    type="text"
                    :rules="rules.zipCodeRules"
                  >
                  </v-text-field>
                </div>

                <div class="form-group">
                   <v-text-field
                    v-model="contact.MailingCountry"
                    label="Country*"
                    type="text"
                    :rules="rules.countryRules"
                  >
                  </v-text-field>
                </div>
               
                 
              </v-form>

              
            </div>
           
           
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" @click="save" class="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- profile page delete model-->
    <v-dialog
        v-model="deleteProfile"
        max-width="350px">

        <v-card>
        
          <div class="pa-3">
            Are you sure want to delete profile picture?
          </div>
         
           <v-divider></v-divider>
         
            <v-card-actions>
              <button
              class="log-btn btn default"
              type="button"
              @click="deleteImage"
            >
              Delete
            </button>

            <button
              class="log-btn btn error"
              type="button"
              @click="deleteProfile = false"
            >
              Cancel
            </button>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- user information updated success message model-->
    <v-dialog
        v-model="userInformationUpdatedSuccess"
        max-width="350px" style="z-index:99999">

        <v-card>
        
          <div class="pa-3">
            <h3 class="text-center">
             User information updated successfully.
            </h3>
          </div>
         
           <v-divider></v-divider>
         
            <v-card-actions>
            

            <button
              class="log-btn btn success"
              type="button"
              @click="userInformationUpdatedSuccess = false"
            >
              Okay
            </button>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- profile page delete model end-->


    <!-- 2fa start-->
    <v-dialog persistent width="500" v-model="twofactor">
      <v-card>

        <v-card-title class="headline purple darken-1 white--text">
          Two Factor Autentication is Needed
        </v-card-title> 

        <!-- 2fa using email -->
        <v-card-text class="pa-3 2fa_email_code_email" v-if="twofactoroption=='Email'">
          <div>
            <span>
              Please check your email for Two-factor authentication..
            </span>
            <div>
              <div align="center" justify="center">
                <template>
                   <v-text-field
                      v-model="form['2fa_email_code']"
                      label="2FA"
                      type="text"
                    ></v-text-field>
                </template>
              </div>

              <div>
                <v-btn color="success" @click="twofactorSubmit()">Submit 
                   <v-progress-circular
                      v-if="loading"
                      :size="20"
                      :width="2"
                      color="white"
                      indeterminate
                    ></v-progress-circular>
                </v-btn>
              </div>

              <div v-if="twofactorError" align="center" justify="center" style="margin-top: 24px">
                <v-alert type="error" transition="fade-transition">
                  {{twofactorErrorMsg}}
                </v-alert>
              </div>
              <div v-if="online" align="center" justify="center" style="margin-top: 24px">
                <v-alert type="error">
                   Please check your internet connection and try again.
                </v-alert>
              </div>

            </div>
          </div>
        </v-card-text>

       
        <!-- 2fa using google auth -->
        <v-card-text class="pa-3 2fa_email_code_google" v-if="twofactoroption=='Google Authenticator'">
          <div>
            <span v-if="twofa">
              Scan this QR code with Google Autenticator and do not
              delete the app. If you remove that you will not be able to
              restore your code. Once you already have the Google
              Authenticator code. Then enter the code provided from the
              app.
            </span>
             <span v-if="!twofa">
              Please enter the sign-in 2FA code from your authenticator app.  
              If having issues, then reach out to <strong>support@jagindustrials.com</strong>.
            </span>
            <div>
              <v-row v-if="twofa" align="center" justify="center">
                <template>
                  <figure>
                    <vue-qrcode
                      :value="this.authkey"
                      :options="{
                        errorCorrectionLevel: 'Q',
                        width: 200,
                        height: 200,
                      }"
                    ></vue-qrcode>
                  </figure>
                </template>
                <v-btn icon color="green" @click="getNewQR()">
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </v-row>
              <v-row align="center" justify="center">
                <span>
                  Please enter the OTP here to check your settings
                </span>
              </v-row>
              <v-row align="center" justify="center">
                <CodeInput
                  :key="componentKey"
                  :loading="false"
                  :autoFocus="true"
                  class="input"
                  v-on:change="onChange"
                  v-on:complete="onComplete"
                />
              </v-row>
              <br /><br />
              <v-alert type="success" v-model="correcttoken"
                >The Generated Token is Correct</v-alert
              >
              <v-alert type="error" v-model="errortoken"
                >The Generated Token is Incorrect</v-alert
              ><br />

              <v-alert type="error" v-model="online"
                >Please check your internet connection and try again.</v-alert
              ><br />
              
            </div>
          </div>
        </v-card-text>

      </v-card>
    </v-dialog>
    <!-- 2fa end-->

  </div>
</template>
<script>

import firebase from "firebase";
import axios from "axios";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import Loader from '../../components/loader';
import "./index.css";
const API_URL = `${API_BASE_URL}/api/auth/`;
import { API_BASE_URL, API_HELIUM_URL } from "../../constants";
import { bus } from '../../main';

export default {
  name: "App",
  components:{ Loader },
  data() {
    return {
      deleteProfile:false,
      userInformationUpdatedSuccess: false,
      filepath: '',
      signed: '',
      sucessAlert: false,
      profileImageSuccess:false,
      profileImageMessage:'',
      uid: this.$cookies.get("uid"),
      info: {},
      FirstName: '',
      LastName: '',
      hntAddress: '',
      hmtAddress: '',
      iotAddress: '',
      phone: '',
      MailingStreet:"",
      MailingStreet2:"",
      MailingCity:"",
      MailingState:"",
      MailingZipCode:"",
      MailingCountry:"",
      contact: {
        name: '',
        email: '',
        FirstName: '',
        LastName: '',
        hntAddress: '',
        hmtAddress: '',
        iotAddress: '',
        paypalAddress: '',
        zelleAddress: '',
        phone: '',
        btcAddress: '',
        ethAddress: '',
        venmoAddress: '',

        MailingStreet:"",
        MailingStreet2:"",
        MailingCity:"",
        MailingState:"",
        MailingZipCode:"",
        MailingCountry:""
      },
      status: "not available",
      heliumstatus:"operational",
      notamonth: true,
        dynamicMessage: {
        vaild_range: "",
        header: "",
        leased_hotspot_no_data: "",
        dashboard_loading: "",
        experiencing_diffculties: "",
        hotspot_offline: "",
        will_start_earning: "",
        start_end_date_validation: "",
        valid_data_range: "",
      },
        profile_image_uploaded: true,

      investor: 0,


      profileCompletionPercentValue: 0,
      profileCompletionPercentage: "0%",
      allowableTypes: ['jpg', 'jpeg', 'png'],
      maximumSize: 5000000,//5MB
      selectedImage: null,
      image: null,
      rules: { 
        requiredRules: [(v) => !!v || "This field is required"],
        firstnameRules:[
            v => !!v || 'First Name is required',
            v => !!/^[a-z]+$/i.test(v) || 'First Name must be valid',
        ],
        lastnameRules:[
            v => !!v || 'Last Name is required',
            v => !!/^[a-z]+$/i.test(v) || 'Last Name must be valid',
        ],
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) || 'E-mail must be valid',
        ],
        phoneRules: [
            v => !!v || 'Phone is required',
            v => !!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/im.test(v) || 'Phone must be valid',
        ],
        zipCodeRules:[
            v => !!v || 'Zip is required',
            v => !!/^(\d{5})?$/.test(v) || 'Zip must be valid',
        ],
        countryRules:[
            v => !!v || 'Country is required',
            v => !!/^[a-z\s]+$/i.test(v) || 'Country must be valid',
        ],
        stateRules:[
            v => !!v || 'State is required',
            v => !!/^[a-z\s]+$/i.test(v) || 'State must be valid',
        ],
        cityRules:[
            v => !!v || 'City is required',
            v => !!/^[a-z\s]+$/i.test(v) || 'City must be valid',
        ],
        addressLine1Rules:[
            v => !!v || 'Adddress 1 is required',
            v => !!/^.{0,1000}$/.test(v) || 'Adddress 1 should be less than 1000 character',
        ],
        addressLine2Rules:[
            v => !!/^.{0,1000}$/.test(v) || 'Adddress 2 should be less than 1000 character',
        ],
        hntAddressRules:[
          v => !!v || 'HNT wallet address is required',
            // v => !!/^.[a-zA-Z0-9]{50,50}$/.test(v) || 'Please enter valid 51 characters HNT wallet address.',
        ],
        hmtAddressRules:[
          v => !!v || 'HMT wallet address is required',
            v => !!/^.[a-zA-Z0-9]{50,50}$/.test(v) || 'Please enter valid 51 characters HMT wallet address.',
        ],
        iotAddressRules:[
          v => !!v || 'IOT wallet address is required',
            v => !!/^.[a-zA-Z0-9]{50,50}$/.test(v) || 'Please enter valid 51 characters IOT wallet address.',
        ]
      },




      form: {
          password: "",
          "2fa_email_code":  ""
      },
      twofactor: false,
      twofactorError: false,
      twofactorErrorMsg: '',
      twofactorSuccessMsg: null,
      twofactorselection:false,
      twofa:false,
      twofactoroption:"",
      correcttoken: false,
      errortoken: false,
      online:false,
      componentKey: 0,
      authkey: "",
      "2fa_type":0,
      googleTwoValue: '',
      loading:false,
      loader:true
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },

  beforeMount() {

    
    //get user from local storage
    this.User = JSON.parse(localStorage.getItem('User'));

    console.log("this.User = ",this.User)

    this.contact.name = this.User.displayName,
    this.contact.email = this.User.email;

    this.uid = this.User.uid;


    this.getUid();

    axios
      .get(`${API_BASE_URL}/user/info/${this.uid}`, {
        headers: { accept: "application/json" },
      })
      .then((resp) => {

        try {
          this.investor = resp.data[0].investor; 
          (this.signed = resp.data[0].signed),
            (this.filepath = resp.data[0].filepath);

            //get default
            this['2fa_type'] = resp.data[0]['2fa_type'];
            this.googleTwoValue =  resp.data[0]['2fa'];
            

            if(this['2fa_type'] == 1){
              this.twofactoroption = 'Email';
            }
            else if(this['2fa_type'] == 2){
              this.twofactoroption = 'Google Authenticator';
            }else{
              this.twofactoroption = 'Email';
            }

            if(resp.data[0].profile_image){
              //enable delete icon
              document.querySelector(".fa-trash").style.opacity = 1;

              this.image = API_BASE_URL + resp.data[0].profile_image
              this.profile_image_uploaded =true;
              
            }else{
              //enable delete icon
              document.querySelector(".fa-trash").style.opacity = 0;
              this.image = 'img/test-profile.26f15de2.jpg';
              this.profile_image_uploaded =false;
            }
              this.getprofileCompletionPercent();
        } catch (err) {
          this.error = true;
          console.log("empty profile");
            this.getprofileCompletionPercent();
        }
      });
          this.getHotspotName();
  },
   mounted() {
     this.getprofileCompletionPercent();
     this.getDynamicMessages();
     this.getAPIStatus();

     //hide page loader  
      setTimeout(() => {
        this.loader = false;
      }, 2000)
   },
   created (){

    //listen to sidebar leased hotspot event
    bus.$on('changeHotspot', (data) => {


      console.log("in changeHotspot dashboard = ",data)

      this.getHotspot(data.name);
    })
  },
  methods: {
    redirect(link) { //custom redirect method
      if(this.investor == 1){
          this.$router.push('investor');
      }else{
          this.$router.push('dashboard');
      }
    },
    getHotspot(hname) {
      this.loader = true;
      this.hotspotname = this.formatHotspotName(hname);

      this.sums = 0;
      axios
        .get(`${API_HELIUM_URL}/v1/hotspots/name/${hname}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {

          this.haddress = resp.data.data[0].address;
          this.status = resp.data.data[0].status.online;

          //hide page loader
          setTimeout(() => { this.loader = false;}, 1000)
        
        }).catch((err) => {
          //hide page loader
          setTimeout(() => { this.loader = false;}, 1000)
          console.log(err);
        });
    },
     getHotspotName() {
      var i;
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.hotspotAll = resp.data;

          for (i = 0; i < this.hotspotAll.length; i++) {


                 var today = new Date();
                 var end = this.hotspotAll[i].removeDate? new Date(this.hotspotAll[i].removeDate.slice(0,10)):today;

                 //compare only date
                 today.setHours(0,0,0,0)

                 
                 //check hotspot end date display accordingly
                 if(end >= today){
                    

                    axios
                      .get(
                        `${API_HELIUM_URL}/v1/hotspots/${this.hotspotAll[i].Haddress}`,
                        {
                          headers: {
                            accept: "application/json",
                          },
                        }
                      )
                      .then((resp) => {
                        this.hots.push(resp.data.data.name);

                      });


                 }else{
                    console.log("hotspot end is passed = ",end)
                 }
            
          }
        }).catch((err) => {
          setTimeout(() => {
            this.loader = false;
          }, 1000)
          console.log(err);
        });
    },
    getUid() {
      axios
        .get(`${API_BASE_URL}/user/payment/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            (this.info = resp.data[0]), this.populateField(resp.data[0]);
            this.getprofileCompletionPercent();
          } catch (err) {
            console.log("empty profile");
          }
        });
    },
    populateField(arr) {
      //personal info
      try {
        this.contact.FirstName = arr.FirstName;
        this.FirstName = arr.FirstName;

        this.contact.LastName = arr.LastName;
        this.LastName = arr.LastName;

        this.contact.hntAddress = arr.HNT;
        this.hntAddress = arr.HNT;

        this.contact.phone = arr.Mobile;
        this.phone = arr.Mobile;

        this.contact.hmtAddress = arr.HMT;
        this.hmtAddress = arr.HMT;

        this.contact.iotAddress = arr.IOT;
        this.iotAddress = arr.IOT;

      } catch (err) {
        console.log("empty value");
      }


      //contact address
      try {

        this.contact.MailingStreet = arr.MailingStreet;
        this.MailingStreet = arr.MailingStreet;

        this.contact.MailingStreet2 = arr.MailingStreet2;
        this.MailingStreet2 = arr.MailingStreet2;

        this.contact.MailingCity = arr.MailingCity;
        this.MailingCity = arr.MailingCity;

        this.contact.MailingState = arr.MailingState;
        this.MailingState = arr.MailingState;

        this.contact.MailingZipCode = arr.MailingZipCode;
        this.MailingZipCode = arr.MailingZipCode;

        this.contact.MailingCountry = arr.MailingCountry;
        this.MailingCountry = arr.MailingCountry;


      } catch (err) {
        console.log("empty value");
      }

    },
    save() {

      if (this.$refs.form.validate()) {
        let payload = {
          FirstName: this.contact.FirstName,
          LastName: this.contact.LastName,
          hnt: this.contact.hntAddress,
          paypal: this.contact.email,
          zelle: this.contact.zelleAddress,
          mobile: this.contact.phone,
          btc: this.contact.btcAddress,
          eth: this.contact.ethAddress,
          venmo: this.contact.venmoAddress,
          hmt: this.contact.hmtAddress,
          iot: this.contact.iotAddress,

          MailingStreet :this.contact.MailingStreet,
          MailingStreet2 :this.contact.MailingStreet2,
          MailingCity :this.contact.MailingCity,
          MailingState :this.contact.MailingState,
          MailingZipCode :this.contact.MailingZipCode,
          MailingCountry :this.contact.MailingCountry
        };
        axios.put(`${API_BASE_URL}/update/user/profile/${this.uid}`, payload),
          { headers: { "Content-Type": "application/json" } };
        this.sucessAlert = true;
      this.userInformationUpdatedSuccess = true,
        //hide msg after 4 sec
        setTimeout(()=>{
          this.sucessAlert = false;
          this.userInformationUpdatedSuccess = false,
          this.FirstName = this.contact.FirstName;
          this.LastName = this.contact.LastName;
          this.hntAddress = this.contact.hntAddress;
          this.phone = this.contact.phone;


          this.MailingStreet =this.contact.MailingStreet;
          this.MailingStreet2 =this.contact.MailingStreet2;
          this.MailingCity =this.contact.MailingCity;
          this.MailingState =this.contact.MailingState;
          this.MailingZipCode =this.contact.MailingZipCode;
          this.MailingCountry =this.contact.MailingCountry;


        },4000);

          this.getprofileCompletionPercent();
      }
        
    },
    getContract() {
      let winowInstance = window.open();
      axios
        .get(`${API_BASE_URL}/user/contract/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            console.log(resp.data);
            var id = resp.data.sid;
            winowInstance.location.href = `${API_BASE_URL}/download?sid=` + id, "_blank";
          } catch (err) {
            console.log("no contract signed");
          }
        });
    },


    validate(image) {
      if (!this.allowableTypes.includes(image.name.split(".").pop().toLowerCase())) {
        alert(`Sorry you can only upload ${this.allowableTypes.join("|").toUpperCase()} files.`)
        return false
      }

      if (image.size > this.maximumSize){
        alert("Sorry File size exceeding from 5 Mb")
        return false
      }

      return true
    },
    onImageError(err){
      console.log(err, 'do something with error')
    },
    changeImage($event) {
      this.selectedImage = $event.target.files[0]

      //validate the image
      if (!this.validate(this.selectedImage))
        return

      // create a form
      const form = new FormData();
      form.append('file', this.selectedImage);
      form.append('name', this.uid);

      const URL = `${API_BASE_URL}/uploadprofile/${this.uid}`;
      axios.post( URL,
              form,
              {
              headers: {
                  'Content-Type': `multipart/form-data; boundary=${form._boundary}`
              }
            }
          ).then(this.createImage)
           .catch(this.onImageError);

    },
    createImage() {
      const image = new Image()
      const reader = new FileReader()
      
      reader.onload = (e) => {
        this.image = e.target.result;

        this.profileImageMessage = "Profile picture updated successfully.";

        //set image to top right sec
        document.querySelector(".user-dash-pros.image-upload").style.backgroundImage = 'url(' + this.image + ')';

        //enable delete icon
        document.querySelector(".fa-trash").style.opacity = 1;

        //hide after few sec
        this.profileImageSuccess=true;
        this.profile_image_uploaded =true;
        this.getprofileCompletionPercent();
        setTimeout(()=>{
          this.profileImageSuccess=false;
        },5000)
      };
      reader.readAsDataURL(this.selectedImage)
    },

    getprofileCompletionPercent (){
      var counter = 0;
      var totalFields = 12;


      if(this.contact.FirstName!= null && this.contact.FirstName != undefined && this.contact.FirstName.length > 0){
        counter++;
      }

      if(this.contact.LastName!= null && this.contact.LastName != undefined && this.contact.LastName.length > 0){
        counter++;
      }



      if(this.profile_image_uploaded){
        counter++;
      }
      if(this.contact.phone!= null && this.contact.phone != undefined && this.contact.phone.length > 0){
        counter++;
      }


      if(this.contact.hntAddress!= null && this.contact.hntAddress != undefined && this.contact.hntAddress.length > 0){
        counter++;
      }

      if(this.contact.hmtAddress!= null && this.contact.hmtAddress != undefined && this.contact.hmtAddress.length > 0){
        counter++;
      }

      if(this.contact.iotAddress!= null && this.contact.iotAddress != undefined && this.contact.iotAddress.length > 0){
        counter++;
      }

      if(this.contact.MailingStreet!= null && this.contact.MailingStreet != undefined && this.contact.MailingStreet.length > 0){
        counter++;
      }
    

      if(this.contact.MailingCity!= null && this.contact.MailingCity != undefined && this.contact.MailingCity.length > 0){
        counter++;
      }

      if(this.contact.MailingState!= null && this.contact.MailingState != undefined && this.contact.MailingState.length > 0){
        counter++;
      }

      if(this.contact.MailingZipCode!= null && this.contact.MailingZipCode != undefined && this.contact.MailingZipCode.length > 0){
        counter++;
      }

      if(this.contact.MailingCountry!= null && this.contact.MailingCountry != undefined && this.contact.MailingCountry.length > 0){
        counter++;
      }

      this.profileCompletionPercentValue = Math.ceil((counter/totalFields) * 100);
      this.profileCompletionPercentage = Math.ceil((counter/totalFields) * 100)+"%";

    },
    deleteImage(){//delete profile picture
      this.deleteProfile = false;
      document.querySelector(".user-dash-pros.image-upload").style.backgroundImage = "";
      document.querySelector("#imageUpload").style.backgroundImage = "";

      //disable delete icon
      document.querySelector(".fa-trash").style.opacity = 0;

      this.profileImageMessage = "Profile picture deleted successfully.";


      let payload = {};

      const URL = `${API_BASE_URL}/deleteprofile/${this.uid}`;
      axios.put(URL, payload),
          { headers: { "Content-Type": "application/json" } };
     
      this.profileImageSuccess=true;
      setTimeout(()=>{
        this.profileImageSuccess=false;


      },4000);

       this.profile_image_uploaded = false;
       this.getprofileCompletionPercent();
      
    },
    handle2faoption(){

      if(this.twofactoroption == 'Email'){
          this.twofactorselection = false;
          this.twofactor = true;

          
          this.validateTwoFactorAuth();
      }
      else if(this.twofactoroption == 'Google Authenticator'){
          this.twofactorselection = false;
          this.twofactor = true;

          if(!this.googleTwoValue){
            this.twofa = true;
            this.generateQrCode();
          }
      }
    },
    twofactorSubmit(){

      if(window.navigator.onLine){

          this.online = false;

          if(!this.form['2fa_email_code']){
            this.twofactorError = true;
            this.twofactorErrorMsg = 'Please enter 2-FA code.'
            return false;
          }
          this.validateTwoFactorAuth();

      }else{
          this.online = true;
          setTimeout(()=>{this.twofactor = false;this.online = false;},5000)
      }

    },
    validateTwoFactorAuth(){

      this.error2fa = null;
      this.loading = true;
      axios
        .post(API_URL + "validate2fa", {
          username: this.contact.email,
          "2fa_email_code": this.form['2fa_email_code']
        })
        .then((response) => {

          
          setTimeout(()=>{this.loading = false;},1500)

          if(response.data.status == 1){

            console.log("2fa sent complete successfull login")

            this.twofactor = true;
            return false;
          }else {

            console.log("2fa successfull");
            this.form['2fa_email_code'] = "";
            this.twofactor = false;
            this.twofactorErrorMsg = "";
            this.twofactorError = false;

            //update the type from the db
            this.save2faOption();
            
          }
        })
        .catch(error  => {
            console.log('inisde catch');
            this.loading = false;
            if (error.response) {
                if(error.response.data.status == 0){
                  this.twofactorErrorMsg = error.response.data.message;
                  this.twofactorError = true;
                }else{
                  this.error2fa = error.response.data.message;
                }
            }

        });
    },
    save2faOption(){

      if(this.twofactoroption == 'Email'){
        this['2fa_type'] = 1;
      }else{
        this['2fa_type'] = 2;
      }

      axios
      .put(`${API_BASE_URL}/user/update2faType/${this.uid}`, {
          "2fa_type": this['2fa_type']
        })
        .then((resp) => {

          this.twofactorSuccessMsg = "2FA type updated sucessfully.";

          setTimeout(()=>{
            this.twofactorSuccessMsg = null;
          },5000);
           
        }).catch((resp) => {
          console.log("resprr = ",resp)
        });

    },
    validateTwoFactorAuthGoogle() {
      axios
        .get(`${API_BASE_URL}/user/info/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            this.email = resp.data[0].email;
            console.log('resp.data[0]["2fa"]', resp.data[0]["2fa"]);
            if (resp.data[0]["2fa"] == null) {
              this.generateQrCode();
              this.twofa = true
              this.twofactor = true;
              
            } else {
              this.twofa = false
              this.twofactor = true;
            }
          } catch (err) {
            this.error2fa = true;
            console.log("empty profile");
          }
        });
    },
    forceRerender() {
      this.componentKey += 1;
    },
    getNewQR() {
      this.generateQrCode();
    },
    generateQrCode() {
      axios
        .get(`${API_BASE_URL}/user/2fa/${this.uid}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          console.log(resp);
          this.authkey = resp.data;
        });
    },
    onChange(v) {
      this.correcttoken = false;
      this.errortoken = false;
    },
    onComplete(v) {

      if(window.navigator.onLine){

          this.online = false;
          var data = {
            token: v,
          };
          axios
          .post(`${API_BASE_URL}/user/token/${this.uid}`, data, {
            headers: {
              accept: "application/json",
            },
          })
          .then((resp) => {
            console.log(resp, data);

            this.errortoken = false;
            this.forceRerender();
            if (resp.data == true) {
              this.correcttoken = true;
              this.twofactor = false;
              this.dialog2 = true;
              this.correcttoken = false;

              //update the type from the db
              this.save2faOption();


            } else {
              this.errortoken = true;
            }

          }).catch((resp) => {
            console.log("resprr = ",resp)
          });
      }else{
        this.online = true;
        this.forceRerender();
            
        setTimeout(()=>{this.twofactor = false;this.online = false;},5000)
      }

    },
       getAPIStatus() {
      axios.get(`http://status.helium.com/api/v2/summary.json`, {
          headers: {
              'accept': 'application/json'
          },
      }).then((resp) => {
          for(var x in resp.data.components){
              if (resp.data.components[x].name == "API"){
                  this.heliumstatus = resp.data.components[x].status
              }
          }
      }).catch((err) => {
          console.log(err);
      });
    },
    getDynamicMessages() {
      axios
        .get(`${API_BASE_URL}/getMessageConfiguration/dashboard`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            this.dynamicMessage = resp.data;
          } catch (err) {
            this.error = true;
          }
        });

    },
    formatHotspotName(name){
      let newName = name.replace(/-/g, " ").toLowerCase().replace(/\b[a-z]/g, function(letter) {
          return letter.toUpperCase();
      });

      return newName;
    }
  },
};
</script>
