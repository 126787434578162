<template>
  <div class="main-view-sec">

    <Loader v-if="loader"></Loader>

    <div class="page-headings">
      <h1 class="clearfix">
        Dashboard
        <span
          ><a href="javascript:void(0)">Home</a>
          <a href="javascript:void(0)">Dashboard</a></span
        >
      </h1>
    </div>


    <div class="dash-chart-outer" >
      


      <div class="row" v-if="heliumstatus != 'operational' || status == 'offline' || notamonth === false || daterror === false">

        <div class="col-sm-12">
          <div class="card-style m-b-30">
            <div class="box-head clearfix" >Alerts</div>
            <!-- <MessageBanner></MessageBanner> -->
            <div class="mCustomScrollbar">
              <ul class="alert-list">
                <li v-if="heliumstatus != 'operational'">
                  <a class="alert alert-warning" href="javascript:void(0)">
                    {{ dynamicMessage.experiencing_diffculties }}
                  </a>
                </li>

                <li v-if="status == 'offline'">
                  <a class="alert alert-danger" href="javascript:void(0)" v-on:click="showTaC"> 
                  {{ dynamicMessage.hotspot_offline }}
                  </a>
                </li>

                <li v-if="notamonth === false">
                  <a class="alert alert-warning" href="javascript:void(0)"> 
                  {{ updateMessageContent(dynamicMessage.will_start_earning) }}
                  </a>
                </li>

                 <li v-if="daterror === false">
                  <a class="alert alert-danger" href="javascript:void(0)"> 
                  {{ dynamicMessage.start_end_date_validation }}
                  </a>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
      
      <div class="filter-list">
        <div class="row">
          <div class="col-sm-5">
            <div class="input-group">
                <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto">

                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date1"
                        label="Start Date"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        @click:prepend="menu1 = true"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        :format="format_date"
                      >
                        
                      </v-text-field>
                    </template>

                    <v-date-picker
                      v-model="date3"
                      no-title
                      :min="this.installation"
                      :max="this.todaydate.toISOString().slice(0, 10)"
                      @input="menu1 = false"
                    ></v-date-picker> 
                  </v-menu>
                  <span class="input-group-addon" id="basic-addon" @click="menu1 = true"><i class="fas fa-calendar-alt"></i></span>
            </div>
          </div>
          <div class="col-sm-5">
            <div class="input-group">
              
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date2"
                    label="End Date"
                    persistent-hint
                    @click:prepend="menu2 = true"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    :format="format_date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date4"
                  no-title
                  :max="this.todaydate.toISOString().slice(0, 10)"
                  :min= "date1"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>


              <span class="input-group-addon" id="basic-addon2" @click="menu2 = true"><i class="fas fa-calendar-alt"></i></span>
            </div>
          </div>
          <div class="col-sm-2">
            <button type="button" class="btn" @click="loader = true;filtereward(haddress, installation, date1, date2, commission);">
              <i class="fas fa-filter"></i> Filter Data
            </button>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-sm-12">
          <div class="card-style m-b-30">
            <div class="box-head clearfix">
              Wallet Rewards <span v-bind:class="{ 'offline-card': status == 'offline','online-card': status == 'online' }" ><span >{{this.hotspotname}} ({{this.status}})</span></span>
              <!--<div class="pull-right custom-rad">
                <p>Show Earnings for ALL Leased Hotspots</p>
                <label class="toggle-label">
                  <input type="checkbox" />
                  <span class="back">
                    <span class="toggle"></span>
                    <span class="label on">ON</span>
                    <span class="label off">OFF</span>
                  </span>
                </label>
              </div>-->

              
              <span class="pull-right" v-if="hotspotType == 'HNT'">
                 <b>Earnings for Selected Time Period</b> 
                 {{ sums.toFixed(2) }} HNT | ${{
                  (sums * (oracleprice / 100000000)).toFixed(2)
                }}
              </span>

              <span class="pull-right" v-if="hotspotType == '5G'">
                 <b>Earnings for Selected Time Period</b> 
                 {{hmtSum}} HMT | N/A
              </span>

              
              <span class="pull-right" v-if="hotspotType == 'IoT'">
                 <b>Earnings for Selected Time Period</b> 
                 {{iotSum}} IOT | N/A
              </span>

            </div>
            <div class="box-head-sub clearfix">
              Start Earning Date {{ formatDate(installation) }}
              <!--<span class="pull-right">
                 <b>Earnings for Selected Time Period</b> 
                 {{ sums.toFixed(2) }} HNT | ${{
                  (sums * (oracleprice / 100000000)).toFixed(2)
                }}
              </span>-->
            </div>
            <div class="card-450 card-auto-he">
              <!--<canvas id="myChart" height="100vh"></canvas>-->
              <v-card class="my-2 pa-4">
                <apexchart
                  type="bar"
                  height="350"
                  :options="chartOptions1"
                  :series="series"
                ></apexchart>
              </v-card>
            </div>


            <div class="row">
              <div class="col-sm-3">
                <div class="media day-rev alert-success">
                  <div class="media-body">
                    <h4 class="media-heading">{{ today }}<span>{{hotspotType == '5G' ? 'Mobile' : hotspotType}}</span></h4>
                    <p>Today</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="media day-rev alert-success">
                  <div class="media-body">
                    <h4 class="media-heading">{{ yesterday }}<span>{{hotspotType == '5G' ? 'Mobile' : hotspotType}}</span></h4>
                    <p>Yesterday</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="media day-rev alert-success">
                  <div class="media-body">
                    <h4 class="media-heading">{{ week }}<span>{{hotspotType == '5G' ? 'Mobile' : hotspotType}}</span></h4>
                    <p>7 days earn</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="media day-rev alert-success">
                  <div class="media-body">
                    <h4 class="media-heading">{{ month }}<span>{{hotspotType == '5G' ? 'Mobile' : hotspotType}}</span></h4>
                    <p>30 day earn</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 

      
    
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import { API_BASE_URL, API_HELIUM_URL, API_HELIUM_HMT_URL, HOTSPOTTY_BASE_URL, HOTSPOTTY_TOKEN } from "../../constants";
import MessageBanner from "../../components/messageBanner";
import Loader from '../../components/loader';
import { bus } from '../../main';
import './index.css';
import _ from 'lodash';
 
export default {
  components: { Loader,MessageBanner },
  data() {
    return {
      uid: "",
      haddress: "",
      oracleprice: "",
      sums: 0,
      yesterday: 0,
      loading: false,
      menu1: false,
      menu2: false,
      signneed: false,
      hotspotType:  '',
      hmtSum: '',
      iotSum: '',
      status: "not available",
      heliumstatus:"operational",
      daterror: true,
      datareverse: true,
      date1: moment().subtract(1, 'month').format('MM-DD-YYYY'),
      date3: moment().subtract(1, 'month').format('YYYY-MM-DD'),
      date2: moment().format('MM-DD-YYYY'),
      date4: moment().format('YYYY-MM-DD'),
      month: 0,
      week: 0,
      arr: {},
      todaydate: new Date(),
      hotspotTotal: [],
      installation: "00-00-0000",
      hotspotEndDate:'',
      notamonth: true,
      hname: "",
      today: 0,
      first30days: 0,
      commission: 20,
      hots: [],
      loader:true,
      hotspotname:"",
      hostCommission: '',
      dynamicMessage: {
        vaild_range: "",
        header: "",
        leased_hotspot_no_data: "",
        dashboard_loading: "",
        experiencing_diffculties: "",
        hotspot_offline: "",
        will_start_earning: "",
        start_end_date_validation: "",
        valid_data_range: "",
      },
      chartOptions1: {
        chart: {
          height: 450,
          type: "bar",
          reset: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
        },

        grid: {
          row: {
            colors: ["#fff", "#f2f2f2"],
          },
        },
        xaxis: {
          labels: {
            rotate: 0,
          },
          categories: [],
          tickPlacement: "on",
          tickAmount: 15,
        },
        yaxis: {
          title: {
            text: "Reward",
          },
          tickAmount: 10,
        },
        colors: ["#184cf8"],
        fill: {
          type: "fill",
          gradient: {
            type: "vertical",
            opacityFrom: 1,
            opacityTo: 1,
          },
        },
      },
      chartOptions2: {
        chart: {
          height: 450,
          type: "area",
          reset: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
        },
        markers: {
          size: 3,
          hover: {
            size: 5,
          },
        },

        grid: {
          row: {
            colors: ["#fff", "#f2f2f2"],
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          labels: {
            rotate: 0,
          },
          categories: [],

          tickPlacement: "on",
        },
        yaxis: {
          title: {
            text: "Reward",
          },
          tickAmount: 10,
        },
        colors: ["#184cf8"],
        fill: {
          type: "fill",
          gradient: {
            opacityFrom: 1,
            opacityTo: 1,
          },
        },
      },
      series: [
        {
          name: "HNT",
          data: [], //this.$cookies.get('series')
        },
      ],
      series2: [
        {
          name: "HNT",
          data: [], //this.$cookies.get('series')
        },
      ],
    };
  },
  watch: {
    date3 (val) {
      this.date1 = this.format_date(this.date3)
    },
    date4 (val) {
      this.date2 = this.format_date(this.date4)
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  beforeMount() {

      //get user from local storage
      this.User = JSON.parse(localStorage.getItem('User'));
      this.uid = this.User.uid;


      this.getuid(),
      this.getOracleValue()
      // this.getHotspotName();
  },
  mounted() {
      this.getAPIStatus();
      this.getDynamicMessages();

     

      //hide page loader
      setTimeout(() => {
        this.loader = false;
        //load chart with intial dates
        this.filtereward(this.haddress, this.installation, this.date1, this.date2, this.commission);
      }, 3000)
  },
  created (){
    //listen to sidebar leased hotspot event
    bus.$on('changeHotspot', (data) => {
      console.log("in changeHotspot dashboard = ",data)
      this.haddress = data.address;
      this.hotspotType = data.hotspot_type;
      this.hostCommission = data.hostCommission;
      this.status = data.status
      // alert('this.hotspotType', this.hotspotType);
      this.getHotspot(data.name, data.hotspot_type, data.address, this.hostCommission);
    })
  },
  methods: {
    getMonthcalc(haddress, installation, end, commission,final_comm) {
      var today = new Date();
      var ends = new Date();
      ends.setDate(today.getDate() - end);
      if (ends >= installation) {
        axios
          .get(
            `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${ends
              .toISOString()
              .slice(0, 10)}&max_time=${today.toISOString().slice(0, 10)}`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            this.month = (((resp.data.data.total -
              (resp.data.data.total / 100) * final_comm) /
              100) *
            commission).toFixed(2);
          })
          .catch((err) => {
            this.month = (0).toFixed(2);
          });
      }
    },
    showTaC() {
      var pdf = `${API_BASE_URL}/gethelp`;
      window.open(pdf);
    },
    async filtereward(haddress, installation, date, date2, commission) {
console.log('address****', haddress);
console.log('commission****', commission);

      if(this.hotspotType == '5G'){console.log('filtereward 5G')
        // alert('its 5G bro');
        this.today = await this.getHMTRewardHotSpotty(haddress, 'today', this.hotspotType);
        if(this.today != 'N/A' && this.today != 'Server error'){
          let commiss = (this.hostCommission / 100) * this.today;
          this.today = this.abbrNum(commiss, 3);
        }

        this.yesterday = await this.getHMTRewardHotSpotty(haddress, 'yesterday', this.hotspotType);
        if(this.yesterday != 'N/A' && this.yesterday != 'Server error'){
          let commiss = (this.hostCommission / 100) * this.yesterday;
          this.yesterday = this.abbrNum(commiss, 3);
        }

        this.week = await this.getHMTRewardHotSpotty(haddress, 'week', this.hotspotType);
        if(this.week != 'N/A' && this.week != 'Server error'){
          let commiss = (this.hostCommission / 100) * this.week;
          this.week = this.abbrNum(commiss, 3);
        }

        this.month = await this.getHMTRewardHotSpotty(haddress, 'month', this.hotspotType);
        if(this.month != 'N/A' && this.month != 'Server error'){
          let commiss = (this.hostCommission / 100) * this.month;
          this.month = this.abbrNum(commiss, 3);
        }

        console.log('this.date1', this.date1);
        console.log('this.date3', this.date3);

        this.getHMTChartHotSpotty(haddress, this.hostCommission, this.hotspotType);

        this.loader = false;
      }
      else if(this.hotspotType == 'IoT'){console.log('filtereward IOT')
        this.today = await this.getHMTRewardHotSpotty(haddress, 'today', this.hotspotType);
        if(this.today != 'N/A' && this.today != 'Server error'){
          let commiss = (this.hostCommission / 100) * this.today;
          this.today = commiss;
        }

        this.yesterday = await this.getHMTRewardHotSpotty(haddress, 'yesterday', this.hotspotType);
        if(this.yesterday != 'N/A' && this.yesterday != 'Server error'){
          let commiss = (this.hostCommission / 100) * this.yesterday;
          this.yesterday = commiss;
        }

        this.week = await this.getHMTRewardHotSpotty(haddress, 'week', this.hotspotType);
        if(this.week != 'N/A' && this.week != 'Server error'){
          let commiss = (this.hostCommission / 100) * this.week;
          this.week = commiss;
        }

        this.month = await this.getHMTRewardHotSpotty(haddress, 'month', this.hotspotType);
        if(this.month != 'N/A' && this.month != 'Server error'){
          let commiss = (this.hostCommission / 100) * this.month;
          this.month = commiss;
        }

        console.log('this.date1', this.date1);
        console.log('this.date3', this.date3);

        this.getHMTChartHotSpotty(haddress, this.hostCommission, this.hotspotType);

        this.loader = false;
      }else if(this.hotspotType == 'HNT') { console.log('filtereward HNT')
        this.today = await this.getHMTRewardHotSpotty(haddress, 'today', this.hotspotType);
        if(this.today != 'N/A' && this.today != 'Server error'){
          let commiss = (this.hostCommission / 100) * this.today;
          this.today = commiss;
        }

        this.yesterday = await this.getHMTRewardHotSpotty(haddress, 'yesterday', this.hotspotType);
        if(this.yesterday != 'N/A' && this.yesterday != 'Server error'){
          let commiss = (this.hostCommission / 100) * this.yesterday;
          this.yesterday = commiss;
        }

        this.week = await this.getHMTRewardHotSpotty(haddress, 'week', this.hotspotType);
        if(this.week != 'N/A' && this.week != 'Server error'){
          let commiss = (this.hostCommission / 100) * this.week;
          this.week = commiss;
        }

        this.month = await this.getHMTRewardHotSpotty(haddress, 'month', this.hotspotType);
        if(this.month != 'N/A' && this.month != 'Server error'){
          let commiss = (this.hostCommission / 100) * this.month;
          this.month = commiss;
        }

        console.log('this.date1', this.date1);
        console.log('this.date3', this.date3);

        this.getHMTChartHotSpotty(haddress, this.hostCommission, this.hotspotType);

        this.loader = false;
      }

    },

    populateChartData(haddress, installation, commission,final_comm) {
      this.series = [
        {
          name: "HNT",
          data: [],
        },
      ];
      this.series2 = [
        {
          name: "HNT",
          data: [],
        },
      ];
      this.chartOptions2.xaxis.categories = [];
      this.chartOptions1.xaxis.categories = [];
      var arr = [];
      var earndate = [];
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=-31%20day&bucket=day`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          var res = resp.data.data;
          //this.haddress = res
          for (let i = 0; i < res.length; i++) {
            //.timestamp.toISOString().slice(0,10)
            earndate.push(this.formatDate(res[i].timestamp.substring(0, 10)));
            var [year, month, day] = res[i].timestamp
              .substring(0, 10)
              .split("-");
            var date = new Date(year, month, day);
            if(date.getMonth() == 0){
              month = 12;
            }
            else{
              month = date.getMonth() - 1;
            }
            date = new Date(year, month, day);
            if (installation <= date) {
              this.notamonth = true;
              arr.push((((res[i].total -
              (res[i].total / 100) * final_comm) /
              100) *
            commission).toFixed(4));
            } else {
              if (this.first30days == 0) {
                this.notamonth = false;
              }

              arr.push(parseInt(0));
            }
          }
          var testdate = earndate.reverse();
          var testearn = arr.reverse();
          this.series = [
            {
              name: "HNT",
              data: testearn,
            },
          ];
          this.series2 = [
            {
              name: "HNT",
              data: testearn,
            },
          ];
          this.chartOptions1 = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: testdate,
                labels: {
                  rotate: 0,
                },
                tickPlacement: "on",
              },
            },
          };
          this.series[0].data.reverse();
          this.series2[0].data.reverse();
        })
        .catch((err) => {
          this.series[0].data.push(0);
        });
    },
    accountActivity(address, start, today, commission) {
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?max_time=${today}&min_time=${start}`,
          {
            headers: { accept: "application/json" },
          }
        )
        .then((resp) => {
          this.sums = (resp.data.data.total / 100) * commission;
        }).catch((err) => {
          console.log(err);
        });
    },
    getHotspotName() {
      var i;
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.hotspotAll = resp.data;

          for (i = 0; i < this.hotspotAll.length; i++) {


                 var today = new Date();
                 var end = this.hotspotAll[i].removeDate? new Date(this.hotspotAll[i].removeDate.slice(0,10)):today;

                 //compare only date
                 today.setHours(0,0,0,0)

                 
                 //check hotspot end date display accordingly
                 if(end >= today){
                    

                    axios
                      .get(
                        `${API_HELIUM_URL}/v1/hotspots/${this.hotspotAll[i].Haddress}`,
                        {
                          headers: {
                            accept: "application/json",
                          },
                        }
                      )
                      .then((resp) => {
                        this.hots.push(resp.data.data.name);

                      });


                 }else{
                    console.log("hotspot end is passed = ",end)
                 }
            
          }
        }).catch((err) => {
          console.log(err);
        });
    },

    getOracleValue() {
      axios
        .get(`${API_HELIUM_URL}/v1/oracle/prices/current`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.oracleprice = resp.data.data.price;
        }).catch((err) => {
          console.log(err);
        });
    },

    getYesterday(haddress, installation, end, commission,final_comm) {
      var today = new Date();
      var ends = new Date();
      ends.setDate(today.getDate() - end);


      if (ends >= installation) {
        axios
          .get(
            `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${ends
              .toISOString()
              .slice(0, 10)}&max_time=${today.toISOString().slice(0, 10)}`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            this.yesterday = (((resp.data.data.total -
              (resp.data.data.total / 100) * final_comm) /
              100) *
            commission).toFixed(2);
          })
          .catch((err) => {
            this.yesterday = 0;
          });
      }
    },

    getWeek(haddress, installation, end, commission,final_comm) {
      var today = new Date();
      var ends = new Date();
      ends.setDate(today.getDate() - end);
      if (ends >= installation) {
        axios
          .get(
            `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${ends
              .toISOString()
              .slice(0, 10)}&max_time=${today.toISOString().slice(0, 10)}`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            this.week = (((resp.data.data.total -
              (resp.data.data.total / 100) * final_comm) /
              100) *
            commission).toFixed(2);
          })
          .catch((err) => {
            this.week = (0).toFixed(2);
          });
      }
    },
    async getHotspot(hname, hotspotType='', address='', hostCommission=20) {
      console.log('hname inside gethotspot', hname);
      console.log('hotspotType inside gethotspot', hotspotType);
      console.log('address inside gethotspot', address);
      console.log('hostCommission inside gethotspot', hostCommission);

      this.loader = true;
      this.hotspotname = this.formatHotspotName(hname);
      hname = this.revertHotspotName(hname);
      
      this.hotspotType = hotspotType;
      if(hotspotType){
        // alert('its 5G bro');
        if(hotspotType == '5G'){console.log('inside 5G')
          this.today = await this.getHMTRewardHotSpotty(address, 'today', this.hotspotType);
          if(this.today != 'N/A' && this.today != 'Server error'){
            let commission = (hostCommission / 100) * this.today;
            this.today = this.abbrNum(commission, 3);
          }

          this.yesterday = await this.getHMTRewardHotSpotty(address, 'yesterday', this.hotspotType);
          if(this.yesterday != 'N/A' && this.yesterday != 'Server error'){
            let commission = (hostCommission / 100) * this.yesterday;
            this.yesterday = this.abbrNum(commission, 3);
          }

          this.week = await this.getHMTRewardHotSpotty(address, 'week', this.hotspotType);
          if(this.week != 'N/A' && this.week != 'Server error'){
            let commission = (hostCommission / 100) * this.week;
            this.week = this.abbrNum(commission, 3);
          }

          this.month = await this.getHMTRewardHotSpotty(address, 'month', this.hotspotType);
          if(this.month != 'N/A' && this.month != 'Server error'){
            let commission = (hostCommission / 100) * this.month;
            this.month = this.abbrNum(commission, 3);
          }

          console.log('this.date1', this.date1);
          console.log('this.date3', this.date3);

          this.getHMTChartHotSpotty(address, hostCommission, this.hotspotType);
        }
        else if(hotspotType == 'IoT'){console.log('inside IOT')
          this.today = await this.getHMTRewardHotSpotty(address, 'today', this.hotspotType);
          if(this.today != 'N/A' && this.today != 'Server error'){
            let commission = (hostCommission / 100) * this.today;
            this.today =commission;
          }

          this.yesterday = await this.getHMTRewardHotSpotty(address, 'yesterday', this.hotspotType);
          if(this.yesterday != 'N/A' && this.yesterday != 'Server error'){
            let commission = (hostCommission / 100) * this.yesterday;
            this.yesterday =commission;
          }

          this.week = await this.getHMTRewardHotSpotty(address, 'week', this.hotspotType);
          if(this.week != 'N/A' && this.week != 'Server error'){
            let commission = (hostCommission / 100) * this.week;
            this.week =commission;
          }

          this.month = await this.getHMTRewardHotSpotty(address, 'month', this.hotspotType);
          if(this.month != 'N/A' && this.month != 'Server error'){
            let commission = (hostCommission / 100) * this.month;
            this.month =commission;
          }

          console.log('this.date1', this.date1);
          console.log('this.date3', this.date3);

          this.getHMTChartHotSpotty(address, hostCommission, this.hotspotType);
        }else if(hotspotType == 'HNT'){console.log('inside HNT')
          this.today = await this.getHMTRewardHotSpotty(address, 'today', this.hotspotType);
          if(this.today != 'N/A' && this.today != 'Server error'){
            let commission = (hostCommission / 100) * this.today;
            this.today = commission;
          }

          this.yesterday = await this.getHMTRewardHotSpotty(address, 'yesterday', this.hotspotType);
          if(this.yesterday != 'N/A' && this.yesterday != 'Server error'){
            let commission = (hostCommission / 100) * this.yesterday;
            this.yesterday = commission;
          }

          this.week = await this.getHMTRewardHotSpotty(address, 'week', this.hotspotType);
          if(this.week != 'N/A' && this.week != 'Server error'){
            let commission = (hostCommission / 100) * this.week;
            this.week = commission;
          }

          this.month = await this.getHMTRewardHotSpotty(address, 'month', this.hotspotType);
          if(this.month != 'N/A' && this.month != 'Server error'){
            let commission = (hostCommission / 100) * this.month;
            this.month = commission;
          }

          console.log('this.date1', this.date1);
          console.log('this.date3', this.date3);

          this.getHMTChartHotSpotty(address, hostCommission, this.hotspotType);
        }


        this.loader = false;
      }
    },
    runCalc(installdatetime, haddress1, final_comm) {
      var today1 = new Date();
      this.today = 0;
      this.yesterday = 0;
      this.week = 0;
      this.month = 0;
      //this.getTotal(this, haddress1, installdatetime, today1);
      this.getToday(haddress1, installdatetime, this.commission, final_comm);
      this.getMonthcalc(
        haddress1,
        installdatetime,
        30,
        this.commission,
        final_comm
      );
      this.getYesterday(
        haddress1,
        installdatetime,
        1,
        this.commission,
        final_comm
      );
      this.getWeek(haddress1, installdatetime, 7, this.commission, final_comm);
      this.populateChartData(
        haddress1,
        installdatetime,
        this.commission,
        final_comm
      );
    },
    checkfirstdays(role, startdate, firstdays) {
      var [year, month, day] = startdate.substring(0, 10).split("-");
      var installation = new Date();
      if (role == 1) {
        if (firstdays == 0) {
          //30 days NO
          installation = new Date(year, month - 1, day);
          installation.setDate(installation.getDate() + 1);
        } else {
          //30 days YES
          installation = new Date(year, month, day);
          installation.setDate(installation.getDate() + 1);
        }
      } else {
        //primary owner
        if (this.first30days == 0) {
          //30 days YES
          installation = new Date(year, month, day);
          installation.setDate(installation.getDate() + 1);
        } else {
          installation = new Date(year, month - 1, day);
          installation.setDate(installation.getDate() + 1);
        }
      }
      this.installation = installation.toISOString().substring(0, 10);
      return installation;
    },
    getbalance(address, dateinst, endinst, commission, type) {
      var final_comm = 0;
      if (type == "refer") {
        axios
          .get(`${API_BASE_URL}/user/hotspot/own/${address}`, {
            headers: {
              accept: "application/json",
            },
          })
          .then((resp) => {
            if (resp.data.length != 0) {
              final_comm = resp.data[0].commission;
            } else {
              final_comm = 20;
            }
            this.getbalanceStep2(
              address,
              dateinst,
              endinst,
              commission,
              final_comm
            );
          });
      } else {
        this.getbalanceStep2(
          address,
          dateinst,
          endinst,
          commission,
          final_comm
        );
      }
    },
    getbalanceStep2(address, dateinst, endinst, commission, final_comm) {
      this.sums = 0;
      if (endinst == "null" || endinst == null) {
        endinst = "";
      }
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${dateinst
            .toISOString()
            .slice(0, 10)}${endinst}`,
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((resp) => {
          this.sums =
            ((resp.data.data.total -
              (resp.data.data.total / 100) * final_comm) /
              100) *
            commission;
        }).catch((err) => {
          console.log(err);
        });
    },
    getToday(haddress, installation, commission,final_comm) {
      var today = new Date();
      if (today >= installation) {
        axios
          .get(
            `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${today
              .toISOString()
              .slice(0, 10)}`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            this.today =(((resp.data.data.total -
              (resp.data.data.total / 100) * final_comm) /
              100) *
            commission).toFixed(2);
          })
          .catch((err) => {
            console.log(err), (this.today = 0);
          });
      }
    },
    getglobal() {
      axios
        .get(`${API_BASE_URL}/user/info/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
              if(resp.data[0].investor == 0){
               if(resp.data[0].signed==1 || resp.data[0].signed==null){this.signneed=false}
              else{


               // this.signneed=true  //we are moving this functionality to shopify 
                }
              (this.commission = resp.data[0].commission),
                (this.first30days = resp.data[0].FirstDays);
            }
           
          } catch (err) {
            this.error = true;
            console.log("empty profile");
          }
        }).catch((err) => {
          console.log(err);
        });
    },
    getuid() {
      axios
        .get(`${API_BASE_URL}/user/hotspots/${this.uid}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then(async (resp) => {

          var today = new Date();
          var end;
          var firstActHotspot;


          if(resp.data.length > 0){
            let hotspottyRes = await this.getHotspottyHotspot(resp.data[0].Haddress)
            if(!_.isEmpty(hotspottyRes)){
              this.status = hotspottyRes.active ? 'online' : 'offline';
              this.hname = this.formatHotspotName(hotspottyRes.name);
            }
            this.haddress = resp.data[0].Haddress;
            this.hotspotType = resp.data[0].hotspotType;
            this.hostCommission = resp.data[0].hostCommission;
            this.getHotspot(this.hname, this.hotspotType, this.haddress, this.hostCommission);
          }

          
      
          
          
        }).catch((err) => {
          console.log(err);
        });
    },
    async getHotspottyHotspot(hotspotAddress){
      return new Promise (function( resolve ) {
        axios.get(`${HOTSPOTTY_BASE_URL}/hotspots/${hotspotAddress}`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
          }
        }).
        then((res) => {
          res = res.data.data;
          resolve(res);
        })
        .catch(() => {
          resolve({});
        })
      })
    },
    getAPIStatus() {
      axios.get(`http://status.helium.com/api/v2/summary.json`, {
          headers: {
              'accept': 'application/json'
          },
      }).then((resp) => {

          for(var x in resp.data.components){
              if (resp.data.components[x].name == "API"){
                  this.heliumstatus = resp.data.components[x].status
              }
          }
          
      }).catch((err) => {
          console.log(err);
      });
    },
    getDynamicMessages() {
      axios
        .get(`${API_BASE_URL}/getMessageConfiguration/dashboard`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            this.dynamicMessage = resp.data;
          } catch (err) {
            this.error = true;
          }
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");

      return `${month}-${day}-${year}`;
    },
    format_date(value){

        if (value) {
          return moment(String(value)).format('MM-DD-YYYY')
        }
    },
    format_date_year(value){
      console.log("in YYYY-MM-DD")
        if (value) {
          return moment(String(value)).format('YYYY-MM-DD')
        }
    },
    formatHotspotName(name){
      let newName = name.replace(/-/g, " ").toLowerCase().replace(/\b[a-z]/g, function(letter) {
          return letter.toUpperCase();
      });

      return newName;
    },
    revertHotspotName(name){
      let newName = name.replace(/ /g, "-").toLowerCase().replace(/\b[a-z]/g, function(letter) {
          return letter;
      });

      return newName;
    },
    updateMessageContent(msg){//replace dynamic hotspot name here
      if(this.hotspotname){
        return msg.replace("{{ hname }}",this.hotspotname);
      }
    },
    async getHMTReward(hotspotAddress, filter){
      console.log('inisde gethntreward', hotspotAddress, filter);
      let minDate= '';
      let maxDate = '';
      if(filter == 'today'){
        minDate= moment().format('YYYY-MM-DD');
        maxDate = moment().format('YYYY-MM-DD');
      }

      if(filter == 'week'){
        minDate= moment().subtract(6, 'days').format('YYYY-MM-DD');
        maxDate = moment().format('YYYY-MM-DD');
      }

      if(filter == 'month'){
        minDate= moment().subtract(29, 'days').format('YYYY-MM-DD');
        maxDate = moment().format('YYYY-MM-DD');
      }

      if(filter == 'total'){
        minDate= moment().subtract(2, 'years').format('YYYY-MM-DD');
        console.log('minDate', minDate);
        maxDate = moment().format('YYYY-MM-DD');
      }

      if(filter == 'yesterday'){
        minDate= moment().subtract(1, 'days').format('YYYY-MM-DD');
        maxDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
      }

      

      return new Promise (function( resolve, reject ) {
        axios.get(`${API_HELIUM_HMT_URL}/cell/hotspots/${hotspotAddress}/rewards?max_date=${maxDate}&min_date=${minDate}`).
        then((res) => {
          res = res.data;

          let total = 0.00;
          for(let i = 0; i < res.length; i++){
            if(res[i].amount > 0){
              total += (res[i].amount / 100000000);
            }
          }

          if(total == 0){
            total = 'N/A';
          }
          
          resolve(total);
        })
        .catch((err) => {
          resolve('Server error');
        })
      })
    },
    async getHMTRewardHotSpotty(hotspotAddress, filter, hotspotType){
      console.log('inisde gethntreward', hotspotAddress, filter);
      let minDate= '';
      let maxDate = '';
      if(filter == 'today'){
        minDate= moment().unix();
        maxDate = moment().unix();
      }

      if(filter == 'week'){
        minDate= moment().subtract(6, 'days').unix();
        maxDate = moment().unix();
      }

      if(filter == 'month'){
        minDate= moment().subtract(29, 'days').unix();
        maxDate = moment().unix();
      }

      if(filter == 'total'){
        minDate= moment().subtract(5, 'years').unix();
        console.log('minDate', minDate);
        maxDate = moment().unix();
      }

      if(filter == 'yesterday'){
        minDate= moment().subtract(1, 'days').unix();
        maxDate = moment().subtract(1, 'days').unix();
      }

      

      return new Promise (function( resolve, reject ) {
        axios.get(`${HOTSPOTTY_BASE_URL}/hotspots/${hotspotAddress}/rewards?start=${minDate}&end=${maxDate}`,{
          headers: {
            Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
          }
        }).
        then((res) => {
          if(hotspotType == '5G'){console.log('getHMTRewardHotSpotty 5G')
            res = res.data.data.rewards.mobile;
          }else if(hotspotType == 'IoT'){console.log('getHMTRewardHotSpotty IOT')
            res = res.data.data.rewards.iot;
          }else{console.log('getHMTRewardHotSpotty HNT')
            res = res.data.data.rewards.hnt;
          }

          let total = 0.00;
          for(let i = 0; i < res.length; i++){
            if(res[i].total > 0){
              total += res[i].total;
            }
          }
          console.log('total***', total);
          if(total == 0){
            total = 'N/A';
          }
          
          resolve(total);
        })
        .catch((err) => {
          resolve('Server error');
        })
      })
    },
    getHMTChartHotSpotty(hotspotAddress, hostCommission = 20, hotspotType){
      let minDate = moment(this.date1).unix();
      let maxDate = moment(this.date2).unix();
      console.log('minDate chat***', minDate);
      console.log('maxDate chat***', maxDate)

      let arr = [];
      let earndate = [];
      axios.get(`${HOTSPOTTY_BASE_URL}/hotspots/${hotspotAddress}/rewards?start=${minDate}&end=${maxDate}`,{
          headers: {
            Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
          }
        }).then((res) => {
          if(hotspotType == '5G'){ console.log('chart 5G')
            res = res.data.data.rewards.mobile;
          }else if(hotspotType == 'IoT'){console.log('chart IOT')
            res = res.data.data.rewards.iot;
          }else{ console.log('chart HNT')
            res = res.data.data.rewards.hnt;
          }

          let total = 0.00;
          for(let i = 0; i < res.length; i++){
            if(res[i].total > 0){

              let commission = (hostCommission / 100) * (res[i].total);
              console.log('hostCommission / 100', hostCommission / 100);
              console.log('res[i].total / 100000000', res[i].total / 100000000)

              total += commission;
              let hmt = '';
              if(hotspotType == '5G'){ 
                hmt = this.abbrNum(commission, 3);
                arr.push(hmt);
              }else {
                arr.push(commission);
              }
            }
            else{
              arr.push('NA');
            }

            earndate.push(moment.unix(res[i].start_date).format('MM-DD-YYYY'));
          }

          
          let name = '';
          if(hotspotType == '5G'){ console.log('chart 2 5G')
            name = 'Mobile';
          }else if(hotspotType == 'IoT'){ console.log('chart 2 IOT')
            name = 'IOT';
          }else{ console.log('chart 2 HNT')
            name = 'HNT'
          }

          this.series =  
          [
            {
              name: name,
              data: arr
            }
          ];

        this.chartOptions1 = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: earndate,
                labels: {
                  rotate: 0,
                },
                tickPlacement: "on",
              },
            },
          };

        if(hotspotType == '5G'){ console.log('chart 3 5G')
          this.hmtSum = this.abbrNum(total, 3);
        }else if(hotspotType == 'IoT'){ console.log('chart 3 IOT')
          this.iotSum = total;
        }else{ console.log('chart 3 HNT')
          this.sums = total;
        }

        })
        .catch((err) => {
          console('Server error');
        })

    },
    getHMTChart(hotspotAddress, hostCommission){
      let minDate = moment(this.date1).format('YYYY-MM-DD');
      let maxDate = moment(this.date2).format('YYYY-MM-DD');
      console.log('minDate***', minDate);
      console.log('maxDate***', maxDate)

      let arr = [];
      let earndate = [];
      axios.get(`${API_HELIUM_HMT_URL}/cell/hotspots/${hotspotAddress}/rewards?max_date=${maxDate}&min_date=${minDate}`).
        then((res) => {
          res = res.data;

          let total = 0.00;
          for(let i = 0; i < res.length; i++){
            if(res[i].amount > 0){

              let commission = (hostCommission / 100) * (res[i].amount / 100000000);


              total += commission;
              let hmt = this.abbrNum(commission, 3);
              console.log('hmt******', hmt);
              arr.push(hmt);
            }
            else{
              arr.push('NA');
            }

            earndate.push(moment(res[i].timestamp).format('MM-DD-YYYY'));
          }

          this.series =  
          [
            {
              name: "HMT",
              data: arr
            }
          ];

        this.chartOptions1 = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: earndate,
                labels: {
                  rotate: 0,
                },
                tickPlacement: "on",
              },
            },
          };

         this.hmtSum = this.abbrNum(total, 3);

        })
        .catch((err) => {
          console('Server error');
        })

    },
    abbrNum(number, decPlaces) {
      // 2 decimal places => 100, 3 => 1000, etc
      decPlaces = Math.pow(10,decPlaces);

      // Enumerate number abbreviations
      var abbrev = [ "K", "M", "B", "T" ];

      // Go through the array backwards, so we do the largest first
      for (var i=abbrev.length-1; i>=0; i--) {

          // Convert array index to "1000", "1000000", etc
          var size = Math.pow(10,(i+1)*3);

          // If the number is bigger or equal do the abbreviation
          if(size <= number) {
              // Here, we multiply by decPlaces, round, and then divide by decPlaces.
              // This gives us nice rounding to a particular decimal place.
              number = Math.round(number*decPlaces/size)/decPlaces;

              // Handle special case where we round up to the next abbreviation
              if((number == 1000) && (i < abbrev.length - 1)) {
                  number = 1;
                  i++;
              }

              // Add the letter for the abbreviation
              number += abbrev[i];

              // We are done... stop
              break;
          }
      }

      return number;
    }
  },
};
</script>