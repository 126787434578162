<template>
  <div class="main-view-sec">
    <Loader v-if="loader"></Loader>

    <div class="contact-page clearfix">
	    <div class="left-contact-page">
	    	<div class="vh-center">
	    		<img src="@/assets/img/helpdesk.png" alt="Image"/>
	    	</div>
	    </div>
	    <div class="right-contact-page">

	    	<v-form ref="form">
		        <div class="vh-center">
		            <h1>Submit No Longer Want a JAG Hotspot Request</h1>
		            <h3>Please follow these instructions.</h3>
                <h3>Did you order your hotspot on Shopify?</h3>
                <h3>If yes, then go to this <v-btn :style="{ 'background-color': '#184cf8', 'font-size': '16px', 'color': 'white' }" target="_blank" href="https://jag.network/a/return" >link here</v-btn> to generate a return label.</h3>
                
                <h3>If no, then go to this <v-btn :style="{ 'background-color': '#184cf8', 'font-size': '16px', 'color': 'white' }" target="_blank" href="https://jag.network/collections/return-products" >link here</v-btn> and select the type of unit you want to return and create an order of it. You will not be charged.</h3>
                
		            <h3>Then go to this <v-btn :style="{ 'background-color': '#184cf8', 'font-size': '16px', 'color': 'white' }" target="_blank" href="https://jag.network/a/return" >link here</v-btn> to generate a return label</h3>

                <h3>If help needed then reach out to the support team.</h3>

		        </div>
	        </v-form>

	    </div>
	    <div class="clearfix"></div>
	</div>
  </div>
</template>

<script>
import axios from "axios";
import VueCookies from "vue-cookies";
import {API_BASE_URL } from '../../../constants';
import Loader from '../../../components/loader';
import "./index.css";

export default {
  components: { Loader },
  setup() {},
  data() {
    return {
        uid: this.$cookies.get("uid"),
        loader:true,
        loading: false,
        // form: {
        //   name:"",
        //   email: "",
        //   hotspotName: "",
        //   currentAddressLineOne: "",
        //   currentCity:"",
        //   currentState:"",
        //   currentZip:"",
        //   currentCountry:"",
        //   reasonOfReturn:"",
        // },
        // error: null,
        // success:null,
        // rules: {
        //     requiredRules: [],
        //     nameRules:[],
        //     emailRules: [],
        //     reasonRules:[],
        //     zipCodeRules:[],
        //     countryRules:[],
        //     stateRules:[],
        //     cityRules:[],
        //     addressLineRules:[]
        // },
        token: {
          accessToken: ''
        },
    };
  },
  beforeMount() {
    

    //hide page loader
    setTimeout(() => {
      this.loader = false;
    }, 1000)
  },
  methods: {
    // send(){
    //   this.error = null;

    //   //enabled validation only on send button and removed from indivisual field
    //   this.rules.requiredRules= [v => !!v || 'This field is required']
    //   this.rules.nameRules=[
    //       v => !!v || 'Name is required',
    //       v => !!/^[a-z]+$/i.test(v) || 'Name must be valid',
    //   ];
    //   this.rules.emailRules= [
    //       v => !!v || 'E-mail is required',
    //       v => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) || 'E-mail must be valid',
    //   ];
    //   this.rules.reasonRules=[
    //       v => !!v || 'Reason for return is required',
    //       v => !!/^.{0,1000}$/.test(v) || 'Reason for return must be less than or equal to 1000 character',   
    //   ];
    //   this.rules.zipCodeRules=[
    //       v => !!v || 'Zip is required',
    //       v => !!/^(\d{5})?$/.test(v) || 'Zip must be valid',
    //   ];
    //   this.rules.countryRules=[
    //       v => !!v || 'Country is required',
    //       v => !!/^[a-z]+$/i.test(v) || 'Country must be valid',
    //   ];
    //   this.rules.stateRules=[
    //       v => !!v || 'State is required',
    //       v => !!/^[a-z]+$/i.test(v) || 'State must be valid',
    //   ];
    //   this.rules.cityRules=[
    //       v => !!v || 'City is required',
    //       v => !!/^[a-z]+$/i.test(v) || 'City must be valid',
    //   ];
    //   this.rules.addressLineRules=[
    //       v => !!v || 'Adddress line one is required',
    //       v => !!/^.{0,1000}$/.test(v) || 'Adddress line one should be less than 1000 character',
    //   ];

    //   //end
      
    //   setTimeout(()=>{
    //      if(this.$refs.form.validate()) {
    //         this.loading = true;
    //         axios.post(`${API_BASE_URL}/nohotspotform/request`,
    //         {
    //           userId:this.uid,
    //           name:this.form.name,
    //           email:this.form.email,
    //           hotspotName:this.form.hotspotName,
    //           currentAddressLineOne:this.form.currentAddressLineOne,
    //           currentCity:this.form.currentCity,
    //           currentState:this.form.currentState,
    //           currentZip:this.form.currentZip,
    //           currentCountry:this.form.currentCountry,
    //           reasonOfReturn:this.form.reasonOfReturn
    //         })

    //         .then((response) => {
    //           this.loading = false;
    //           this.$refs.form.reset();
    //           if(response.data.status === '1') {
    //             this.success = response.data.message;
    //             //auto hide after 5 sec
    //             setTimeout(() => this.success = null, 5000)
    //           }
    //           else {
    //             this.error = response.data.message;
    //             //auto hide after 5 sec
    //             setTimeout(() => this.error = null, 5000)
    //           }
    //         }, 
    //         (error) => {
    //           this.loading = false;
    //           this.error = error.message;
    //           //auto hide after 5 sec
    //           setTimeout(() => this.error = null, 5000)
    //         });
    //       }
    //   })

      
    // }
  },
  
};
</script>
