<template>
  <div class="main-view-sec">
  
    <Loader v-if="loader"></Loader>

    <div class="contact-page clearfix">
	    <div class="left-contact-page">
	    	<div class="vh-center">
	    		<img src="@/assets/img/helpdesk.png" alt="Image"/>
	    	</div>
	    </div>
	    <div class="right-contact-page">
        <v-form ref="form">
  	        <div class="vh-center">

  	            <h1>Submit Update Hotspot Address Request</h1>
  	            <p>{{submitFormInstruction}}</p>

  	            <div class="row">
  	                <div class="col-sm-6">
  	                    <v-text-field v-model="form.name"
                          label="Name*"
                          class="form-control"
                          required
                          :rules="rules.nameRules"
                        >
                        </v-text-field>
  	                </div>
  	                <div class="col-sm-6">
	                      <v-text-field v-model="form.email"
                          label="Email Id*"
                          type="email"
                          class="form-control"
                          required
                          :rules="rules.emailRules"
                        >
                        </v-text-field>
  	                </div>
  	                <div class="col-sm-6">
  	                    <v-text-field v-model="form.hotspotName"
                          label="Hotspot Name*"
                          class="form-control"
                          required
                          :rules="rules.requiredRules"
                        >
                        </v-text-field>
  	                </div>
  	                <div class="col-sm-6">
  	                    <v-text-field v-model="form.phone"
                          label="Phone*"
                          class="form-control"
                          required
                          :rules="rules.phoneRules"
                        >
                        </v-text-field>
  	                </div>
  	                <div class="col-sm-12">
  	                    <v-textarea v-model="form.reasonOfAddressChange"
                          label="Reason for Address Change*"
                          auto-grow
                          rows="1"
                          type="text"
                          row-height="15"
                          autocomplete="off"
                          required
                          :rules="rules.reasonRules"
                          class="form-control"
                        >
                        </v-textarea>
  	                </div>
  	            </div>

                <h1>Current Hotspot Address</h1>
                <div class="row">
                    <div class="col-sm-12">
                        <v-textarea v-model="form.currentAddressLineOne"
                          label="Address Line 1*"
                          auto-grow
                          rows="1"
                          type="text"
                          row-height="15"
                          autocomplete="off"
                          required
                          :rules="rules.addressLineRules"
                          class="form-control"
                        >
                        </v-textarea>
                    </div>
                    <div class="col-sm-6">
                         <v-text-field v-model="form.currentCity"
                            label="City*"
                            type="text"
                            class="form-control"
                            required
                            :rules="rules.cityRules"
                          >
                          </v-text-field>
                    </div>
                    <div class="col-sm-6">
                        <v-text-field v-model="form.currentState"
                          label="State*"
                          type="text"
                          class="form-control"
                          required
                          :rules="rules.stateRules"
                        >
                        </v-text-field>
                    </div>
                    <div class="col-sm-6">
                        <v-text-field v-model="form.currentZip"
                          label="Zip*"
                          type="text"
                          class="form-control"
                          required
                          :rules="rules.zipCodeRules"
                        >
                        </v-text-field>
                    </div>
                    <div class="col-sm-6">
                        <v-text-field v-model="form.currentCountry"
                          label="Country*"
                          type="text"
                          class="form-control"
                          required
                          :rules="rules.countryRules"
                        >
                        </v-text-field>
                    </div>
                </div>

                <h1>New Hotspots Address</h1>
                <div class="row">
                    <div class="col-sm-12">
                        <v-textarea v-model="form.newAddressLineOne"
                          label="Address Line 1*"
                          auto-grow
                          rows="1"
                          type="text"
                          row-height="15"
                          autocomplete="off"
                          required
                          :rules="rules.addressLineRules"
                          class="form-control"
                        >
                        </v-textarea>
                    </div>
                    <div class="col-sm-6">
                        <v-text-field v-model="form.newCity"
                          label="City*"
                          type="text"
                          class="form-control"
                          required
                          :rules="rules.cityRules"
                        >
                        </v-text-field>
                    </div>
                    <div class="col-sm-6">
                        <v-text-field v-model="form.newState"
                          label="State*"
                          type="text"
                          class="form-control"
                          required
                          :rules="rules.stateRules"
                        >
                        </v-text-field>
                    </div>
                    <div class="col-sm-6">
                        <v-text-field v-model="form.newZip"
                          label="Zip*"
                          type="text"
                          class="form-control"
                          required
                          :rules="rules.zipCodeRules"
                        >
                        </v-text-field>
                    </div>
                    <div class="col-sm-6">
                        <v-text-field v-model="form.newCountry"
                          label="Country*"
                          type="text"
                          class="form-control"
                          required
                          :rules="rules.countryRules"
                        >
                        </v-text-field>
                    </div>
                    <div class="col-sm-12">
                        <v-text-field v-model="form.newAddressAntennaHeight"
                          label="New Address Antenna Height or Floor*"
                          type="text"
                          class="form-control"
                          required
                          :rules="rules.requiredRules"
                        >
                        </v-text-field>
                    </div>
                </div>

                <div>
                  <v-alert
                    dense
                    outlined
                    type="error"
                    v-if="error"
                  >
                    {{error}}
                  </v-alert>

                  <v-alert
                    dense
                    text
                    type="success"
                    v-if="success"
                  >
                    {{success}}
                  </v-alert>
                </div>

  	            <div class="text-center">
                  <v-btn class="theme-btn" @click="send">send
                      <v-progress-circular
                        v-if="loading"
                        :size="20"
                        :width="2"
                        color="white"
                        indeterminate
                      ></v-progress-circular>
                  </v-btn>
                </div>

  	        </div>
        </v-form>

	    </div>
	    <div class="clearfix"></div>
	</div>
  </div>
</template>


<script>
import axios from "axios";
import VueCookies from "vue-cookies";
import {API_BASE_URL } from '../../../constants';
import Loader from '../../../components/loader';
import "./index.css";

var dynamicMessage = {
  submit_form_instruction: "",
  reason: "",
  address_line: "",
  city_required: "",
  state_required: "",
  zip_required: "",
  country_required: "",
  field_required: "",
  email_valid: "",
  phone_valid: "",
  city_valid: "",
  state_valid: "",
  country_valid: "",
  thanks_for_contacting: "",
  something_went_wrong: "",
};
export default {
  components: { Loader },
  setup() {},
  data() {
    return {
        uid: this.$cookies.get("uid"),
        loader:true,
        loading: false,
        form: {
          name:"",
          email: "",
          hotspotName: "",
          phone:"",
          reasonOfAddressChange: "",
          currentAddressLineOne: "",
          currentCity:"",
          currentState:"",
          currentZip:"",
          currentCountry:"",
          newAddressLineOne: "",
          newCity:"",
          newState:"",
          newZip:"",
          newCountry:"",
          newAddressAntennaHeight: ""
        },
        submitFormInstruction:"",
        error: null,
        success:null,
        rules: {
            requiredRules: [],
            nameRules:[],
            emailRules: [],
            phoneRules: [],
            reasonRules:[],
            zipCodeRules:[],
            countryRules:[],
            stateRules:[],
            cityRules:[],
            addressLineRules:[],
        },
        token: {
          accessToken: ''
        },
    };
  },

  beforeMount() {
    

    //hide page loader
    setTimeout(() => {
      this.loader = false;
    }, 1000);

  },
    mounted() {
    this.getDynamicMessages();
    
  },
  methods: {
    send(){
        this.error = null;

        //enabled validation only on send button and removed from indivisual field
          this.rules.requiredRules= [v => !!v || dynamicMessage.field_required];
          this.rules.nameRules=[
              v => !!v || 'Name is required',
              v => !!/^[a-z\s]+$/i.test(v) || 'Name must be valid',
          ];
          this.rules.emailRules= [
              v => !!v || 'E-mail is required',
              v => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) || 'E-mail must be valid',
          ];
          this.rules.phoneRules= [
              v => !!v || 'Phone is required',
              v => !!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/im.test(v) || dynamicMessage.phone_valid,
          ];
          this.rules.reasonRules=[
              v => !!v || dynamicMessage.reason,
              v => !!/^.{0,1000}$/.test(v) || 'Reason for address change must be less than or equal to 1000 character',
          ];
          this.rules.zipCodeRules=[
              v => !!v || dynamicMessage.zip_required,
              v => !!/^(\d{5})?$/.test(v) || 'Zip must be valid',
          ];
          this.rules.countryRules=[
              v => !!v || dynamicMessage.country_required,
              v => !!/^[a-z\s]+$/i.test(v) || dynamicMessage.country_valid,
          ];
          this.rules.stateRules=[
              v => !!v || dynamicMessage.state_required,
              v => !!/^[a-z\s]+$/i.test(v) || dynamicMessage.state_valid,
          ];
          this.rules.cityRules=[
              v => !!v || dynamicMessage.city_required,
              v => !!/^[a-z\s]+$/i.test(v) || dynamicMessage.city_valid,
          ];
          this.rules.addressLineRules=[
              v => !!v || dynamicMessage.address_line,
              v => !!/^.{0,1000}$/.test(v) || 'Adddress line one should be less than 1000 character',
          ];

        //end


        setTimeout(()=>{
          if (this.$refs.form.validate()) {


            this.loading = true;
            axios.post(`${API_BASE_URL}/update/hotspot/address/request`,
            {
              userId:this.uid,
              name:this.form.name,
              email:this.form.email,
              phone:this.form.phone,
              hotspotName:this.form.hotspotName,
              reasonOfAddressChange:this.form.reasonOfAddressChange,
              currentAddressLineOne:this.form.currentAddressLineOne,
              currentCity:this.form.currentCity,
              currentState:this.form.currentState,
              currentZip:this.form.currentZip,
              currentCountry:this.form.currentCountry,
              newAddressLineOne:this.form.newAddressLineOne,
              newCity:this.form.newCity,
              newState:this.form.newState,
              newZip:this.form.newZip,
              newCountry:this.form.newCountry,
              newAddressAntennaHeight:this.form.newAddressAntennaHeight,
            })
            .then((response) => {
              this.loading = false;
              this.$refs.form.reset();
              if(response.data.status === '1') {
                this.success = response.data.message;
                //auto hide after 5 sec
                setTimeout(() => this.success = null, 5000)
              }
              else {
                this.error = response.data.message;
                //auto hide after 5 sec
                setTimeout(() => this.error = null, 5000)
              }
            }, 
            (error) => {
              this.loading = false;
              if(error.message === "Network Error") {
                this.error = "Unable to send email, please check your internet connectivity.";
              }
              else{
                this.error = error.message;
              }
              //auto hide after 5 sec
              setTimeout(() => this.error = null, 5000)
            });


          }
       })
    },
     getDynamicMessages() {
      axios
        .get(`${API_BASE_URL}/getMessageConfiguration/address_change`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            dynamicMessage = resp.data;
            this.submitFormInstruction = resp.data.submit_form_instruction
          } catch (err) {
            this.error = true;
          }
        });
    },
  },
  
};
</script>