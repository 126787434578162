<template>

  <div class="main-view-sec">

    <Loader v-if="$root.isLoading"></Loader>

    <div class="page-headings">

      <h1 class="clearfix"> <span><a href="javascript:void(0)" @click="redirect('dashboard')">Home</a> <a href="javascript:void(0)">Hotspot</a></span></h1>

    </div>

    <div class="dash-chart-outer">
      <div class="row" v-if="heliumstatus != 'operational' || hotspotstatus == 'offline' || notamonth != 1 || error == true">
        <div class="col-sm-12">
          <div class="card-style m-b-30">
            <div class="box-head clearfix">Alerts</div>
            <div class="mCustomScrollbar">
                <v-col v-if="error" cols="12">
                  <v-alert type="error">
                    {{ dynamicMessage.leased_hotspot_inprocess }}
                  </v-alert>
                </v-col>

                <v-col v-if="notamonth != 1" cols="12">
                  <v-alert type="warning">
                    {{ updateMessageContent(dynamicMessage.will_start_earning) }}
                  </v-alert>
                </v-col>

                <v-col v-if="heliumstatus != 'operational'" cols="12">
                  <v-alert type="warning">
                    {{ dynamicMessage.experiencing_diffculties }}
                  </v-alert>
                </v-col> 
                
                <v-col v-if="hotspotstatus == 'offline'" cols="12" v-on:click="showTaC">
                  <v-alert type="error" class="cursor">
                    {{ dynamicMessage.hotspot_offline }}
                  </v-alert>
                </v-col>
            
            </div>
          </div>
        </div>
      </div>
      
      <v-dialog
          v-model="signneed"
          persistent
          max-width="450"
          >
        <v-card>
          <v-card-title class="text-h5">
            New contract awaiting signature.
          </v-card-title>
          <v-card-text>A new hotspot has been assigned to you.  Please sign the contract to see the hotspot details.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="purple darken-1"
              text
              to="/sign"
            >
              Go to sign
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="row">
        <div class="col-sm-8">
          <div class="maphotspot">
            <div class="box-head clearfix">Map Location</div>
            <v-card class="pa-2" style="height: 100%" min-height="450px;">
          <iframe
            :src="mapurl"
            width="100%"
            height="450px"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            style="border: 1px solid black"
          ></iframe>
        </v-card>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card-style-sotspots m-b-30 "
          v-bind:class="{ 'red-rg-bor offline-card': hotspotstatus == 'offline','red-green-bor online-card': hotspotstatus == 'online' }"
          >
            <h2>Hotspot Name </h2>
            <p>{{ hotspotname }} <b>({{ hotspotstatus }})</b></p>
          </div>
          <div class="card-style-sotspots m-b-30 blue-rg-bor">
            <h2>Address </h2>
            <p>  {{ hotspotadd }} </p>
          </div>
          <div class="card-style-sotspots m-b-30 blue-rg-bor">
            <h2>Lat Long </h2>
            <p>  {{ latlng }} </p>
          </div>
          <div class="card-style-sotspots m-b-30 green-rg-bor">
            <h2>Installation Date </h2>
            <p> {{ formatDate(installdatetime) }}</p>
          </div>
          <div class="card-style-sotspots m-b-30 green-rg-bor">
            <h2>Earning Eligibility </h2>
            <p> {{ formatDate(installation) }}</p>
          </div>
          <div class="card-style-sotspots m-b-30 orange-rg-bor">
            <h2 style="color: #184cf8;"> {{ totalearn }} {{ hotSpotType }}</h2>
            <p>Total Reward for the selected hotspot </p>
          </div>
        </div>
      </div>
       <v-card-title>
        Hotspot linked:
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="hotspotAll"
        class="elevation-1"
        :loading="false"
        :search="search"
        item-key="index"
        :item-class="itemRowBackground"
      >
        <v-progress-linear
          v-show="progressBar"
          slot="progress"
          color="purple darken-4"
          indeterminate
        ></v-progress-linear>
        <template v-slot:[`item.name`]="{ item }" >
         <td>{{ item.name }}</td>
        </template>
        <template v-slot:[`item.hotspotType`]="{ item }" >
         <td><span class="type-hot-tger iot-tgr">{{ item.hotspotType == '5G' ? 'Mobile' : item.hotspotType }}</span></td>
        </template>
        <template v-slot:[`item.status`]="{ item }" >
         <td><span :class="[item.status]" class="status-cr st"></span> {{item.status}}</td>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <a href="javascript:void(0)" @click="getHotspot(item)" class="table-btn-cust table-send-btn"><i class="fas fa-paper-plane"></i> SHOW</a>
        </template>
        <template v-slot:[`item.role`]="{ item }">
          <v-chip
            class="ma-2"
            color="purple darken-1"
            dark
            v-if="item.role === '0'"
          >
            Primary Owner
          </v-chip>
          <v-chip
            class="ma-2"
            dark
            color="purple darken-4"
            v-else-if="item.role === '1'"
          >
            Referral Owner
          </v-chip>
          <v-chip
            class="ma-2"
            dark
            color="green accent-2"
            v-else-if="item.role === '2'"
          >
            Watchers
          </v-chip>
          <v-chip
            class="ma-2"
            dark
            color="green accent-2"
            v-else-if="item.role === '3'"
          >
            Fixed Rent Referral
          </v-chip>
          <v-chip class="ma-2" dark color="danger" v-else>Secondary Owner</v-chip>
        </template>
        <template v-slot:[`item.signed`]="{ item }">
          <v-icon v-if="item.signed == 1 && item.role != '2'"
            >mdi-account-check</v-icon
          >
          <v-icon v-if="item.signed == 0 && item.role != '2'"
            >mdi-alert-circle</v-icon
          >
        </template>
        <template v-slot:[`item.timestamp_added`]="{ item }">
          <span v-if="item.installDate == null">
            {{formatDate(item.timestamp_added.slice(0, 10))}}
          </span>
          <span v-else>{{ item.installDate.slice(0, 10) }}</span>
        </template>
        <template v-slot:[`item.geocode.short_state`]="{ item }">
          <span v-if="item.removeDate!=null && item.role == 0">{{" "}}</span>
          <span v-else>
            {{ item.geocode.short_state }}
            {{ " "}}
            {{ item.geocode.short_street }}
            {{ " "}}
            {{ item.geocode.short_country }}
          </span>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <span v-if="item.removeDate != null && item.role == 0">
            {{ "N/A" }}
          </span>
          <span v-else>{{ item.email }}</span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import moment, { max } from "moment";
import { API_BASE_URL, API_HELIUM_URL, API_JAG_HELIUM_URL, API_HELIUM_HMT_URL, HOTSPOTTY_BASE_URL, HOTSPOTTY_TOKEN  } from '../../constants';
import { bus } from '../../main';
import Loader from '../../components/loader';
import './index.css';
import _ from "lodash";

export default {
  components: { Loader },
  data() {
    return {
      hotSpotType: 'HNT',
      loader:true,
      dialog: false,
      hotspotDetails: [],
      hotspotTotal: [],
      url: "",
      progressBar: true,
      mapurl: "",
      error: false,
      start: "",
      today1: "",
      totalearn: 0,
      Hdetails: false,
      notamonth: 1,
      hotspotstatus: "",
      heliumstatus:"operational",
      installdatetime: "",
      hotspotname: "",
      hotspotadd: "",
      latlng: "",
      hotspotAll: [],
      message: "",
      uid: this.$cookies.get("uid"),
      hname: "",
      commission: 20,
      haddress: "",
      installation: "",
      first30days: 0,
      today: 0,
      signneed: false,
      sums: 0,
      yesterday: 0,
      search: "",
      week: 0,
      arr: [],
      isInvestor: 0,
        dynamicMessage: {
        no_data: "",
        no_matching_record: "",
        leased_hotspot_inprocess: "",
        will_start_earning: "",
        experiencing_diffculties: "",
        hotspot_offline: "ff",
      },
    };
  },
  watch: {
    hotspotAll() {
      //this.progressBar = false;
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    headers() {
      return [
        { text: "Hotspot Name", value: "name" },
        { text: "Type", value: "hotspotType" },
        { text: "Status", value: "status" },
        { text: "Relationship", value: "role" },
        { text: "Email", value: "email" },
        { text: "Location", value: "location" },
        { text: "Lat Lng", value: "latlng" },
        { text: "Date of Installation", value: "timestamp_added" },
        { text: "Today", value: "today" },
        { text: "Past 7 Days", value: "week" },
        { text: "Past 30 Days", value: "month" },
        { text: "All Time Earnings", value: "total" },
        { text: "Action", value: "action", sortable: false, align: "center" },
      ];
    },
  },
  beforeMount() {
    this.getuid();
  },
  mounted() {
    this.getAPIStatus();
    this.getDynamicMessages();
    this.$root.isLoading = true;
    // setTimeout(() => {
    //   this.$root.isLoading = false;
    // }, 3000)
  },
  created (){
    //listen to sidebar leased hotspot event
    bus.$on('changeHotspot', (data) => {
      console.log("in changeHotspot dashboard = ",data)
      this.getHotspot(data);
    })
  },
  methods: {
    redirect() { //custom redirect method
      if(this.isInvestor == 1){
        this.$router.push('investor');
     }else{
        this.$router.push('dashboard');
     }
    },
    formatHotspotType(type) {
      if(type === "N/A") {
        return "4G";
      } else {
        return type;
      }
    },
    getHotspot(hname) {
      console.log('In get hotspot');
      console.log(hname);
      if(hname.hotspotType == '5G'){
        this.hotSpotType = 'Mobile';
      }else if(hname.hotspotType == "IoT"){
        this.hotSpotType = 'IOT';
      }else{
        this.hotSpotType = 'HNT';
      }
      this.$root.isLoading = true;
      
      //hide page loader
      setTimeout(() => {
        this.$root.isLoading = false;
      }, 1500);

      this.Hdetails = true;
      this.haddress = hname.address;
      this.hotspotadd = hname.address;
      this.latlng = hname.latlng;
      //this.hotspotstatus = hname.status.online;
      if(typeof(hname.status) === "object") {
        this.hotspotstatus = hname.status.online;
      }
      else {
        this.hotspotstatus = hname.status;
      }
      
      this.hotspotname = hname.name;

      this.notamonth = hname.notmonth;
      
      
      if (hname.total != undefined) {
        this.totalearn = hname.total;
      } else {
        this.totalearn = 0;
      }

      
      // if (hname.installDate != null) {
      //   this.installdatetime = hname.installDate.substring(0, 10);
      // } else {
      //   this.installdatetime = hname.timestamp_added.substring(0, 10);
      // }
      this.installdatetime = hname.timestamp_added
      this.installation = hname.elegibility;
      this.mapurl = [
        "https://www.openstreetmap.org/export/embed.html?bbox=" +
          hname.lng +
          "%2C" +
          hname.lat +
          "%2C" +
          hname.lng +
          "%2C" +
          hname.lat +
          "&layer=mapnik&marker=" +
          hname.lat +
          "%2C" +
          hname.lng,
      ].join("");
    },
    showTaC() {
      var pdf = `${API_BASE_URL}/gethelp`;
      window.open(pdf);
    },
    getUserHotspots() {
      axios.get(`${API_BASE_URL}/user/hotspots/${this.uid}`, {
        headers: {
          accept: "application/json",
        },
      })
      .then(async (resp) => {
        let hotspots = resp.data;
        console.log('hotspots**', hotspots);
        let newArr = [];

        let hotspotsIds = [];
        for(let i = 0; i < hotspots.length; i ++){
          let exists = hotspotsIds.findIndex((x) => x == hotspots[i].Haddress);
          if(exists < 0){
            hotspotsIds = [...hotspotsIds, hotspots[i].Haddress];
          }
        }
        let hotspottyHotspots = await this.getHotspottyHotspots(hotspotsIds);

        for(let i = 0; i < hotspots.length; i++){
          let hotspottyResIndex = hotspottyHotspots.findIndex((x) => x.id == hotspots[i].Haddress);
          
          if(hotspottyResIndex > -1){
            let hotspottyRes = hotspottyHotspots[hotspottyResIndex];
            let hotspotObj =  {
                  index: i,
                  name: this.formatHotspotName(hotspottyRes.name),
                  status: hotspottyRes.active ? 'online' : 'offline',
                  address: hotspots[i].Haddress,
                  owner: hotspottyRes.owner,
                  role: hotspots[i].role,
                  timestamp_added: 'N/A',
                  hotspotType: hotspots[i].hotspotType,
                  email: this.userEmail,
                  location: hotspottyRes.place,
                  lat: (hotspottyRes.lat) ? hotspottyRes.lat : 45.45706794277646,
                  lng: (hotspottyRes.lng) ? hotspottyRes.lng : 9.152475848644855,                    
                  timestamp_added: '',
                  removeDate: (hotspots[i].removeDate) ? hotspots[i].removeDate : null,
                  installDate: (hotspots[i].installDate) ? hotspots[i].installDate : null,
                  elegibility: 'N/A',
                  today:  'N/A',
                  week: 'N/A',
                  month:  'N/A',
                  total: 'N/A',
                  hostCommission: hotspots[i].hostCommission,
                  latlng: `${(hotspottyRes.lat ? hotspottyRes.lat : 45.45706794277646)} ${(hotspottyRes.lng ? hotspottyRes.lng : 9.152475848644855)}`
                };
            let flag = 0;
            if(localStorage.getItem('searched_hotspot') !== null) {
              flag = 1;
              let searched_hotspot_haddress = localStorage.getItem('searched_hotspot');
              if(hotspotObj.address === searched_hotspot_haddress) {
                this.getHotspot(hotspotObj);
                flag = 2;
                localStorage.removeItem('searched_hotspot');
              }
            }
            if(i == 0 && flag == 0) {
                this.getHotspot(hotspotObj);
            }
            
            newArr = [...newArr, hotspotObj]
          } 
        }

        this.$root.isLoading = true;

        this.hotspotAll = newArr;
        console.log('this.hotspots****', this.hotspotAll);
//         hotspots = [
//     {
//         "hotspotName": "cheery-misty-wolverine",
//         "hotspotAddress": "1121PBKKmDRyDx5gcrG7HXajEC4XfUSaA4jNxB9TxGaytUJrKL5M",
//         "hotspotLng": -94.58579558966908,
//         "hotspotLat": 39.05693728978543,
//         "hotspotStatus": "offline",
//         "short_street": "Main St",
//         "short_state": "MO",
//         "short_country": "US",
//         "short_city": "KCMO",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "IoT",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-28T16:44:06.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0,
//         "hotspotAllTimeMining": 5.128998030000001,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0,
//         "hotspotAllTimeEarning": 1.025799606
//     },
//     {
//         "hotspotName": "cheery-misty-wolverine",
//         "hotspotAddress": "1121PBKKmDRyDx5gcrG7HXajEC4XfUSaA4jNxB9TxGaytUJrKL5M",
//         "hotspotLng": -94.58579558966908,
//         "hotspotLat": 39.05693728978543,
//         "hotspotStatus": "offline",
//         "short_street": "Main St",
//         "short_state": "MO",
//         "short_country": "US",
//         "short_city": "KCMO",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "5G",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-28T16:44:06.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0,
//         "hotspotAllTimeMining": 5.128998030000001,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0,
//         "hotspotAllTimeEarning": 1.025799606
//     },
//     {
//         "hotspotName": "polished-cinnabar-porcupine",
//         "hotspotAddress": "11sXuN9py7BDB8hVhR4CqYSMHZwCwyAzBu6fXFpf1G4mru4HfbH",
//         "hotspotLng": -94.57830697606472,
//         "hotspotLat": 39.06867598916537,
//         "hotspotStatus": "offline",
//         "short_street": "Gillham Rd",
//         "short_state": "MO",
//         "short_country": "US",
//         "short_city": "KCMO",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "IoT",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-28T16:59:21.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0,
//         "hotspotAllTimeMining": 4.48797027,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0,
//         "hotspotAllTimeEarning": 0.8975940539999999
//     },
//     {
//         "hotspotName": "polished-cinnabar-porcupine",
//         "hotspotAddress": "11sXuN9py7BDB8hVhR4CqYSMHZwCwyAzBu6fXFpf1G4mru4HfbH",
//         "hotspotLng": -94.57830697606472,
//         "hotspotLat": 39.06867598916537,
//         "hotspotStatus": "offline",
//         "short_street": "Gillham Rd",
//         "short_state": "MO",
//         "short_country": "US",
//         "short_city": "KCMO",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "5G",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-28T16:59:21.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0,
//         "hotspotAllTimeMining": 4.48797027,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0,
//         "hotspotAllTimeEarning": 0.8975940539999999
//     },
//     {
//         "hotspotName": "joyous-purple-toad",
//         "hotspotAddress": "1127RdPa8KQrdUAiBABD3nN1A9aHrL4u8j3oJe455C8mQtnrmjzP",
//         "hotspotLng": -94.64496813746008,
//         "hotspotLat": 39.02303480024172,
//         "hotspotStatus": "online",
//         "short_street": "Johnson Dr",
//         "short_state": "KS",
//         "short_country": "US",
//         "short_city": "Mission",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "IoT",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-26T14:16:58.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0.0981861,
//         "hotspotAllTimeMining": 6.497385140000001,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0.019637219999999997,
//         "hotspotAllTimeEarning": 1.2994770280000003
//     },
//     {
//         "hotspotName": "joyous-purple-toad",
//         "hotspotAddress": "1127RdPa8KQrdUAiBABD3nN1A9aHrL4u8j3oJe455C8mQtnrmjzP",
//         "hotspotLng": -94.64496813746008,
//         "hotspotLat": 39.02303480024172,
//         "hotspotStatus": "online",
//         "short_street": "Johnson Dr",
//         "short_state": "KS",
//         "short_country": "US",
//         "short_city": "Mission",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "5G",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-26T14:16:58.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0.0981861,
//         "hotspotAllTimeMining": 6.497385140000001,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0.019637219999999997,
//         "hotspotAllTimeEarning": 1.2994770280000003
//     },
//     {
//         "hotspotName": "beautiful-crimson-swallow",
//         "hotspotAddress": "112Nas1E156cGTpvLjLMTo8Kj6q1QmRvLSNLz2s1xui7rfot2MSR",
//         "hotspotLng": -94.57706460049644,
//         "hotspotLat": 39.1109025102792,
//         "hotspotStatus": "online",
//         "short_street": "E 3rd St",
//         "short_state": "MO",
//         "short_country": "US",
//         "short_city": "KCMO",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "IoT",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-27T14:41:35.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0.113915,
//         "hotspotAllTimeMining": 5.36552029,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0.022783,
//         "hotspotAllTimeEarning": 1.0731040580000002
//     },
//     {
//         "hotspotName": "beautiful-crimson-swallow",
//         "hotspotAddress": "112Nas1E156cGTpvLjLMTo8Kj6q1QmRvLSNLz2s1xui7rfot2MSR",
//         "hotspotLng": -94.57706460049644,
//         "hotspotLat": 39.1109025102792,
//         "hotspotStatus": "online",
//         "short_street": "E 3rd St",
//         "short_state": "MO",
//         "short_country": "US",
//         "short_city": "KCMO",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "5G",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-27T14:41:35.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0.113915,
//         "hotspotAllTimeMining": 5.36552029,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0.022783,
//         "hotspotAllTimeEarning": 1.0731040580000002
//     },
//     {
//         "hotspotName": "shambolic-tawny-antelope",
//         "hotspotAddress": "1126dWgm6CF2AKyBEWjxPhSMYTiGiQZoG3rsE98rkMRQzhaUaYqf",
//         "hotspotLng": -94.37852393545248,
//         "hotspotLat": 38.90640245077759,
//         "hotspotStatus": "online",
//         "short_street": "SW Jefferson St",
//         "short_state": "MO",
//         "short_country": "US",
//         "short_city": "Lee's Summit",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "IoT",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-23T13:42:05.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0.273613,
//         "hotspotAllTimeMining": 18.599504400000004,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0.054722599999999996,
//         "hotspotAllTimeEarning": 3.719900880000001
//     },
//     {
//         "hotspotName": "shambolic-tawny-antelope",
//         "hotspotAddress": "1126dWgm6CF2AKyBEWjxPhSMYTiGiQZoG3rsE98rkMRQzhaUaYqf",
//         "hotspotLng": -94.37852393545248,
//         "hotspotLat": 38.90640245077759,
//         "hotspotStatus": "online",
//         "short_street": "SW Jefferson St",
//         "short_state": "MO",
//         "short_country": "US",
//         "short_city": "Lee's Summit",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "5G",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-23T13:42:05.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0.273613,
//         "hotspotAllTimeMining": 18.599504400000004,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0.054722599999999996,
//         "hotspotAllTimeEarning": 3.719900880000001
//     },
//     {
//         "hotspotName": "active-lavender-gazelle",
//         "hotspotAddress": "11tPapbNJ6ng2NigS3nVJuRNrME8mcdfvpGRGJnfDGBg9vi2pbm",
//         "hotspotLng": -94.67597015510133,
//         "hotspotLat": 38.93105301818663,
//         "hotspotStatus": "online",
//         "short_street": "Lowell Ave",
//         "short_state": "KS",
//         "short_country": "US",
//         "short_city": "Overland Park",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "IoT",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-23T13:46:33.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0.156881,
//         "hotspotAllTimeMining": 9.0084671,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0.0313762,
//         "hotspotAllTimeEarning": 1.80169342
//     },
//     {
//         "hotspotName": "active-lavender-gazelle",
//         "hotspotAddress": "11tPapbNJ6ng2NigS3nVJuRNrME8mcdfvpGRGJnfDGBg9vi2pbm",
//         "hotspotLng": -94.67597015510133,
//         "hotspotLat": 38.93105301818663,
//         "hotspotStatus": "online",
//         "short_street": "Lowell Ave",
//         "short_state": "KS",
//         "short_country": "US",
//         "short_city": "Overland Park",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "5G",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-23T13:46:33.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0.156881,
//         "hotspotAllTimeMining": 9.0084671,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0.0313762,
//         "hotspotAllTimeEarning": 1.80169342
//     }
// ];
        if(this.hotspotAll.length > 0) {
          // hotspots.forEach((element, index) => {
          //   let hotspotObj = {};
          //   let date_of_installation = new Date(element.dateOfInstallation);
          //   date_of_installation.setDate(date_of_installation.getDate() + 1);
          //   date_of_installation.setMonth(date_of_installation.getMonth() + 1);
          //   let eligiblity_date = date_of_installation;
          //   let eligibility_date_to_iso_string = date_of_installation.toISOString();
          //   if(eligiblity_date > today_date) {
          //     hotspotObj.notmonth = 0;
          //   } else {
          //     hotspotObj.notmonth = 1;
          //   }
          //   hotspotObj.index = index;
          //   hotspotObj.name = this.formatHotspotName(element.hotspotName);
          //   hotspotObj.status = element.hotspotStatus;
          //   hotspotObj.address = (element.hotspotAddress) ? element.hotspotAddress : 'N/A';
          //   hotspotObj.role = element.hotspotOwner;
          //   hotspotObj.hotspotType = element.hotspotType;
          //   hotspotObj.email = this.userEmail;
          //   hotspotObj.location = ((element.short_state) ? element.short_state : '')+'-'+
          //                         ((element.short_street) ? element.short_street : '')+'-'+
          //                         ((element.short_country) ? element.short_country : '');
          //   hotspotObj.lat = (element.hotspotLat) ? element.hotspotLat : 45.45706794277646;
          //   hotspotObj.lng = (element.hotspotLng) ? element.hotspotLng : 9.152475848644855;                    
          //   hotspotObj.timestamp_added = element.dateOfInstallation.slice(0, 10);
          //   hotspotObj.removeDate = (element.removeDate) ? element.removeDate : null;
          //   hotspotObj.elegibility = (eligibility_date_to_iso_string) ? eligibility_date_to_iso_string.slice(0, 10) : 'N/A';
          //   hotspotObj.today = (element.hotspotTodayEarning) ? (element.hotspotTodayEarning).toFixed(2) : 'N/A';
          //   hotspotObj.week = (element.hotspotPastSevenDaysEarning) ? (element.hotspotPastSevenDaysEarning).toFixed(2) : 'N/A';
          //   hotspotObj.month = (element.hotspotPastThirtyDaysEarning) ? (element.hotspotPastThirtyDaysEarning).toFixed(2) : 'N/A';
          //   hotspotObj.total = (element.hotspotAllTimeEarning) ? (element.hotspotAllTimeEarning).toFixed(2) : 'N/A';
          //   hotspotObj.hostCommission = element.hostCommission;
          //   let flag = 0;
          //   if(localStorage.getItem('searched_hotspot') !== null) {
          //     flag = 1;
          //     let searched_hotspot_haddress = localStorage.getItem('searched_hotspot');
          //     if(hotspotObj.address === searched_hotspot_haddress) {
          //       this.getHotspot(hotspotObj);
          //       flag = 2;
          //       localStorage.removeItem('searched_hotspot');
          //     }
          //   }
          //   if(index == 0 && flag == 0) {
          //       this.getHotspot(hotspotObj);
          //   }
          //   this.hotspotAll.push(hotspotObj);
          // });
          this.getHMTEarningsHotSpotty();
        } else {
          this.$root.isLoading = false;
        }
      })
      .catch((error) => {
        this.progressBar = false;
        this.$root.isLoading = false;
        console.log('error1', error);
      });
    },
    async getHotspottyHotspots(hotspots){
      return new Promise (function( resolve ) {
        axios.post(`${HOTSPOTTY_BASE_URL}/hotspots`,{
          hotspot_ids: hotspots
        }, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
          }
        }).
        then((res) => {
          res = res.data.data;
          resolve(res);
        })
        .catch(() => {
          resolve({});
        })
      })
    },
    async getHotspottyHotspot(hotspotAddress){
      return new Promise (function( resolve ) {
        axios.get(`${HOTSPOTTY_BASE_URL}/hotspots/${hotspotAddress}`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
          }
        }).
        then((res) => {
          res = res.data.data;
          resolve(res);
        })
        .catch(() => {
          resolve({});
        })
      })
    },
    async getHMTEarningsHotSpotty(){
      for(let i = 0; i < this.hotspotAll.length; i++){console.log('this.hotspotAll[i].hotspotType', this.hotspotAll[i].hotspotType);
      console.log('this.hotspotAll[i]', this.hotspotAll[i]);
        if(this.hotspotAll[i].hotspotType == '5G'){

          let res1 = await this.getHMTRewardHotSpotty(this.hotspotAll[i].address, 'today');
          this.hotspotAll[i].today = res1.mobileTotal;
          if(this.hotspotAll[i].today != 'N/A' && this.hotspotAll[i].today != 'Server error'){
            let hostCommission = ((this.hotspotAll[i].hostCommission != 0 ? this.hotspotAll[i].hostCommission : 20) / 100) * this.hotspotAll[i].today;
            this.hotspotAll[i].today = this.abbrNum(hostCommission, 3);
          }
          let res2 = await this.getHMTRewardHotSpotty(this.hotspotAll[i].address, 'week');
          this.hotspotAll[i].week = res2.mobileTotal;
          if(this.hotspotAll[i].week != 'N/A' && this.hotspotAll[i].week != 'Server error'){
            let hostCommission = ((this.hotspotAll[i].hostCommission != 0 ? this.hotspotAll[i].hostCommission : 20) / 100) * this.hotspotAll[i].week;
            this.hotspotAll[i].week = this.abbrNum(hostCommission, 3);
          }
          let res3 = await this.getHMTRewardHotSpotty(this.hotspotAll[i].address, 'month');
          this.hotspotAll[i].month = res3.mobileTotal;
          if(this.hotspotAll[i].month != 'N/A' && this.hotspotAll[i].month != 'Server error'){
            let hostCommission = ((this.hotspotAll[i].hostCommission != 0 ? this.hotspotAll[i].hostCommission : 20) / 100) * this.hotspotAll[i].month;
            this.hotspotAll[i].month = this.abbrNum(hostCommission, 3);
          }
          let res4 = await this.getHMTRewardHotSpotty(this.hotspotAll[i].address, 'total', this.hotspotAll[i].installDate);
          this.hotspotAll[i].total = res4.mobileTotal; 
          if(this.hotspotAll[i].total != 'N/A' && this.hotspotAll[i].total != 'Server error'){
            let hostCommission = ((this.hotspotAll[i].hostCommission != 0 ? this.hotspotAll[i].hostCommission : 20) / 100) * this.hotspotAll[i].total;
            this.hotspotAll[i].total = this.abbrNum(hostCommission, 3);
          }

        }else if(this.hotspotAll[i].hotspotType == "IoT"){
          let res1 = await this.getHMTRewardHotSpotty(this.hotspotAll[i].address, 'today');
          this.hotspotAll[i].today = res1.iotTotal;
          if(this.hotspotAll[i].today != 'N/A' && this.hotspotAll[i].today != 'Server error'){
            let hostCommission = ((this.hotspotAll[i].hostCommission != 0 ? this.hotspotAll[i].hostCommission : 20) / 100) * this.hotspotAll[i].today;
            this.hotspotAll[i].today = hostCommission.toFixed(2);
          }
          let res2 = await this.getHMTRewardHotSpotty(this.hotspotAll[i].address, 'week');
          this.hotspotAll[i].week = res2.iotTotal;
          if(this.hotspotAll[i].week != 'N/A' && this.hotspotAll[i].week != 'Server error'){
            let hostCommission = ((this.hotspotAll[i].hostCommission != 0 ? this.hotspotAll[i].hostCommission : 20) / 100) * this.hotspotAll[i].week;
            this.hotspotAll[i].week = hostCommission.toFixed(2);;
          }
          let res3 = await this.getHMTRewardHotSpotty(this.hotspotAll[i].address, 'month');
          this.hotspotAll[i].month = res3.iotTotal;
          if(this.hotspotAll[i].month != 'N/A' && this.hotspotAll[i].month != 'Server error'){
            let hostCommission = ((this.hotspotAll[i].hostCommission != 0 ? this.hotspotAll[i].hostCommission : 20) / 100) * this.hotspotAll[i].month;
            this.hotspotAll[i].month = hostCommission.toFixed(2);;
          }
          let res4 = await this.getHMTRewardHotSpotty(this.hotspotAll[i].address, 'total', this.hotspotAll[i].installDate);
          this.hotspotAll[i].total = res4.iotTotal; 
          if(this.hotspotAll[i].total != 'N/A' && this.hotspotAll[i].total != 'Server error'){
            let hostCommission = ((this.hotspotAll[i].hostCommission != 0 ? this.hotspotAll[i].hostCommission : 20) / 100) * this.hotspotAll[i].total;
            this.hotspotAll[i].total = hostCommission.toFixed(2);;
          }
        }else if(this.hotspotAll[i].hotspotType == "HNT"){
          let res1 = await this.getHMTRewardHotSpotty(this.hotspotAll[i].address, 'today');
          this.hotspotAll[i].today = res1.hntTotal;
          if(this.hotspotAll[i].today != 'N/A' && this.hotspotAll[i].today != 'Server error'){
            let hostCommission = ((this.hotspotAll[i].hostCommission != 0 ? this.hotspotAll[i].hostCommission : 20) / 100) * this.hotspotAll[i].today;
            this.hotspotAll[i].today = hostCommission.toFixed(2);
          }
          let res2 = await this.getHMTRewardHotSpotty(this.hotspotAll[i].address, 'week');
          this.hotspotAll[i].week = res2.hntTotal;
          if(this.hotspotAll[i].week != 'N/A' && this.hotspotAll[i].week != 'Server error'){
            let hostCommission = ((this.hotspotAll[i].hostCommission != 0 ? this.hotspotAll[i].hostCommission : 20) / 100) * this.hotspotAll[i].week;
            this.hotspotAll[i].week = hostCommission.toFixed(2);;
          }
          let res3 = await this.getHMTRewardHotSpotty(this.hotspotAll[i].address, 'month');
          this.hotspotAll[i].month = res3.hntTotal;
          if(this.hotspotAll[i].month != 'N/A' && this.hotspotAll[i].month != 'Server error'){
            let hostCommission = ((this.hotspotAll[i].hostCommission != 0 ? this.hotspotAll[i].hostCommission : 20) / 100) * this.hotspotAll[i].month;
            this.hotspotAll[i].month = hostCommission.toFixed(2);;
          }
          let res4 = await this.getHMTRewardHotSpotty(this.hotspotAll[i].address, 'total', this.hotspotAll[i].installDate);
          this.hotspotAll[i].total = res4.hntTotal; 
          console.log('this.hotspotAll[i].total**', this.hotspotAll[i].total);
          if(this.hotspotAll[i].total != 'N/A' && this.hotspotAll[i].total != 'Server error'){
            let hostCommission = ((this.hotspotAll[i].hostCommission != 0 ? this.hotspotAll[i].hostCommission : 20) / 100) * this.hotspotAll[i].total;
            this.hotspotAll[i].total = hostCommission.toFixed(2);;
          }
        }
      }
      this.$root.isLoading = false;
    },
    async getHMTEarnings(){
      for(let i = 0; i < this.hotspotAll.length; i++){console.log('this.hotspotAll[i].hotspotType', this.hotspotAll[i].hotspotType);
        if(this.hotspotAll[i].hotspotType == '5G'){
          this.hotspotAll[i].today = await this.getHMTReward(this.hotspotAll[i].address, 'today');
          if(this.hotspotAll[i].today != 'N/A' && this.hotspotAll[i].today != 'Server error'){
            let hostCommission = (this.hotspotAll[i].hostCommission / 100) * this.hotspotAll[i].today;
            this.hotspotAll[i].today = this.abbrNum(hostCommission, 3);
          }
          this.hotspotAll[i].week = await this.getHMTReward(this.hotspotAll[i].address, 'week');
          if(this.hotspotAll[i].week != 'N/A' && this.hotspotAll[i].week != 'Server error'){
            let hostCommission = (this.hotspotAll[i].hostCommission / 100) * this.hotspotAll[i].week;
            this.hotspotAll[i].week = this.abbrNum(hostCommission, 3);
          }
          this.hotspotAll[i].month = await this.getHMTReward(this.hotspotAll[i].address, 'month');
          if(this.hotspotAll[i].month != 'N/A' && this.hotspotAll[i].month != 'Server error'){
            let hostCommission = (this.hotspotAll[i].hostCommission / 100) * this.hotspotAll[i].month;
            this.hotspotAll[i].month = this.abbrNum(hostCommission, 3);
          }
          this.hotspotAll[i].total = await this.getHMTReward(this.hotspotAll[i].address, 'total');
          if(this.hotspotAll[i].total != 'N/A' && this.hotspotAll[i].total != 'Server error'){
            let hostCommission = (this.hotspotAll[i].hostCommission / 100) * this.hotspotAll[i].total;
            this.hotspotAll[i].total = this.abbrNum(hostCommission, 3);
          }
        }
      }
    },
    async getHMTRewardHotSpotty(hotspotAddress, filter, installDate = ''){
      console.log('inisde gethntreward', hotspotAddress, filter);
      let minDate= '';
      let maxDate = '';
      if(filter == 'today'){
        minDate= moment().unix();
        maxDate = moment().unix();
      }

      if(filter == 'week'){
        minDate= moment().subtract(6, 'days').unix();
        maxDate = moment().unix();
      }

      if(filter == 'month'){
        minDate= moment().subtract(29, 'days').unix();
        maxDate = moment().unix();
      }

      if(filter == 'total'){
        minDate= moment().subtract(5, 'years').unix();
        if(installDate){
          minDate = moment(installDate).unix()
        }
        console.log('minDate', minDate);
        maxDate = moment().unix();
      }


      console.log('filter', filter);
      console.log('minDate', minDate);
      console.log('maxDate', maxDate);
      
      

      return new Promise (function( resolve ) {
        axios.get(`${HOTSPOTTY_BASE_URL}/hotspots/${hotspotAddress}/rewards?start=${minDate}&end=${maxDate}`, {
          headers: {
            Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
          }
        }).
        then((res) => {
          // console.log('res', res.data.data.rewards);

          let mobile = res.data.data.rewards.mobile;
          let mobileTotal = 0.00;
          for(let i = 0; i < mobile.length; i++){
            if(mobile[i].total > 0){
              mobileTotal += mobile[i].total;
            }
          }

          let iot = res.data.data.rewards.iot;
          let iotTotal = 0.00;
          for(let i = 0; i < iot.length; i++){
            if(iot[i].total > 0){
              iotTotal += iot[i].total;
            }
          }

          let hnt = res.data.data.rewards.hnt;
          let hntTotal = 0.00;
          for(let i = 0; i < hnt.length; i++){
            if(hnt[i].total > 0){
              hntTotal += hnt[i].total;
            }
          }

          resolve({
            mobileTotal: mobileTotal != 0.00 ? mobileTotal : 'N/A',
            iotTotal : iotTotal != 0.00 ? iotTotal : 'N/A',
            hntTotal: hntTotal != 0.00 ? hntTotal : 'N/A'
          });
        })
        .catch(() => {
          resolve({
            mobileTotal: 'Server error',
            iotTotal : 'Server error',
            hntTotal: 'Server error'
          });
        })
      })
    },
    async getHMTReward(hotspotAddress, filter){
      console.log('inisde gethntreward', hotspotAddress, filter);
      let minDate= '';
      let maxDate = '';
      if(filter == 'today'){
        minDate= moment().format('YYYY-MM-DD');
        maxDate = moment().format('YYYY-MM-DD');
      }

      if(filter == 'week'){
        minDate= moment().subtract(6, 'days').format('YYYY-MM-DD');
        maxDate = moment().format('YYYY-MM-DD');
      }

      if(filter == 'month'){
        minDate= moment().subtract(29, 'days').format('YYYY-MM-DD');
        maxDate = moment().format('YYYY-MM-DD');
      }

      if(filter == 'total'){
        minDate= moment().subtract(2, 'years').format('YYYY-MM-DD');
        console.log('minDate', minDate);
        maxDate = moment().format('YYYY-MM-DD');
      }

      

      return new Promise (function( resolve ) {
        axios.get(`${API_HELIUM_HMT_URL}/cell/hotspots/${hotspotAddress}/rewards?max_date=${maxDate}&min_date=${minDate}`).
        then((res) => {
          res = res.data;

          let total = 0.00;
          for(let i = 0; i < res.length; i++){
            if(res[i].amount > 0){
              total += (res[i].amount / 100000000);
            }
          }

          if(total == 0){
            total = 'N/A';
          }
          
          resolve(total);
        })
        .catch(() => {
          resolve('Server error');
        })
      })
    },
    getuid() {
      this.getglobal();
      this.getUserHotspots();
      //this.$root.isLoading = true;
      // axios.get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
      //   headers: {
      //     accept: "application/json",
      //   },
      // })
      // .then((resp) => {
      //   try {
      //     console.log('inisde getuid', resp.data);
      //     //if no hotspot assigned then open the sign and force him to sign the contract
      //     if(resp.data.length == 0 && this.isInvestor == 0){
      //       // this.signneed = true; //we are moving this functionality to shopify
      //     }

      //     this.$cookies.set("hotspotStatus", false);
      //     this.parsingName(resp.data);
      //   } 
      //   catch (err) {
      //     this.error = true;
      //     this.$cookies.set("hotspotStatus", true);
      //     console.log("empty profile");
      //   }
      //   //this.$root.isLoading = false;
      // });
    },
    parsingName(arr3) {
      var arr1 = [];
      var arr2 = [];
      var filteredHotspot = [];
      let promises = [];
      arr3.forEach((element) => {
        if(element.Haddress != 'empty'){

           var today = new Date();
           var end = element.removeDate? new Date(element.removeDate.slice(0,10)):today;

           //compare only date
           today.setHours(0,0,0,0)

           
           //check hotspot end date display accordingly
           if(end >= today){
              promises.push(
                axios.get(`${API_HELIUM_URL}/v1/hotspots/${element.Haddress}`, {
                  headers: { accept: "application/json" },
                })
              );

              //filter hotspot remove date wise
              filteredHotspot.push(element);
           }


        }
      });
      Promise.all(promises).then((results) => {
        results.forEach((resp) => {
          arr2.push(resp.data.data);
          arr1.push(resp.data.data.name);
        });
        this.remainpayment(arr1, arr2, filteredHotspot);
      });
    },
    itemRowBackground(item) {
      return item.status.online == "offline" ? "style-1" : "style-2";
    },
     remainpayment(arr1, arr2, arr3) {
      this.ownedhotspot = arr1; //name hotspot
      this.detailhotspot = arr2; //helium data
      for(let i = 0; i < arr3.length; i++){
        if(arr3[i].Haddress != 'empty'){
          this.hotspotAll = [...this.hotspotAll, arr3[i]];
        }
      }
     
      var i;
      var conc = {};
      let details = this.detailhotspot;
      this.remainbalance = 0;
      for (i = 0; i < details.length; i++) {
        for (var attrname in this.hotspotAll[i]) {
          conc[attrname] = this.hotspotAll[i][attrname];
        }
        conc["elegibility"] = null;
        conc["today"] = (0).toFixed(2);
        conc["week"] = (0).toFixed(2);
        conc["month"] = (0).toFixed(2);
        conc["total"] = (0).toFixed(2);
        conc["notmonth"] = 0;
        conc["email"] = null;
        for (var attrname1 in details[i]) {
          conc[attrname1] = details[i][attrname1];
        }

        this.hotspotAll[i] = conc;
        //format hotsopt name for search
        this.hotspotAll[i]['name'] = this.formatHotspotName(this.hotspotAll[i]['name']);

        conc = {};
      }

      this.hotspotAll.forEach((element) => {
        //jag value + helium value
        var endinst = null;
        var [year, month, day] = element.timestamp_added
          .substring(0, 10)
          .split("-");
        var installdatetime = "";
        var installation = new Date(year, month - 1, day);
        if (element.installDate != null) {
          installdatetime = element.installDate.slice(0, 10);
        } else {
          installdatetime = installation.toISOString().slice(0, 10);
        }
        if (element.removeDate != null) {
          endinst = "&max_time=" + element.removeDate.slice(0, 10);
        }
        this.installdatetime = this.checkfirstdays(
          element.role,
          installdatetime,
          element.firstdays
        );
        if (element.role == 1) {
          //if refer owner
          if (element.firstdays == 0) {
            this.getBalance(
              element.address,
              this.installdatetime,
              endinst,
              element.commission,
              element.firstdays,
              "refer"
            );
          } else {
            this.getBalance(
              element.address,
              this.installdatetime,
              endinst,
              element.commission,
              element.firstdays,
              "refer"
            );
          }
        }
        if (element.role == 0) {
          //if primary owner
          if (this.first30days == 1) {
            this.getBalance(
              element.address,
              this.installdatetime,
              endinst,
              this.commission,
              this.first30days,
              "primary"
            );
          } else {
            this.getBalance(
              element.address,
              this.installdatetime,
              endinst,
              this.commission,
              this.first30days,
              "primary"
            );
          }
        }
      });

      console.log('this.hotspotAll', this.hotspotAll);
      console.log('arr3', arr3)

      for(let i = 0; i < arr3.length; i++){
        
        if(arr3[i].Haddress == 'empty'){
          let data = {
            name: arr3[i].Haddress,
            status: {
              online: ''
            },
            role: arr3[i].role,
            email: this.userEmail,
            geocode: {
              short_state: ''
            },
            timestamp_added: '',
            today: '',
            week: '',
            month: '',
            total: ''
          }

          this.hotspotAll = [...this.hotspotAll, data];
        }
      }

      console.log('new hotspotall', this.hotspotAll);
    },
    checkfirstdays(role, startdate, firstdays) {
      var [year, month, day] = startdate.substring(0, 10).split("-");
      var installation = new Date();
      if (role == 1) {
        if (firstdays == 0) {
          //30 days NO
          installation = new Date(year, month - 1, day);
          installation.setDate(installation.getDate() + 1);
        } else {
          //30 days YES
          installation = new Date(year, month, day);
          installation.setDate(installation.getDate() + 1);
        }
      } else {
        //primary owner
        if (this.first30days == 0) {
          //30 days YES
          installation = new Date(year, month, day);
          installation.setDate(installation.getDate() + 1);
        } else {
          installation = new Date(year, month - 1, day);
          installation.setDate(installation.getDate() + 1);
        }
      }
      this.installation = installation.toISOString().substring(0, 10);
      return installation.toISOString().substring(0, 10);
    },
    getglobal() {
      axios
        .get(`${API_BASE_URL}/user/info/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            this.isInvestor = resp.data[0].investor;
            this.userEmail = resp.data[0].email;
            if(resp.data[0].investor == 0){
               if(resp.data[0].signed==1 || resp.data[0].signed==null){this.signneed=false}
                else{
                  //this.signneed=true //we are moving this functionality to shopify 
                  }
                  (this.commission = resp.data[0].commission),
                  (this.first30days = resp.data[0].FirstDays);
            }
          } catch (err) {
            this.error = true;
          }
        });
    },

    getDayscalc(i, name, haddress, installation, end, commission,final_comm) {
      var [year, month, day] = installation.substring(0, 10).split("-");
      installation = new Date(year, month - 1, day);
      var today = new Date();
      var ends = new Date();
      ends.setDate(today.getDate() - end);
      if (ends >= installation) {
        this.$root.isLoading = true;
        axios
          .get(
            `${API_HELIUM_URL}/v1/hotspots/${haddress}/rewards/sum?min_time=${ends
              .toISOString()
              .slice(0, 10)}&max_time=${today.toISOString().slice(0, 10)}`,
            {
              headers: {
                accept: "application/json",
              },
            }
          )
          .then((resp) => {
            this.hotspotAll[i][name] = (((resp.data.data.total-(resp.data.data.total / 100) * final_comm))/ 100 * commission).toFixed(2);
            this.$root.isLoading = false;
          })
          .catch(() => {
            this.$root.isLoading = false;
            return 0;
          });
      }
    },
    getBalance(address, dateinst, endinst, commission,firstday, type) {
      var final_comm = 0;
      if (type == "refer") {
        this.$root.isLoading = true;
        axios
          .get(`${API_BASE_URL}/user/hotspot/own/${address}`, {
            headers: {
              accept: "application/json",
            },
          })
          .then((resp) => {
            if (resp.data.length != 0) {
              final_comm = resp.data[0].commission;
            } else {
              final_comm = 20;
            }
            this.getBalanceStep2(
              address,
              dateinst,
              endinst,
              commission,
              final_comm
            );
            this.$root.isLoading = false;
          });
      } else {
        this.getBalanceStep2(
          address,
          dateinst,
          endinst,
          commission,
          final_comm
        );
      }
    },
    getBalanceStep2(address, dateinst, endinst, commission, final_comm) {
      var totalearn = 0;
      if (endinst == "null" || endinst == null) {
        endinst = "";
      }
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${dateinst.slice(
            0,
            10
          )}${endinst}`,
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((resp) => {
          totalearn = ((resp.data.data.total-(resp.data.data.total / 100) * final_comm))/ 100 * commission;
          for (var i = 0; i < this.hotspotAll.length; i++) {
            if (this.hotspotAll[i]["address"] == address) {
              this.hotspotAll[i]["total"] = totalearn.toFixed(2);
              this.getOwner(address, i);
              this.getDayscalc(i, "today", address, dateinst, 1, commission,final_comm);
              this.getDayscalc(i, "month", address, dateinst, 30, commission,final_comm);
              this.getDayscalc(i, "week", address, dateinst, 7, commission,final_comm);
              this.hotspotAll[i]["elegibility"] = dateinst;
              this.hotspotAll[i]["notmonth"] = 1;
              this.getHotspot(this.hotspotAll[0]);
            }
          }
        })
        .catch(() => {
          for (var i = 0; i < this.hotspotAll.length; i++) {
            if (this.hotspotAll[i]["address"] == address) {
              this.hotspotAll[i]["total"] = (0).toFixed(2);
              this.getOwner(address, i);
              this.hotspotAll[i]["elegibility"] = dateinst;
              this.hotspotAll[i]["today"] = (0).toFixed(2);
              this.hotspotAll[i]["week"] = (0).toFixed(2);
              this.hotspotAll[i]["month"] = (0).toFixed(2);
              this.hotspotAll[i]["notmonth"] = 0;
              this.getHotspot(this.hotspotAll[0]);
            }
          }
        });
    },
    getOwner(address, i) {
      axios
        .get(`${API_BASE_URL}/user/hotspot/own/${address}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
            this.hotspotAll[i]["email"] = resp.data[0]?resp.data[0].email:"";
        });
    },
    getAPIStatus() {
      axios.get(`http://status.helium.com/api/v2/summary.json`, {
          headers: {
              'accept': 'application/json'
          },
      }).then((resp) => {
          for(var x in resp.data.components){
              console.log(x)
              if (resp.data.components[x].name == "API"){
                  this.heliumstatus = resp.data.components[x].status
              }
          }
          
      })
    },
    getDynamicMessages() {
      axios
        .get(`${API_BASE_URL}/getMessageConfiguration/hotspot`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            this.dynamicMessage = resp.data;
          } catch (err) {
            this.error = true;
          }
        });
    },
    updateMessageContent(msg){//replace dynamic hotspot name here
      if(this.hotspotname){
        return msg.replace("{{ hotspot name }}",this.hotspotname);
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");

      return `${month}/${day}/${year}`;
    },
    formatHotspotName(name){
      let newName = name.replace(/-/g, " ").toLowerCase().replace(/\b[a-z]/g, function(letter) {
          return letter.toUpperCase();
      });

      return newName;
    },
    resetHotspot() {
      let activeHotspot = JSON.parse(localStorage.getItem('activeHotspot'));
                
      console.log("activeHotspot = ",activeHotspot)
      if(activeHotspot){
        this.getHotspot(activeHotspot);
      }
    },
    abbrNum(number, decPlaces) {
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10,decPlaces);

    // Enumerate number abbreviations
    var abbrev = [ "K", "M", "B", "T" ];

    // Go through the array backwards, so we do the largest first
    for (var i=abbrev.length-1; i>=0; i--) {

        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10,(i+1)*3);

        // If the number is bigger or equal do the abbreviation
        if(size <= number) {
             // Here, we multiply by decPlaces, round, and then divide by decPlaces.
             // This gives us nice rounding to a particular decimal place.
             number = Math.round(number*decPlaces/size)/decPlaces;

             // Handle special case where we round up to the next abbreviation
             if((number == 1000) && (i < abbrev.length - 1)) {
                 number = 1;
                 i++;
             }

             // Add the letter for the abbreviation
             number += abbrev[i];

             // We are done... stop
             break;
        }
    }

    return number;
  }
  }
};
</script>