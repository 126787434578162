export const API_BASE_URL = 'https://api.jag.network';
// export const API_BASE_URL = 'http://127.0.0.1:3000';
export const API_HELIUM_URL = 'https://sharkfed.api.stakejoy.com';
export const API_JAG_HELIUM_URL = 'https://api.helium.jag.network';
export const API_HELIUM_HMT_URL = 'https://explorer-api.helium.com/api';
//export const API_JAG_HELIUM_URL = 'http://127.0.0.1:3008';
export const HOTSPOTTY_BASE_URL = 'https://beta-api.hotspotty.net/api/v1';
export const HOTSPOTTY_TOKEN = 'AqmgYnbQ1YsK9Lj91GTA3wmv3C36GnLs';
export const FIRE_BASE = {
    apiKey: "AIzaSyDhBin0Dq89xUhLmFWhuI7mxXrl6RxaF9o",
    authDomain: "jagindustrials1.firebaseapp.com",
    databaseURL: "https://jagindustrials1.firebaseio.com",
    projectId: "jagindustrials1",
    storageBucket: "",
    messagingSenderId: "",
    appId: ""
}