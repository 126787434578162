<template>
  <section class="login-page">
    <div class="left-log">
      <div class="vh-cent">
        <div class="log-logo">
          <a target="New" href="https://jag.network"
            ><img src="@/assets/img/logo.png" alt="SITE-LOGO"
          /></a>
        </div> 
        <h2>Change your password.</h2>
        
        <h3 class="forgot-dev-pass">
          Want go back?
          <v-btn class="ml-2 white--text" outlined :to="{ name: 'dashboard' }"
            >Home</v-btn
          >
        </h3>
        
        <v-form ref="form">

          <div class="form-group">
            <label>Current Password</label>
            <v-text-field
              v-model="currentPassword"
              placeholder="Current Password*"
              type="password"
              class="form-control"
              :rules="requiredRules"
            >
            </v-text-field>
          </div>

          <div class="form-group">
            <label>New Password</label>
            <v-text-field
              v-model="newPassword"
              placeholder="New Password*"
              type="password"
              class="form-control"
              :rules="newPasswordRules"
            >
            </v-text-field>
          </div>

          <div class="form-group">
            <label>Confirm New Password</label>
            <v-text-field
              v-model="confirmNewPassword"
              placeholder="Confirm New Password*"
              type="password"
              class="form-control"
              :rules="confirmPasswordRules"
            >
            </v-text-field>

          </div>

          <button class="log-btn btn" type="button" @click="send()">
            Send Request
            <v-progress-circular
              v-if="loading"
              :size="20"
              :width="2"
              color="white"
              indeterminate
            ></v-progress-circular>
          </button>

          <v-alert
            dense
            outlined
            color="error"
            class="my-5"
            dismissible
            v-if="error"
          >
            {{ error }}
          </v-alert>

          <v-alert
            dense
            outlined
            color="success"
            class="my-5"
            dismissible
            v-if="success"
          >
            {{ success }}
          </v-alert>

        </v-form>

      </div>
    </div>
    <div class="right-log">
      <h1>Jag Host Portal</h1>
      <h1>
        <span
          >Login to see your tracked earnings, check hotspot status and request
          payouts.</span
        >
      </h1>
      <h3>Best Network Portal</h3>
    </div>
  </section>
</template>

<script>

import firebase from "firebase";
import axios from "axios";
import { API_BASE_URL } from "../../constants";




var dynamicMessage = {
  password: "",
};
export default {
  name: "App",
  data: function() {
   return {
      loading:false,
      error: null,
      success: null,
      currentPassword:"",
      newPassword:"",
      confirmNewPassword:"",
      requiredRules: [(v) => !!v || "This field is required"],
      newPasswordRules: [
        v => !!v || 'This field is required',
        v => v !== this.currentPassword || 'It seems you have entered same password as Old Password!!!.',
      ],
      confirmPasswordRules: [
        v => !!v || 'This field is required',
        v => v === this.newPassword || 'The password and confirm password do not match..',
      ]
   };
    
  },
  beforeMount() {

    //get user from local storage
    this.User = JSON.parse(localStorage.getItem('User'));

    console.log("this.User = ",this.User.email)

  },
  mounted() {
    this.getDynamicMessages();
  },
  methods: {
    send() {

      this.error = null;
      this.success = null;

      if (this.$refs.form.validate()) {
        
        this.loading = true;

        firebase
        .auth()
        .signInWithEmailAndPassword(this.User.email, this.currentPassword)
        .then((user) => {

            console.log("current password is correct.");

            firebase.auth().currentUser.updatePassword(this.newPassword).then(()=>{
              
              this.loading = false;
              this.success = "Password updated successfully.";
              console.log("password updated successfully...");

              this.$refs.form.reset();

              //auto hide after 5 sec
              setTimeout(()=>{
                this.success = null;
                //redirect to dashboard page
                this.$router.push('dashboard');
              },5000)

            }).catch(function(err){
               this.error = err.message;
               this.loading = false;
            });          

        })
        .catch(error => {
            this.error = error.message;
            this.loading = false;
        });
        
      }
    },
    getDynamicMessages() {
      axios
        .get(`${API_BASE_URL}/getMessageConfiguration/forgot`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            dynamicMessage = resp.data;
          } catch (err) {
            this.error = true;
          }
        });
    },
  },
};
</script>