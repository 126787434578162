<template>
  <div>
    <div class="left-header">
      
      <div class="logo">
       <a href="javascript:void(0)" @click="redirect('dashboard')">
          <img src="../../../assets/img/logo-white.png" alt="site-logo"/> 
          <span>Customer</span>
        </a> 
      </div>
   
      <div class="slide-nav-1 mCustomScrollbar">
        <ul class="clearfix forgot-dev-pass">
          
          <li class="open-lefter"  >
            <a href="javascript:void(0)">
              <i class="fas fa-arrow-right" data-toggle="tooltip" data-placement="bottom" title="Expand"></i> <span>Close Menu</span>
            </a>
          </li> 

          <li v-bind:class="{ 'activee': currRouteName =='dashboard' }">
            <a href="javascript:void(0)" @click="redirect('dashboard')">
              <i class="fas fa-chart-bar" data-toggle="tooltip" data-placement="bottom" title="Dashboard"></i> 
             <span>Dashboard</span>
            </a> 
          </li>
          
          <li v-if="isInvestor == 1"  v-bind:class="{ 'activee': currRouteName=='investor' }">
            <a href="javascript:void(0)" @click="redirect('investor')">
              <i class="fas fa-user-tie" data-toggle="tooltip" data-placement="bottom" title="Investor"></i> 
              <span>Investor</span>
            </a>
          </li>
          
          <li v-bind:class="{ 'activee': currRouteName =='hotspot' }">
            <a href="javascript:void(0)" @click="redirect('hotspot')"><i class="fas fa-wifi" data-toggle="tooltip" data-placement="bottom" title="Hotspot"></i> 
             <span>Hotspot</span>
            </a>
          </li>
         

          <li v-bind:class="{ 'activee': currRouteName =='profile' }">
          <a href="javascript:void(0)" @click="redirect('profile')"><i class="fas fa-user" data-toggle="tooltip" data-placement="bottom" title="Profile"></i> <span>Profile</span></a>
          </li>
         
          <li v-bind:class="{ 'activee': currRouteName =='rewards' }" ><a href="javascript:void(0)" @click="redirect('rewards')"><i class="fas fa-star" data-toggle="tooltip" data-placement="bottom" title="Rewards"></i> <span>Rewards</span></a></li>
        
          <li v-bind:class="{ 'activee': currRouteName =='reports' }" >
          <a href="javascript:void(0)" @click="redirect('reports')">
          <i class="fas fa-flag-checkered" data-toggle="tooltip" data-placement="bottom" title="Reports"></i> <span> Reports</span></a>
          </li>
        
          <li v-bind:class="currRouteName == 'hotspotRequest'||currRouteName == 'updateHotspotRequest'||currRouteName == 'upgradeHotspotRequest'||currRouteName == 'noHotspotRequest'? 'activee' : ''">
             <a href="javascript:void(0)" class="drop-icon-after"><i class="fas fa-headphones-alt" data-toggle="tooltip" data-placement="top" title="Support"></i> <span>Support</span></a>

            <ul class="sub-menu">
              
              <li v-bind:class="{ 'activee': currRouteName =='hotspotRequest' }"><a href="javascript:void(0)" @click="redirect('hotspotRequest')"><i class="far fa-question-circle" data-toggle="tooltip" data-placement="top" title="Submit Host Support Request"></i> <span>Submit Host Support Request</span></a></li>
              
              <li v-bind:class="{ 'activee': currRouteName =='updateHotspotRequest' }"><a href="javascript:void(0)" @click="redirect('updateHotspotRequest')"><i class="fas fa-address-card" data-toggle="tooltip" data-placement="top" title="Update Hotspot Address Form"></i> <span>Update Hotspot Address Form</span></a></li>
              
              <li v-bind:class="{ 'activee': currRouteName =='upgradeHotspotRequest' }"><a href="javascript:void(0)" @click="redirect('upgradeHotspotRequest')"><i class="fas fa-broadcast-tower" data-toggle="tooltip" data-placement="top" title="Upgraded Antenna Request Form"></i> <span>Upgraded Antenna Request Form</span></a></li>

              <li v-bind:class="{ 'activee': currRouteName =='noHotspotRequest' }"><a href="javascript:void(0)" @click="redirect('noHotspotRequest')"><i class="fas fa-ban" data-toggle="tooltip" data-placement="top" title="No Longer Want a JAG Hotspot"></i> <span>No Longer Want a JAG Hotspot</span></a></li>
            
            </ul>
          </li>

        </ul>
      </div>
   
    </div>

    <div class="right-link-panel mCustomScrollbar">
      <h4><i class="fas fa-wifi"></i> Leased Hotspots</h4>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Hotspot Name</th>
              <th>Type</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="hots.length == 0">
              <td>Fetching Hotspots...</td>
            </tr>
            <tr v-for="item in hots" :key="item.index" @click="changeHotspot(item)" :class="{ active : activeHotspot == item.name }">
              <td>{{formatHotspotName(item.name)}}</td>
              <td><span class="type-hot-tger iot-tgr">{{item.hotspot_type == '5G' ? 'Mobile' : item.hotspot_type}}</span></td>
              <td><span :class="[item.status]" class="status-cr st"></span> {{item.status.toUpperCase()}}</td>
              <td><span href="javascript:void(0)" class="iot-btn-show-hide wifi-pop-btn">SHOW</span></td>
            </tr>
          </tbody>
        </table>
        <v-pagination
          v-model="page"
          :length="totalLength"
          circle
          @input="handelInput(page)"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>

    import axios from "axios";
    import { API_BASE_URL,API_HELIUM_URL, API_JAG_HELIUM_URL, HOTSPOTTY_BASE_URL, HOTSPOTTY_TOKEN  } from "../../../constants";
    import { bus } from '../../../main';
    import './style.css';
    import _ from 'lodash';
    export default {
        name: 'App',
        data() {
          return {
            uid: "",
            hots: [],
            hotspotAll:[],
            activeHotspot:'',
            currRouteName:'dashboard',
            isInvestor: 0,
            page: 1,
            totalLength: 0
          }
        },
        computed: {
          currentRouteName() {
              return this.$route.name;
          }
        },
        watch:{
          $route (to, from){
              this.headRoutClickHandler(to.name);
           }
        },
        mounted() {
          this.headRoutClickHandler(this.currentRouteName);

          //this part of code is added bcz to work external JS files (jquery,custom)
          if (localStorage.getItem('reloaded')) {
              // The page was just reloaded. Clear the value from local storage
              // so that it will reload the next time this page is visited.
              localStorage.removeItem('reloaded');
          } else {
              // Set a flag so that we know not to reload the page twice.
              localStorage.setItem('reloaded', '1');
              location.reload();
          }
        },
        beforeMount() { 
          //get user from local storage
          this.User = JSON.parse(localStorage.getItem('User'));
          this.uid = this.User.uid;

          this.getHotspotName();
          this.getUserInfo();
        },
        methods: {
          getUserInfo(){
            axios.get(`${API_BASE_URL}/user/info/${this.uid}`,{
                headers: { accept: "application/json" },
              })
              .then((resp) => {
                try {
                 this.isInvestor = resp.data[0].investor;
                 console.log('this.isInvestor', this.isInvestor);
                } catch (err) {
                    this.error = "please try again.";
                }

              });
          },
          redirect(link) { //custom redirect method
              this.$router.push(link);
              console.log("link = ",link)
          },
          async getHotspottyHotspots(hotspots){
            return new Promise (function( resolve ) {
              axios.post(`${HOTSPOTTY_BASE_URL}/hotspots`,{
                hotspot_ids: hotspots
              }, {
                headers: {
                  accept: "application/json",
                  Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
                }
              }).
              then((res) => {
                res = res.data.data;
                resolve(res);
              })
              .catch(() => {
                resolve({});
              })
            })
          },
          async getHotspottyHotspot(hotspotAddress){
            return new Promise (function( resolve ) {
              axios.get(`${HOTSPOTTY_BASE_URL}/hotspots/${hotspotAddress}`, {
                headers: {
                  accept: "application/json",
                  Authorization: `Bearer ${HOTSPOTTY_TOKEN}`
                }
              }).
              then((res) => {
                res = res.data.data;
                resolve(res);
              })
              .catch(() => {
                resolve({});
              })
            })
          },
          getHotspotName(page) {
            let skip = 0;
            let itemsPerPage = 10;
            this.hots = [];
            if(page > 1) {
              skip = itemsPerPage * (page - 1);
            }
            let payload = {
              skip: skip,
              limit: itemsPerPage,
              search: ''
            };
            axios.get(`${API_BASE_URL}/user/hotspots/${this.uid}`, {
              headers: {
                accept: "application/json",
              },
            })
            .then(async (resp) => {
              let hotspots = resp.data;
              console.log('heeeeee', hotspots);
              let newArr = [];

              let hotspotsIds = [];
              for(let i = 0; i < hotspots.length; i ++){
                let exists = hotspotsIds.findIndex((x) => x == hotspots[i].Haddress);

                if(exists < 0){
                  hotspotsIds = [...hotspotsIds, hotspots[i].Haddress];
                }
              }

              let hotspottyHotspots = await this.getHotspottyHotspots(hotspotsIds);

              for(let i = 0; i < hotspots.length; i++){
                let hotspottyResIndex = hotspottyHotspots.findIndex((x) => x.id == hotspots[i].Haddress);
                if(hotspottyResIndex > -1){
                  let hotspottyRes = hotspottyHotspots[hotspottyResIndex];
                  let hotspotObj =  {
                        index: i,
                        name: this.formatHotspotName(hotspottyRes.name),
                        status: hotspottyRes.active ? 'online' : 'offline',
                        address: hotspots[i].Haddress,
                        owner: hotspottyRes.owner,
                        role: hotspots[i].role,
                        timestamp_added: 'N/A',
                        hotspot_type: hotspots[i].hotspotType,
                        email: this.userEmail,
                        location: hotspottyRes.place,
                        lat: (hotspottyRes.lat) ? hotspottyRes.lat : 45.45706794277646,
                        lng: (hotspottyRes.lng) ? hotspottyRes.lng : 9.152475848644855,                    
                        timestamp_added: '',
                        removeDate: (hotspots[i].removeDate) ? hotspots[i].removeDate : null,
                        elegibility: 'N/A',
                        today:  'N/A',
                        week: 'N/A',
                        month:  'N/A',
                        total: 'N/A',
                        hostCommission: hotspots[i].hostCommission,
                      };
                  newArr = [...newArr, hotspotObj]
                } 
              }
              this.hots = newArr;
//               hotspots = [
//     {
//         "hotspotName": "cheery-misty-wolverine",
//         "hotspotAddress": "1121PBKKmDRyDx5gcrG7HXajEC4XfUSaA4jNxB9TxGaytUJrKL5M",
//         "hotspotLng": -94.58579558966908,
//         "hotspotLat": 39.05693728978543,
//         "hotspotStatus": "offline",
//         "short_street": "Main St",
//         "short_state": "MO",
//         "short_country": "US",
//         "short_city": "KCMO",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "IoT",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-28T16:44:06.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0,
//         "hotspotAllTimeMining": 5.128998030000001,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0,
//         "hotspotAllTimeEarning": 1.025799606
//     },
//     {
//         "hotspotName": "cheery-misty-wolverine",
//         "hotspotAddress": "1121PBKKmDRyDx5gcrG7HXajEC4XfUSaA4jNxB9TxGaytUJrKL5M",
//         "hotspotLng": -94.58579558966908,
//         "hotspotLat": 39.05693728978543,
//         "hotspotStatus": "offline",
//         "short_street": "Main St",
//         "short_state": "MO",
//         "short_country": "US",
//         "short_city": "KCMO",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "5G",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-28T16:44:06.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0,
//         "hotspotAllTimeMining": 5.128998030000001,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0,
//         "hotspotAllTimeEarning": 1.025799606
//     },
//     {
//         "hotspotName": "polished-cinnabar-porcupine",
//         "hotspotAddress": "11sXuN9py7BDB8hVhR4CqYSMHZwCwyAzBu6fXFpf1G4mru4HfbH",
//         "hotspotLng": -94.57830697606472,
//         "hotspotLat": 39.06867598916537,
//         "hotspotStatus": "offline",
//         "short_street": "Gillham Rd",
//         "short_state": "MO",
//         "short_country": "US",
//         "short_city": "KCMO",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "IoT",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-28T16:59:21.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0,
//         "hotspotAllTimeMining": 4.48797027,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0,
//         "hotspotAllTimeEarning": 0.8975940539999999
//     },
//     {
//         "hotspotName": "polished-cinnabar-porcupine",
//         "hotspotAddress": "11sXuN9py7BDB8hVhR4CqYSMHZwCwyAzBu6fXFpf1G4mru4HfbH",
//         "hotspotLng": -94.57830697606472,
//         "hotspotLat": 39.06867598916537,
//         "hotspotStatus": "offline",
//         "short_street": "Gillham Rd",
//         "short_state": "MO",
//         "short_country": "US",
//         "short_city": "KCMO",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "5G",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-28T16:59:21.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0,
//         "hotspotAllTimeMining": 4.48797027,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0,
//         "hotspotAllTimeEarning": 0.8975940539999999
//     },
//     {
//         "hotspotName": "joyous-purple-toad",
//         "hotspotAddress": "1127RdPa8KQrdUAiBABD3nN1A9aHrL4u8j3oJe455C8mQtnrmjzP",
//         "hotspotLng": -94.64496813746008,
//         "hotspotLat": 39.02303480024172,
//         "hotspotStatus": "online",
//         "short_street": "Johnson Dr",
//         "short_state": "KS",
//         "short_country": "US",
//         "short_city": "Mission",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "IoT",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-26T14:16:58.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0.0981861,
//         "hotspotAllTimeMining": 6.497385140000001,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0.019637219999999997,
//         "hotspotAllTimeEarning": 1.2994770280000003
//     },
//     {
//         "hotspotName": "joyous-purple-toad",
//         "hotspotAddress": "1127RdPa8KQrdUAiBABD3nN1A9aHrL4u8j3oJe455C8mQtnrmjzP",
//         "hotspotLng": -94.64496813746008,
//         "hotspotLat": 39.02303480024172,
//         "hotspotStatus": "online",
//         "short_street": "Johnson Dr",
//         "short_state": "KS",
//         "short_country": "US",
//         "short_city": "Mission",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "5G",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-26T14:16:58.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0.0981861,
//         "hotspotAllTimeMining": 6.497385140000001,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0.019637219999999997,
//         "hotspotAllTimeEarning": 1.2994770280000003
//     },
//     {
//         "hotspotName": "beautiful-crimson-swallow",
//         "hotspotAddress": "112Nas1E156cGTpvLjLMTo8Kj6q1QmRvLSNLz2s1xui7rfot2MSR",
//         "hotspotLng": -94.57706460049644,
//         "hotspotLat": 39.1109025102792,
//         "hotspotStatus": "online",
//         "short_street": "E 3rd St",
//         "short_state": "MO",
//         "short_country": "US",
//         "short_city": "KCMO",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "IoT",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-27T14:41:35.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0.113915,
//         "hotspotAllTimeMining": 5.36552029,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0.022783,
//         "hotspotAllTimeEarning": 1.0731040580000002
//     },
//     {
//         "hotspotName": "beautiful-crimson-swallow",
//         "hotspotAddress": "112Nas1E156cGTpvLjLMTo8Kj6q1QmRvLSNLz2s1xui7rfot2MSR",
//         "hotspotLng": -94.57706460049644,
//         "hotspotLat": 39.1109025102792,
//         "hotspotStatus": "online",
//         "short_street": "E 3rd St",
//         "short_state": "MO",
//         "short_country": "US",
//         "short_city": "KCMO",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "5G",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-27T14:41:35.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0.113915,
//         "hotspotAllTimeMining": 5.36552029,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0.022783,
//         "hotspotAllTimeEarning": 1.0731040580000002
//     },
//     {
//         "hotspotName": "shambolic-tawny-antelope",
//         "hotspotAddress": "1126dWgm6CF2AKyBEWjxPhSMYTiGiQZoG3rsE98rkMRQzhaUaYqf",
//         "hotspotLng": -94.37852393545248,
//         "hotspotLat": 38.90640245077759,
//         "hotspotStatus": "online",
//         "short_street": "SW Jefferson St",
//         "short_state": "MO",
//         "short_country": "US",
//         "short_city": "Lee's Summit",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "IoT",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-23T13:42:05.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0.273613,
//         "hotspotAllTimeMining": 18.599504400000004,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0.054722599999999996,
//         "hotspotAllTimeEarning": 3.719900880000001
//     },
//     {
//         "hotspotName": "shambolic-tawny-antelope",
//         "hotspotAddress": "1126dWgm6CF2AKyBEWjxPhSMYTiGiQZoG3rsE98rkMRQzhaUaYqf",
//         "hotspotLng": -94.37852393545248,
//         "hotspotLat": 38.90640245077759,
//         "hotspotStatus": "online",
//         "short_street": "SW Jefferson St",
//         "short_state": "MO",
//         "short_country": "US",
//         "short_city": "Lee's Summit",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "5G",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-23T13:42:05.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0.273613,
//         "hotspotAllTimeMining": 18.599504400000004,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0.054722599999999996,
//         "hotspotAllTimeEarning": 3.719900880000001
//     },
//     {
//         "hotspotName": "active-lavender-gazelle",
//         "hotspotAddress": "11tPapbNJ6ng2NigS3nVJuRNrME8mcdfvpGRGJnfDGBg9vi2pbm",
//         "hotspotLng": -94.67597015510133,
//         "hotspotLat": 38.93105301818663,
//         "hotspotStatus": "online",
//         "short_street": "Lowell Ave",
//         "short_state": "KS",
//         "short_country": "US",
//         "short_city": "Overland Park",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "IoT",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-23T13:46:33.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0.156881,
//         "hotspotAllTimeMining": 9.0084671,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0.0313762,
//         "hotspotAllTimeEarning": 1.80169342
//     },
//     {
//         "hotspotName": "active-lavender-gazelle",
//         "hotspotAddress": "11tPapbNJ6ng2NigS3nVJuRNrME8mcdfvpGRGJnfDGBg9vi2pbm",
//         "hotspotLng": -94.67597015510133,
//         "hotspotLat": 38.93105301818663,
//         "hotspotStatus": "online",
//         "short_street": "Lowell Ave",
//         "short_state": "KS",
//         "short_country": "US",
//         "short_city": "Overland Park",
//         "hotspotWallet": "14CZLYoG3ER2DGWsgQ5JJDgzEeRucyVvQ4VeuzuSwdGcPMAHMrb",
//         "hotspot_type": "N/A",
//         "hotspotOwner": "0",
//         "hotspotType": "5G",
//         "removeDate": null,
//         "hostCommission": "25",
//         "dateOfInstallation": "2022-06-23T13:46:33.000Z",
//         "hotspotTodayMining": 0,
//         "hotspotPastSevenDaysMining": 0,
//         "hotspotPastThirtyDaysMining": 0.156881,
//         "hotspotAllTimeMining": 9.0084671,
//         "hotspotTodayEarning": 0,
//         "hotspotPastSevenDaysEarning": 0,
//         "hotspotPastThirtyDaysEarning": 0.0313762,
//         "hotspotAllTimeEarning": 1.80169342
//     }
// ];
              if (this.hots.length > 0) {
                // let today_date = new Date();
                // hotspots.forEach((element, index) => {
                //   let hotspotObj = {};
                //   let date_of_installation = new Date(element.dateOfInstallation);
                //   date_of_installation.setDate(date_of_installation.getDate() + 1);
                //   date_of_installation.setMonth(
                //     date_of_installation.getMonth() + 1
                //   );
                //   let eligiblity_date = date_of_installation;
                //   let eligibility_date_to_iso_string = date_of_installation.toISOString();
                //   if (eligiblity_date > today_date) {
                //     hotspotObj.notmonth = 0;
                //   } else {
                //     hotspotObj.notmonth = 1;
                //   }
                //   hotspotObj.index = index;
                //   hotspotObj.name = this.formatHotspotName(element.hotspotName);
                //   hotspotObj.status = element.hotspotStatus;
                //   hotspotObj.address = element.hotspotAddress ? element.hotspotAddress : "N/A";
                //   hotspotObj.role = element.hotspotOwner;
                //   hotspotObj.hotspot_type = element.hotspotType;
                //   hotspotObj.email = this.userEmail;
                //   hotspotObj.location =
                //     (element.short_state ? element.short_state : "") +
                //     "-" +
                //     (element.short_street ? element.short_street : "") +
                //     "-" +
                //     (element.short_country ? element.short_country : "");
                //   hotspotObj.lat = element.hotspotLat ? element.hotspotLat : 45.45706794277646;
                //   hotspotObj.lng = element.hotspotLng ? element.hotspotLng : 9.152475848644855;
                //   hotspotObj.timestamp_added = element.dateOfInstallation.slice(0,10);
                //   hotspotObj.removeDate = element.removeDate ? element.removeDate : null;
                //   hotspotObj.elegibility = eligibility_date_to_iso_string ? eligibility_date_to_iso_string.slice(0, 10) : "N/A";
                //   hotspotObj.today = element.hotspotTodayEarning ? element.hotspotTodayEarning.toFixed(2) : "N/A";
                //   hotspotObj.week = element.hotspotPastSevenDaysEarning ? element.hotspotPastSevenDaysEarning.toFixed(2) : "N/A";
                //   hotspotObj.month = element.hotspotPastThirtyDaysEarning ? element.hotspotPastThirtyDaysEarning.toFixed(2) : "N/A";
                //   hotspotObj.total = element.hotspotAllTimeEarning ? element.hotspotAllTimeEarning.toFixed(2) : "N/A";
                //   hotspotObj.hostCommission = element.hostCommission;
                //   this.hots.push(hotspotObj);
                // })


              }
            })
          },
          getHotspotNameDemo() {
            axios.get(`${API_JAG_HELIUM_URL}/v1/user/hotspots/with/earnings/${this.uid}`, {
              headers: {
                accept: "application/json",
              },
            })
            .then((resp) => {
              let hotspots = resp.data;
              if(hotspots.length > 0) {
                hotspots.forEach((element, index) => {
                  let hotspotObj = {};
                  let date_of_installation = new Date(element.dateOfInstallation);
                  date_of_installation.setDate(date_of_installation.getDate() + 1);
                  date_of_installation.setMonth(date_of_installation.getMonth() + 1);
                  let eligibility_date = date_of_installation.toISOString();
                  hotspotObj.name = element.hotspotName;
                  hotspotObj.status = element.hotspotStatus;
                  hotspotObj.address = (element.hotspotAddress) ? element.hotspotAddress : 'N/A';
                  hotspotObj.role = element.hotspotOwner;
                  hotspotObj.email = this.userEmail;
                  hotspotObj.location = ((element.short_state) ? element.short_state : '')+'-'+
                                        ((element.short_street) ? element.short_street : '')+'-'+
                                        ((element.short_country) ? element.short_country : '');
                  hotspotObj.lat = (element.hotspotLat) ? element.hotspotLat : 45.45706794277646;
                  hotspotObj.lng = (element.hotspotLng) ? element.hotspotLng : 9.152475848644855;                    
                  hotspotObj.timestamp_added = element.dateOfInstallation.slice(0, 10);
                  hotspotObj.removeDate = (element.removeDate) ? element.removeDate : null;
                  hotspotObj.elegibility = (eligibility_date) ? eligibility_date.slice(0, 10) : 'N/A';
                  hotspotObj.today = (element.hotspotTodayEarning) ? (element.hotspotTodayEarning).toFixed(2) : 'N/A';
                  hotspotObj.week = (element.hotspotPastSevenDaysEarning) ? (element.hotspotPastSevenDaysEarning).toFixed(2) : 'N/A';
                  hotspotObj.month = (element.hotspotPastThirtyDaysEarning) ? (element.hotspotPastThirtyDaysEarning).toFixed(2) : 'N/A';
                  hotspotObj.total = (element.hotspotAllTimeEarning) ? (element.hotspotAllTimeEarning).toFixed(2) : 'N/A';
                  this.hots.push(hotspotObj);
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
          },
          getHotspotNameOld() {
            var i;
            axios
              .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
                headers: {
                  accept: "application/json",
                },
              })
              .then((resp) => {

                this.hotspotAll = resp.data;
               
                for (i = 0; i < this.hotspotAll.length; i++) {

                   var today = new Date();
                   var end = this.hotspotAll[i].removeDate? new Date(this.hotspotAll[i].removeDate.slice(0,10)):today;

                   //compare only date
                   today.setHours(0,0,0,0)

                   
                   //check hotspot end date display accordingly
                   if(end >= today){ 
                      axios
                        .get(
                          `${API_HELIUM_URL}/v1/hotspots/${this.hotspotAll[i].Haddress}`,
                          {
                            headers: {
                              accept: "application/json",
                            },
                          }
                        )
                        .then((resp) => {

                          console.log(resp.data.data);
                          this.hots.push(resp.data.data);


                        }).catch((err) => {
                          console.log(err);
                        });



                   }else{
                      console.log("hotspot end is passed = ",end)
                   } 
                  
                }


              }).catch((err) => {
                console.log(err);
              });
          },
          formatHotspotName(name){
            let newName = name.replace(/-/g, " ").toLowerCase().replace(/\b[a-z]/g, function(letter) {
                return letter.toUpperCase();
            });

            return newName;
          },
          formatHotspotType(type) {
            if(type === "N/A") {
              return "4G";
            } else {
              return type;
            }
          },
          changeHotspot(item){
            console.log("hotspot click in sidebar = ",item)

              console.log("hotspot click in sidebar = ",item.name)
              this.activeHotspot = item.name;
            // alert('side bar cliked');
              //for set hotspot data
              localStorage.setItem('activeHotspot',JSON.stringify({'name':item.name,'address':item.address,'status':item.status,'total':item.total,'installDate':item.installDate,'timestamp_added':item.timestamp_added,'elegibility':item.elegibility,'lng':item.lng,'lat':item.lat, hotspot_type: item.hotspot_type,hostCommission: item.hostCommission }));

              //listen this evenet in dashboard page and other pages where we need current hotspot name
              bus.$emit('changeHotspot', item);

              //reset leased hotspot section (remove class with default)
              document.querySelector("body").setAttribute("class","landscape")
          },
          headRoutClickHandler(name){
            
            this.currRouteName = name;


            /*//enable leased hotspot only for dashboard hotspot page
            if(name == "dashboard" || name == "hotspot"){
              document.getElementById("LeasedHotspots").setAttribute("class","links-ab red-display")
            }else{
              //reset leased hotspot section (remove class with default)
              document.querySelector("body").setAttribute("class","landscape");
              
              document.getElementById("LeasedHotspots").setAttribute("class","links-ab red-display disabled")
            }*/
          },
          handelInput(page) {
            console.log('Random Number Clicked');
            this.getHotspotName(page);
          }
        },
    };
</script>



