<template>
  <div class="main-view-sec">

    <Loader v-if="loader"></Loader>

    <div class="contact-page clearfix">
	    <div class="left-contact-page">
	    	<div class="vh-center">
	    		<img src="@/assets/img/helpdesk.png" alt="Image"/>
	    	</div>
	    </div>
	    <div class="right-contact-page">

	    	<v-form ref="form">
		        <div class="vh-center">
		            <h1>Update IoT Antenna Gain Form</h1>
		            <p>If you have connected a new antenna with a different DBI to your hotspot please fill out this form to notify our JAG team to update this on the blockchain. Click <a href="https://jag.network/collections/">here</a> to shop for upgraded antennas.  </p>
		            
		            
		            <div class="row">
		                <div class="col-sm-6">
		                    <v-text-field v-model="form.name"
                              label="Name*"
                              class="form-control"
                              required
                              :rules="rules.nameRules"
                            >
                            </v-text-field>
		                </div>
		                <div class="col-sm-6">
		                    <v-text-field v-model="form.email"
                              label="Email Id*"
                              type="email"
                              class="form-control"
                              required
                              :rules="rules.emailRules"
                            >
                            </v-text-field>
		                </div>
		                <div class="col-sm-6">
		                    <v-text-field v-model="form.hotspotName"
                                label="Hotspot Name*"
                                class="form-control"
                                required
                                :rules="rules.requiredRules"
                              >
                              </v-text-field>
		                </div>
		                <div class="col-sm-6">
		                    <v-text-field v-model="form.phone"
                              label="Phone*"
                              class="form-control"
                              required
                              :rules="rules.phoneRules"
                            >
                            </v-text-field>
		                </div>

		                <!-- <div class="col-sm-6">
		                    <v-text-field v-model="form.newAntennaTypeRequested"
	                          label="New Antenna Type Requested *"
	                          class="form-control"
	                          required
	                          :rules="rules.newAntennaTypeRequestedRules"
	                        >
	                        </v-text-field>
		                </div> -->

		                <div class="col-sm-6">
		                    <v-text-field v-model="form.newAntennaHeightorFloorNumber"
                            label="New Antenna Height or Floor Number*"
                            class="form-control"
                            required
                            :rules="rules.newAntennaHeightorFloorNumberRules"
                          >
                          </v-text-field>
		                </div>


		                <div class="col-sm-12">
		                     <v-text-field v-model="form.newAntennaGain"
                              label="New Antenna Gain*"
                              class="form-control"
                              required
                              :rules="rules.newAntennaGainRules"
                            >
                            </v-text-field>
		                </div>
		                 <div class="col-sm-12">
		                   <span style="margin-right:5px;"> Has your hotspot changed addresses?</span>
                       <label class="radio-inline">
                        <input type="radio" id="Yes" value="Yes" v-model="value_radio" name="test-radio"> Yes
                      </label>
                              
                               <label class="radio-inline">
                              <input type="radio" id="No" value="No" v-model="value_radio" name="test-radio">No
                               </label>
		                </div>
		                
		            </div>


		            <h3 v-if="value_radio == 'Yes'">Old Hotspot Address</h3>
		            <div class="row" v-if="value_radio == 'Yes'">
		                <div class="col-sm-6">
		                    <v-textarea v-model="form.currentAddressLineOne"
	                            label="Address Line 1*"
	                            auto-grow
	                            rows="1"
	                            type="text"
	                            row-height="15"
	                            autocomplete="off"
	                            required
	                            :rules="rules.addressLineRules"
	                            class="form-control"
	                          >
	                          </v-textarea>
		                </div>
		                <div class="col-sm-6">
		                    <v-text-field v-model="form.currentCity"
                                      label="City*"
                                      type="text"
                                      class="form-control"
                                      required
                                      :rules="rules.cityRules"
                                    >
                                    </v-text-field>
		                </div>
		                <div class="col-sm-6">
		                    <v-text-field v-model="form.currentState"
                              label="State*"
                              type="text"
                              class="form-control"
                              required
                              :rules="rules.stateRules"
                            >
                            </v-text-field>
		                </div>
		                <div class="col-sm-6">
		                    <v-text-field v-model="form.currentZip"
                              label="Zip*"
                              type="text"
                              class="form-control"
                              required
                              :rules="rules.zipCodeRules"
                            >
                            </v-text-field>
		                </div>

		                <div class="col-sm-6">
		                    <v-text-field v-model="form.currentCountry"
                              label="Country*"
                              type="text"
                              class="form-control"
                              required
                              :rules="rules.countryRules"
                            >
                            </v-text-field>
		                </div>
 
		            </div>


		            <h3 v-if="value_radio == 'Yes'">New Hotspot Address</h3>
		            <div class="row" v-if="value_radio == 'Yes'">
		                <div class="col-sm-6">
		                    <v-textarea v-model="form.newAddressLineOne"
	                            label="Address Line 1*"
	                            auto-grow
	                            rows="1"
	                            type="text"
	                            row-height="15"
	                            autocomplete="off"
	                            required
	                            :rules="rules.addressLineRules"
	                            class="form-control"
	                          >
	                          </v-textarea>
		                </div>
		                <div class="col-sm-6">
		                    <v-text-field v-model="form.newCity"
                                      label="City*"
                                      type="text"
                                      class="form-control"
                                      required
                                      :rules="rules.cityRules"
                                    >
                                    </v-text-field>
		                </div>
		                <div class="col-sm-6">
		                     <v-text-field v-model="form.newState"
                                      label="State*"
                                      type="text"
                                      class="form-control"
                                      required
                                      :rules="rules.stateRules"
                                    >
                                    </v-text-field>
		                </div>
		                <div class="col-sm-6">
		                    <v-text-field v-model="form.newZip"
                              label="Zip*"
                              type="text"
                              class="form-control"
                              required
                              :rules="rules.zipCodeRules"
                            >
                            </v-text-field>
		                </div>

		                <div class="col-sm-6">
		                    <v-text-field v-model="form.newCountry"
                              label="Country*"
                              type="text"
                              class="form-control"
                              required
                              :rules="rules.countryRules"
                            >
                            </v-text-field>
		                </div>
 
		            </div>


		            <div>
                  <v-alert
                    dense
                    outlined
                    type="error"
                    v-if="error"
                  >
                    {{error}}
                  </v-alert>

                  <v-alert
                    dense
                    text
                    type="success"
                    v-if="success"
                  >
                    {{success}}
                  </v-alert>

                </div>

                <div class="text-center">
                  <v-btn class="theme-btn" @click="send">send
                      <v-progress-circular
                        v-if="loading"
                        :size="20"
                        :width="2"
                        color="white"
                        indeterminate
                      ></v-progress-circular>
                  </v-btn>
                </div>

		        </div>
	        </v-form>

	    </div>
	    <div class="clearfix"></div>
	</div>
  </div>
</template>

<script>
import axios from "axios";
import VueCookies from "vue-cookies";
import {API_BASE_URL } from '../../../constants';
import Loader from '../../../components/loader';
import "./index.css";

export default {
  components: { Loader },
  setup() {},
  data() {
    return {
        uid: this.$cookies.get("uid"),
        loader:true,
        loading: false,
        value_radio: "No",
        form: {
          name:"",
          email: "",
          hotspotName: "",
          phone:"",

          currentAddressLineOne: "",
          currentCity:"",
          currentState:"",
          currentZip:"",
          currentCountry:"",

          // newAntennaTypeRequested:"",
          newAntennaHeightorFloorNumber:"",
          newAntennaGain:"",

          newAddressLineOne: "",
          newCity:"",
          newState:"",
          newZip:"",
          newCountry:""

        },
        error: null,
        success:null,
        rules: {
            requiredRules: [],
            nameRules:[],
            emailRules: [],
            phoneRules: [],
            zipCodeRules:[],
            countryRules:[],
            stateRules:[],
            cityRules:[],
            addressLineRules:[],
            newAntennaTypeRequestedRules:[],
            newAntennaHeightorFloorNumberRules:[],
            newAntennaGainRules:[],
        },
        token: {
          accessToken: ''
        },
    };
  },
  beforeMount() {


    //hide page loader
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  },
  methods: {
    send(){
      this.error = null;

      //enabled validation only on send button and removed from indivisual field
      this.rules.requiredRules= [v => !!v || 'This field is required'];
      this.rules.nameRules=[
          v => !!v || 'Name is required',
          v => !!/^[a-z\s]+$/i.test(v) || 'Name must be valid',
      ];
      this.rules.emailRules= [
          v => !!v || 'E-mail is required',
          v => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) || 'E-mail must be valid',
      ];
      this.rules.phoneRules= [
          v => !!v || 'Phone is required',
          v => !!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/im.test(v) || 'Phone must be valid',
      ];
      this.rules.zipCodeRules=[
          v => !!v || 'Zip is required',
          v => !!/^(\d{5})?$/.test(v) || 'Zip must be valid',
      ];
      this.rules.countryRules=[
          v => !!v || 'Country is required',
          v => !!/^[a-z\s]+$/i.test(v) || 'Country must be valid',
      ];
      this.rules.stateRules=[
          v => !!v || 'State is required',
          v => !!/^[a-z\s]+$/i.test(v) || 'State must be valid',
      ];
      this.rules.cityRules=[
          v => !!v || 'City is required',
          v => !!/^[a-z\s]+$/i.test(v) || 'City must be valid',
      ];
      this.rules.addressLineRules=[
          v => !!v || 'Adddress line one is required',
          v => !!/^.{0,1000}$/.test(v) || 'Adddress line one should be less than 1000 character',
      ];
      this.rules.newAntennaTypeRequestedRules=[
          v => !!v || 'New Antenna Type Requested is required',
          v => !!/^[a-zA-Z0-9.,-\s]+$/i.test(v) || 'New Antenna Type Requested must be valid',
      ];
      this.rules.newAntennaHeightorFloorNumberRules=[
          v => !!v || 'New Antenna Height or Floor Number is required',
          v => !!/^[a-zA-Z0-9.,\s-]+$/i.test(v) || 'New Antenna Height or Floor Number must be valid',
      ];
      this.rules.newAntennaGainRules=[
          v => !!v || 'New Antenna Gain is required',
          v => !!/^[a-zA-Z0-9.,\s-]+$/i.test(v) || 'New Antenna Height or Floor Number must be valid',
      ];
      //end



      setTimeout(()=>{

        if (this.$refs.form.validate()) {
          this.loading = true;
          axios.post(`${API_BASE_URL}/update/antenna/request`,
          {
            userId:this.uid,
            name:this.form.name,
            email:this.form.email,
            phone:this.form.phone,
            hotspotName:this.form.hotspotName,

            // newAntennaTypeRequested:this.form.newAntennaTypeRequested,
            newAntennaHeightorFloorNumber:this.form.newAntennaHeightorFloorNumber,
            newAntennaGain:this.form.newAntennaGain,

            currentAddressLineOne:this.form.currentAddressLineOne,
            currentCity:this.form.currentCity,
            currentState:this.form.currentState,
            currentZip:this.form.currentZip,
            currentCountry:this.form.currentCountry,
            currentAntenna:this.form.currentAntenna,

            newAddressLineOne:this.form.newAddressLineOne,
            newCity:this.form.newCity,
            newState:this.form.newState,
            newZip:this.form.newZip,
            newCountry:this.form.newCountry,
            newAntenna:this.form.newAntenna

          })

          .then((response) => {
            this.loading = false;
            this.$refs.form.reset();
            if(response.data.status === '1') {
              this.success = response.data.message;
              //auto hide after 5 sec
              setTimeout(() => this.success = null, 5000)
            }
            else {
              this.error = response.data.message;
              //auto hide after 5 sec
              setTimeout(() => this.error = null, 5000)
            }
          }, 
          (error) => {
            this.loading = false;
            this.error = error.message;
            //auto hide after 5 sec
            setTimeout(() => this.error = null, 5000)
          });
        }

      })


    }
  },
  
};
</script>
<style scoped>
.right-contact-page .theme-btn {
margin-top: 20px;
}
.radio-inline input{
  width:auto;
}
</style>